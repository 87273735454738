import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { default_property_image } from "assets";
import { notify } from "utilities/notifications/Notify";
import axios from "axios";
import { useSelector } from "react-redux";

import { FaFile, FaFileImage, FaFilePdf, FaRegHeart } from "react-icons/fa6";
import { FiDownload } from "react-icons/fi";
import { BiDislike } from "react-icons/bi";

function ListedPropertyCard({
  id,
  index,
  slug,
  property_name,
  property_img,
  flats,
  layout_types,
  project_type,
  location,
  builder,
  total_rooms,
  media_files,
  is_liked,
  is_viewed,
  created_at,
  not_interested,
  // recommended,
  // new_property,
  notInterestedAction,
}) {
  // const small = useMediaQuery({ query: "(max-width: 576px)" });
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [isLiked, setIsLiked] = useState(is_liked);
  const [isViewed, setIsViewed] = useState(is_viewed);
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDownloadAllMedia = async () => {
    if (media_files && media_files?.length > 0) {
      setDownloading(true);
      try {
        const mediaIds = [];
        media_files.forEach((file) => mediaIds.push(file.id));
        const response = await axios.post(
          "/property/properties/download_media_zip/",
          { ids: mediaIds.join(",") },
          { responseType: "blob" }
        );
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "media_files.zip");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setDownloading(false);
          if (!isViewed) {
            handleViewProperty();
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      notify("Files not found, try again later");
    }
  };

  const handleViewProperty = async () => {
    try {
      const response = await authActions.viewProperty(user.id, id);
      if (response.data) {
        setIsViewed(true);
        setDownloadModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProperty = async () => {
    try {
      const response = await authActions.saveProperty(user.id, id);
      if (response.data) {
        setIsLiked(true);
        console.log(response);
        notify("Project saved successfully", "success");
      } else {
        notify("Failed to save project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProperty = async () => {
    try {
      const response = await authActions.unsaveProperty(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Project unsaved successfully", "success");
      } else {
        notify("Failed to unsave project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkSavedProperty = async () => {
    setLoading(true);
    try {
      const response = await authActions.isCheckSavedProperty(user?.id, id);
      setIsLiked(response?.data?.status);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkSavedProperty();
  }, []);

  // // Function to get CSRF token from cookies
  // const getCookie = (name) => {
  //   const cookieValue = null;
  //   if (document.cookie && document.cookie !== "") {
  //     const cookies = document.cookie.split(";");
  //     for (let i = 0; i < cookies.length; i++) {
  //       const cookie = cookies[i].trim();
  //       if (cookie.substring(0, name.length + 1) === name + "=") {
  //         return decodeURIComponent(cookie.substring(name.length + 1));
  //       }
  //     }
  //   }
  //   return cookieValue;
  // };

  return (
    <>
      <Card
        key={index}
        className="p-0 d-flex flex-column text-start my-4 shadow-none"
        style={{ border: "1px solid #E8EAED", minHeight: 200, borderRadius: 6 }}
      >
        <Row>
          <Col
            md={4}
            lg={5}
            xl={4}
            xxl={3}
            className="mb-2 mb-md-0"
            style={{ flexGrow: 1 }}
          >
            <Card.Img
              className="bg-gray-25 border-0"
              variant="top"
              src={property_img ?? default_property_image}
              style={{ height: 226, borderRadius: "6px 0px 0px 6px" }}
            />
          </Col>
          <Col md={8} lg={7} xl={8} xxl={9}>
            <Card.Body className="w-100 h-100 p-2 p-md-3 d-flex flex-column justify-content-between">
              <div className="mb-2">
                <div className="d-flex flex-row justify-content-between">
                  <Card.Title className="h4 inter-700">
                    {property_name} {builder && `/ ${builder}`}
                  </Card.Title>
                  <div className="d-flex justify-content-center align-items-center">
                    <div
                      className={`d-flex justify-content-center align-items-center cursor-pointer me-2 p-0 ${
                        isLiked ? "bg-primary text-white" : "bg-gray-50"
                      }`}
                      style={{ width: 35, height: 35, borderRadius: 17.5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        isLiked ? handleUnsaveProperty() : handleSaveProperty();
                      }}
                    >
                      {loading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <FaRegHeart size={18} />
                      )}
                    </div>
                    {/* <div
                      className={`d-flex justify-content-center align-items-center p-0 ${not_interested ? "bg-primary text-white" : "bg-gray-50 cursor-pointer"}`}
                      style={{ width: 35, height: 35, borderRadius: 17.5 }}
                      onClick={() => not_interested ? "" : notInterestedAction(id)}
                    >
                      <BiDislike size={18} />
                    </div> */}
                  </div>
                </div>
                <div className="body-text2 mb-2">
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">Number of Flats: {flats}</span>
                  </Card.Text>
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">
                      Layout Types: {layout_types ? layout_types : "-"}
                    </span>
                  </Card.Text>
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">Project Type: {project_type}</span>
                  </Card.Text>
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">Location: {location}</span>
                  </Card.Text>

                  {total_rooms > 0 && (
                    <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                      <span className="">
                        Total Inspirations Created: {total_rooms}
                      </span>
                    </Card.Text>
                  )}
                  {/* <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">created_at: {created_at}</span>
                  </Card.Text> */}
                </div>
              </div>
              {!isViewed ? (
                <div className="d-flex justify-content-end">
                  <Button
                    className="text-white body-text2 mt-2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setDownloadModal(true)}
                  >
                    Create Inspiration
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-end mt-3">
                  {media_files?.length > 0 && (
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2 me-2"
                      disabled={downloading}
                      style={{ borderRadius: 3 }}
                      onClick={handleDownloadAllMedia}
                    >
                      {downloading ? (
                        <span className="d-flex justify-content-center align-items-center">
                          <Spinner className="me-2" size="sm" /> Downloading..{" "}
                          <FiDownload size={16} className="ms-2" />
                        </span>
                      ) : (
                        <span className="d-flex justify-content-center align-items-center">
                          Download All Files{" "}
                          <FiDownload size={18} className="ms-2" />
                        </span>
                      )}
                    </Button>
                  )}
                  <Button
                    className="d-flex align-items-center text-white body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() =>
                      navigate(`/${user?.user_type}/addproject/${id}`)
                    }
                  >
                    Submit Inspiration
                  </Button>
                </div>
              )}
            </Card.Body>
          </Col>
        </Row>
      </Card>
      <Modal
        className="d-flex justify-content-center align-items-center"
        dialogClassName="archive-modal-width"
        show={downloadModal}
        onHide={() => setDownloadModal(false)}
      >
        <div className="py-3 px-3">
          <Modal.Body className="p-0">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="body-title text-gray-800 inter-600">
                Ready to design for {property_name}?
              </div>
              <div className="body-text2 text-gray-600 inter-400">
                You can download the files provided by the builder below to
                begin designing the interiors.
              </div>
            </div>
            <div className="body-text2 inter-500 my-3">Project Files:</div>
            {media_files?.length > 0 && (
              <div>
                {media_files.map((file, index) => (
                  <Card
                    key={index}
                    className="d-flex flex-row justify-content-between align-items-center border-gray-100 shadow-none mb-1"
                    style={{ borderRadius: 3 }}
                  >
                    <div className="d-flex align-items-center">
                      {file?.extension === ".pdf" ? (
                        <FaFilePdf
                          className="text-primary-500 me-2"
                          size={24}
                        />
                      ) : file?.extension === ".png" ||
                        file?.extension === ".jpg" ||
                        file?.extension === ".jpeg" ||
                        file?.extension === ".webp" ||
                        file?.extension === ".heic" ? (
                        <FaFileImage
                          className="me-2"
                          size={24}
                          color="#83c8e4"
                        />
                      ) : (
                        <FaFile className="text-primary-500 me-2" size={24} />
                      )}
                      <div className="d-flex flex-column">
                        <span className="body-text2 text-gray-900">
                          {file?.temp_file_name}
                        </span>
                        <span
                          className="text-gray-600"
                          style={{ fontSize: 10 }}
                        >
                          {file?.size}
                        </span>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            )}
            <div className="d-flex mt-3 w-100">
              <Button
                className="text-white body-text2 me-2 w-50"
                disabled={downloading}
                style={{ borderRadius: 3 }}
                onClick={handleDownloadAllMedia}
              >
                {downloading ? (
                  <span className="d-flex justify-content-center align-items-center">
                    <Spinner className="me-2" size="sm" /> Downloading..{" "}
                    <FiDownload size={16} className="ms-2" />
                  </span>
                ) : (
                  <span className="d-flex justify-content-center align-items-center">
                    Download All Files <FiDownload size={18} className="ms-2" />
                  </span>
                )}
              </Button>
              <Button
                className="bg-gray-50 border-0 text-gray-700 body-text2 w-50"
                style={{ borderRadius: 3 }}
                onClick={() => setDownloadModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default ListedPropertyCard;
