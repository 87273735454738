import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const FilterSearchBar = ({ searchText, setSearchText, onChangeFilters }) => {
  return (
    <div
      className="d-flex justify-content-center filter-container-library align-items-center flex-grow bg-transparent text-gray-700"
      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
    >
      <Form.Group className="d-flex w-100">
        <InputGroup>
          <Form.Control
            placeholder="Search Products"
            className="bg-transparent text-gray-700 p-0 px-2"
            style={{
              border: "none",
              boxShadow: "none",
              fontWeight: "bold",
            }}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              onChangeFilters(e.target.value, "q");
            }}
          />
        </InputGroup>
      </Form.Group>
    </div>
  );
};

export default FilterSearchBar;
