import React, { useEffect, useState } from "react";
import { Container, Form, Spinner } from "react-bootstrap";
import ListedProperties from "../ProfessionalSpecific/ListedProperties";
import MyProjects from "../ProfessionalSpecific/MyProjects";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { useSelector } from "react-redux";
import { CgSortAz } from "react-icons/cg";
import { IoClose, IoFilterSharp } from "react-icons/io5";
import { project_switch_card } from "assets";
import { useMediaQuery } from "react-responsive";
import { FaFilter } from "react-icons/fa";

const DesignerLanding = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  let params = new URLSearchParams(document.location.search);
  let projectType = params.get("project_type");
  let constructionStatus = params.get("construction_status");
  let propertySegment = params.get("property_segment");
  let sortBy = params.get("sort_by");
  let location = params.get("location");

  const constructionStatuses = [
    { id: 1, label: "Pre Construction", value: "PRE_CONSTRUCTION" },
    { id: 2, label: "Under Construction", value: "UNDER_CONSTRUCTION" },
    { id: 3, label: "Ready for Occupancy", value: "READY_FOR_OCCUPANCY" },
    { id: 4, label: "Completed", value: "COMPLETED" },
  ];

  // const propertySegments = [
  //   { id: 1, label: "Affordable Residential", value: "AFFORDABLE_RESIDENTIAL" },
  //   { id: 2, label: "Mid-Range Residential", value: "MID_RANGE_RESIDENTIAL" },
  //   { id: 3, label: "Luxury Residential", value: "LUXURY_RESIDENTIAL" },
  //   {
  //     id: 4,
  //     label: "Ultra-Luxury Residential",
  //     value: "ULTRA_LUXURY_RESIDENTIAL",
  //   },
  //   { id: 5, label: "Basic Commerical", value: "BASIC_COMMERCIAL" },
  //   { id: 6, label: "Mid-Range Commerical", value: "MID_RANGE_COMMERCIAL" },
  //   { id: 7, label: "High-End Commerical", value: "HIGH_END_COMMERCIAL" },
  // ];

  const propertySegments = [
    {
      id: 1,
      label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 2,
      label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)",
      value: "BASIC_COMMERCIAL",
    },
    {
      id: 3,
      label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 4,
      label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)",
      value: "MID_RANGE_COMMERCIAL",
    },
    {
      id: 5,
      label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 6,
      label: "High-End Commercial (₹4,000 per sq. ft. and above)",
      value: "HIGH_END_COMMERCIAL",
    },
    {
      id: 7,
      label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
  ];

  const [isProperty, setIsProperty] = useState(true);
  const [properties, setProperties] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [nextProject, setNextProject] = useState("");

  const allProjects = {
    title: "All Projects",
    image: project_switch_card,
    slug: null,
  };
  const [projectTypes, setProjectTypes] = useState([]);

  const fetchProjectTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectTypes();
      if (response.data) {
        setProjectTypes([allProjects, ...response.data?.results]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchArchitectProjects = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectsByArchitectUser(user.id);
      if (response.data) {
        setProjects(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const loadPaginatedActiveProject = async () => {
    setLoading(true);
    let url = `/project/projects/?architect__user=${user?.id}`;
    const response = await authActions.loadProjects(
      null,
      null,
      null,
      location,
      null,
      null,
      constructionStatus,
      propertySegment,
      sortBy
    );
    if (response) {
      // console.log(response, "response");
      setNextProject(response?.next);
      setProjectsCount(response.count);
      setProjects(response?.results);
    }
    setLoading(false);
  };

  const loadProjectsFilters = async () => {
    setLoading(true);
    try {
      const response = await authActions.loadProjectsFilters(
        constructionStatus,
        propertySegment,
        location,
        sortBy
      );
      setNextProject(response?.next);
      setProjects(response?.results);
      setProjectsCount(response?.count);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchAllLocations = async () => {
    try {
      const response = await authActions.fetchAllCities();
      if (response.data) {
        setAllLocations(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeFilters = (value, type) => {
    let filters = "";
    // Project Type
    if (type === "project_type") {
      filters += `project_type=${value}`;
    } else {
      filters += `project_type=${projectType}`;
    }

    // Construction Status
    if (type === "construction_status") {
      filters += `&construction_status=${value}`;
    } else {
      filters += `&construction_status=${constructionStatus}`;
    }

    // Property Segment
    if (type === "property_segment") {
      filters += `&property_segment=${value}`;
    } else {
      filters += `&property_segment=${propertySegment}`;
    }

    // Sort By
    if (type === "sort_by") {
      filters += `&sort_by=${value}`;
    } else {
      filters += `&sort_by=${sortBy}`;
    }

    // Location
    if (type === "location") {
      filters += `&location=${value}`;
    } else {
      filters += `&location=${location}`;
    }

    navigate(
      `/${user.user_type === "brand" ? "brand" : "architect"
      }/projects?${filters}`
    );
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    fetchProjectTypes();
    fetchArchitectProjects();
    fetchAllLocations();
  }, []);

  useEffect(() => {
    loadProjectsFilters();
  }, [constructionStatus, propertySegment, sortBy, location]);

  useEffect(() => {
    if (user.id) loadPaginatedActiveProject();
  }, [user, projectType]);

  if (propertyId) {
    return <Outlet />;
  }

  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        <div className="body-title mb-4 my-lg-4">
          <span className="inter-600">
            {properties?.count} properties available
          </span>
        </div>

        <>
          {/* Mobile Filter Icon */}
          <div className="d-block d-md-none">
            <FaFilter
              size={20}
              className="cursor-pointer my-2"
              onClick={handleToggleFilters}
            />
          </div>

          {/* Filters (Visible on desktop and toggled on mobile) */}
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${showFilters ? "" : "d-none d-md-flex" // Show filters when clicked on mobile or always on desktop
              }`}
          >
            <div className="row g-2 mb-3">
              <div className="col-12 col-md-auto">
                {constructionStatus && constructionStatus !== "null" ? (
                  <div
                    className="d-flex justify-content-center align-items-center filter-container flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {
                        constructionStatuses.filter(
                          (constStatus) =>
                            constStatus.value === constructionStatus
                        )[0].label
                      }
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        onChangeFilters(null, "construction_status")
                      }
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer w-100 text-wrap"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                    }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "construction_status")
                    }
                    value={
                      constructionStatus && constructionStatus !== "null"
                        ? constructionStatus
                        : ""
                    }
                  >
                    <option className="w-100 text-wrap" value="" disabled>
                      Construction Status
                    </option>
                    {constructionStatuses.map((constStatus, index) => {
                      return (
                        <option key={index + 1} value={constStatus.value}>
                          {constStatus.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>
              <div className="col-12 col-md-auto">
                {propertySegment && propertySegment !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {
                        propertySegments.filter(
                          (seg) => seg.value === propertySegment
                        )[0].label
                      }
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "property_segment")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "property_segment")
                    }
                    value={
                      propertySegment && propertySegment !== "null"
                        ? propertySegment
                        : ""
                    }
                  >
                    <option value="" disabled>
                      Property Segment
                    </option>
                    {propertySegments.map((propSeg, index) => {
                      return (
                        <option key={index + 1} value={propSeg.value}>
                          {!small
                            ? propSeg.label.split(" (")[0]
                            : propSeg.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>
              <div className="col-12 col-md-auto">
                {location && location !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {
                        allLocations.find((loc) => loc.title === location)
                          ?.title
                      }
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "location")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "location")
                    }
                    value={location && location !== "null" ? location : ""}
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {allLocations.map((loc, index) => (
                      <option key={index} value={loc.title}>
                        {loc.title}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
            </div>
            <div className="col-12 col-md-auto">
              <div className="custom-select-wrapper">
                <Form.Select
                  className="bg-transparent text-gray-700 cursor-pointer custom-select"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                  value={sortBy && sortBy !== "null" ? sortBy : ""}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="-created_at">Newest Listings</option>
                  <option value="created_at">Oldest Listings</option>
                </Form.Select>
                {sortBy && sortBy !== "null" ? (
                  <IoClose
                    size={24}
                    className="cursor-pointer ms-2 custom-close"
                    onClick={() => onChangeFilters(null, "sort_by")}
                  />
                ) : (
                  <CgSortAz size={24} className="custom-dropdown-icon" />
                )}
              </div>
            </div>
          </div>
        </>

        <div className="body-text1 d-flex">
          <div
            className="me-2 cursor-pointer"
            onClick={() => setIsProperty(true)}
          >
            <div
              className={`my-1 mx-2 inter-600 ${isProperty ? "text-primary-500" : "text-gray-400"
                }`}
            >
              Listed Properties ({properties?.count || 0})
            </div>
            <div
              className={`mt-1 w-100 ${isProperty ? "bg-primary" : "bg-gray-300"
                }`}
              style={{ height: 1 }}
            />
          </div>
          <div
            className="me-2 cursor-pointer"
            onClick={() => setIsProperty(false)}
          >
            <div
              className={`my-1 mx-2 inter-600 ${isProperty ? "text-gray-400" : "text-primary-500"
                }`}
            >
              My Projects ({projectCount ? projectCount : "0"})
            </div>
            <div
              className={`mt-1 w-100 ${isProperty ? "bg-gray-300" : "bg-primary"
                }`}
              style={{ height: 1 }}
            />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div className="my-1 text-white" style={{ userSelect: "none" }}>
              0
            </div>
            <div className="bg-gray-300 mt-1 w-100" style={{ height: 1 }} />
          </div>
        </div>
        {isProperty ? (
          <ListedProperties
            companyName={user.full_name}
            properties={properties}
            setProperties={setProperties}
            projectType={projectType}
            constructionStatus={constructionStatus}
            propertySegment={propertySegment}
            sortBy={sortBy}
            location={location}
          />
        ) : (
          <MyProjects
            companyName={user.full_name}
            projects={projects}
            setProjects={setProjects}
            loading={loading}
            projectType={projectType}
            constructionStatus={constructionStatus}
            propertySegment={propertySegment}
            setLoading={setLoading}
            sortBy={sortBy}
            location={location}
            setProjectsCount={setProjectsCount}
            setNextProject={setNextProject}
            nextProject={nextProject}
          />
        )}
      </Container>
    </Container>
  );
};

export default DesignerLanding;
