import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { FaSave } from "react-icons/fa";
import { LuMail } from "react-icons/lu";
import { FiEdit3, FiRefreshCcw } from "react-icons/fi";

import * as authActions from "reduxFiles/actions/authActions";
import { userUpdate } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { useLocation } from "react-router-dom";

import { default_pfp } from "assets";

function AccountEditForm() {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const form = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const [hover, setHover] = useState(false);

  const handleProfilePicUpdate = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_picture", file);
    try {
      const response = await authActions.updateProfilePic(user.id, formData);
      if (response.data) {
        notify("Profile Picture Updated!", "success");
        dispatch(userUpdate(response.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(form.current);
    formData.append("id", user.id);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    try {
      const response = await authActions.updateProfile(data.id, data);
      if (response.data) {
        notify("Profile Updated Successfully!", "success");
        dispatch(userUpdate(response.data));
        setIsEdit(false);
      } else {
        notify("Error while updating profile!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.hash !== "") {
      if (location.hash === "#edit") {
        setIsEdit(true);
      }
    }
  }, [location]);

  return (
    <Form
      ref={form}
      onSubmit={handleUpdate}
      className="border border-gray-100 mb-4"
      style={{ borderRadius: 6 }}
    >
      <div className="d-flex justify-content-between p-3">
        <div className={`h4 inter-600 mt-2`}>Contact Information</div>
        <div
          className="mt-2 me-2 cursor-pointer"
          onClick={() => setIsEdit(!isEdit)}
        >
          <FiEdit3 size={20} />
        </div>
      </div>
      <Row className="px-3 pb-3">
        <Col lg={5}>
          <Card
            className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
            style={{
              width: 180,
              height: 150,
              border: "1px dashed #BDC1C6",
              borderRadius: 6,
            }}
          >
            <Image
              className="p-0"
              style={{ borderRadius: 6, objectFit: "cover" }}
              maxWidth={180}
              maxHeight={150}
              src={user?.profile_picture || default_pfp}
              alt="avatar"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
            {hover && (
              <div
                className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: 6,
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => document.getElementById("profileUpload").click()}
              >
                <FiRefreshCcw size={20} color="white" />
                <span className="mt-1">Reupload Profile Image</span>
              </div>
            )}
            <input
              type="file"
              id="profileUpload"
              style={{ display: "none" }}
              onChange={handleProfilePicUpdate}
            />
          </Card>
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Name<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              type="text"
              disabled={!isEdit}
              className={`body-text2 py-2 border border-gray-300 ${
                isEdit ? "text-gray-900" : "text-gray-600"
              }`}
              name="full_name"
              defaultValue={user?.full_name}
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Company Email<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup
              className="border border-gray-300 bg-disabled"
              style={{ borderRadius: 3 }}
            >
              <InputGroup.Text
                className="bg-disabled border-0"
                style={{ borderRadius: 3 }}
              >
                <LuMail size={20} />
              </InputGroup.Text>
              {/* <Form.Control
                required
                disabled
                className={`body-text2 py-2 border-0 ${isEdit ? "text-gray-900" : "text-gray-600"}`}
                type="email"
                name="email"
                defaultValue={user?.email}
                placeholder="example@gmail.com"
              /> */}
              <div
                className={`ms-2 py-2 body-text2 border-0 bg-tertiary text-gray-600`}
              >
                {user?.email}
              </div>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Phone Number<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup
              className="border border-gray-300"
              style={{ borderRadius: 3 }}
            >
              <InputGroup.Text
                className={`border-0 body-text2 inter-500 ${
                  isEdit ? "bg-white" : "bg-disabled"
                }`}
                style={{ borderRadius: 3 }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                disabled={!isEdit}
                className={`body-text2 py-2 border-0 ${
                  isEdit ? "text-gray-900" : "text-gray-600"
                }`}
                placeholder="Phone number"
                name="mobile"
                defaultValue={user?.mobile}
                required
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Location<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              type="text"
              disabled={!isEdit}
              className={`body-text2 py-2 border border-gray-300 ${
                isEdit ? "text-gray-900" : "text-gray-600"
              }`}
              name="location"
              defaultValue={user?.location}
              style={{ borderRadius: 3 }}
            />
          </Form.Group>
        </Col>
      </Row>
      {isEdit && (
        <div className="d-flex p-3">
          <Button
            className="bg-transparent inter-500 border-gray-200 text-gray-900 body-text2"
            style={{ borderRadius: 3 }}
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="inter-500 body-text2 text-white ms-2 d-flex justify-content-center align-items-center"
            style={{ borderRadius: 3 }}
          >
            <FaSave size={18} className="me-2" />
            Save Profile
          </Button>
        </div>
      )}
    </Form>
  );
}

export default AccountEditForm;
