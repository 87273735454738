import { ad_1 } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import PropertyCard from "components/Cards/PropertyCard";
import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaBuildingColumns } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useScrollBottom, InfiniteScroll } from "components";
import MultipleAds from "components/Advertisements/MultipleAds";

const BuilderProperties = () => {
  const { propertyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let params = new URLSearchParams(document.location.search);
  let propertyName = params.get("property_name");

  const user = useSelector((state) => state.auth.user);
  const medium = useMediaQuery({ query: "(min-width: 768px)" });

  const [loading, setLoading] = useState(false);
  const [activeProperty, setActiveProperty] = useState(true);
  const [properties, setProperties] = useState([]);
  const [thisPropertyId, setThisPropertyId] = useState(null);
  const [thisPropertyName, setThisPropertyName] = useState(null);
  const [archivedProperties, setArchivedProperties] = useState([]);
  const [archiveModal, setArchiveModal] = useState(false);
  const [cancelArchiveModal, setCancelArchiveModal] = useState(false);
  const [unarchiveModal, setUnarchiveModal] = useState(false);

  const [noNextItem, setNoNextItem] = useState(true);
  const bottom = useScrollBottom();

  const loadPaginatedActiveProperties = async () => {
    setLoading(true);
    let url = `/property/properties/?builder__user=${user.id}&is_archived=false`;
    const response = await authActions.fetchPropertiesByBuilderUser(
      url,
      propertyName
    );
    if (response) {
      setProperties(response);
    }
    setLoading(false);
  };

  const loadMoreActiveProperties = async () => {
    const propertyList = properties.results;
    if (properties.next) {
      setNoNextItem(false);
      const newProperties = await authActions.fetchPropertiesByBuilderUser(
        properties.next,
        propertyName
      );
      if (newProperties) {
        const newResults = propertyList.concat(newProperties.results);
        newProperties.results = newResults;
        setProperties(newProperties);
      }
    } else {
      setNoNextItem(true);
    }
  };

  const loadPaginatedArchivedProperties = async () => {
    setLoading(true);
    let url = `/property/properties/?builder__user=${user.id}&is_archived=true`;
    const response = await authActions.fetchPropertiesByBuilderUser(
      url,
      propertyName
    );
    if (response) {
      setArchivedProperties(response);
    }
    setLoading(false);
  };

  const loadMoreArchivedProperties = async () => {
    const propertyList = properties.results;
    if (properties.next) {
      setNoNextItem(false);
      const newProperties = await authActions.fetchPropertiesByBuilderUser(
        properties.next,
        propertyName
      );
      if (newProperties) {
        const newResults = propertyList.concat(newProperties.results);
        newProperties.results = newResults;
        setArchivedProperties(newProperties);
      }
    } else {
      setNoNextItem(true);
    }
  };

  const requestArchive = async (propertyId) => {
    try {
      const response = await authActions.archivePropertyOrCancelRequest(
        propertyId,
        { archive_request: true }
      );
      if (response.data) {
        setArchiveModal(false);
        loadPaginatedActiveProperties();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelArchiveRequest = async (propertyId) => {
    try {
      const response = await authActions.archivePropertyOrCancelRequest(
        propertyId,
        { archive_request: false }
      );
      if (response.data) {
        setCancelArchiveModal(false);
        loadPaginatedActiveProperties();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const unarchiveProperty = async (propertyId) => {
    try {
      const response = await authActions.archivePropertyOrCancelRequest(
        propertyId,
        { archive_request: false, is_archived: false }
      );
      if (response.data) {
        setUnarchiveModal(false);
        loadPaginatedArchivedProperties();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeProperty) {
      loadPaginatedActiveProperties();
    } else {
      loadPaginatedArchivedProperties();
    }
  }, [activeProperty, propertyName]);

  useEffect(() => {
    if (location.state?.reload) {
      loadPaginatedActiveProperties();
    }
  }, [location.state]);

  useEffect(() => {
    if (bottom) {
      if (activeProperty) {
        loadMoreActiveProperties();
      } else {
        loadMoreArchivedProperties();
      }
    }
  }, [bottom]);

  if (propertyId) {
    return <Outlet />;
  }

  return (
    <Container fluid className="mt-4 pt-5 px-0" style={{ minHeight: "95vh" }}>
      {/* <div className="bg-gray-25 border-0">
        <Container className="py-4">
          <div className="h3 inter-600">{user?.full_name} Properties ({count})</div>
          <div className="body-title">
            All your properties will be listed here.
          </div>
        </Container>
      </div> */}
      <Container className="py-0">
        <div className="pt-5 d-flex flex-column">
          {propertyName && (
            <div className="d-flex justify-content-between align-items-center text-gray-700 mb-4">
              <span className="body-text1 me-1 inter-500">
                Showing results for "<b>{propertyName}</b>"
              </span>
              <span
                className="d-flex justify-content-center align-items-center py-2 px-2 text-gray-700 body-text3 inter-500 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onClick={() => navigate(`/builder/properties`)}
              >
                Clear Filter
              </span>
            </div>
          )}
          <div className="d-flex body-text1">
            <div
              className="me-2 cursor-pointer"
              onClick={() => setActiveProperty(true)}
            >
              <div
                className={`my-1 mx-2 inter-600 ${
                  activeProperty ? "text-primary-500" : "text-gray-400"
                }`}
              >
                Listed Properties ({properties?.count})
              </div>
              <div
                className={`mt-1 w-100 ${
                  activeProperty ? "bg-primary" : "bg-gray-300"
                }`}
                style={{ height: 1 }}
              />
            </div>
            <div
              className="me-2 cursor-pointer"
              onClick={() => setActiveProperty(false)}
            >
              <div
                className={`my-1 mx-2 inter-600 ${
                  activeProperty ? "text-gray-400" : "text-primary-500"
                }`}
              >
                Archived Properties ({archivedProperties?.count})
              </div>
              <div
                className={`mt-1 w-100 ${
                  activeProperty ? "bg-gray-300" : "bg-primary"
                }`}
                style={{ height: 1 }}
              />
            </div>
            {medium && (
              <div style={{ flexGrow: 1 }}>
                <div className="my-1 text-white" style={{ userSelect: "none" }}>
                  0
                </div>
                <div className="bg-gray-300 mt-1 w-100" style={{ height: 1 }} />
              </div>
            )}
          </div>
        </div>
        <Row>
          <Col lg={9}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : (
              <>
                {activeProperty ? (
                  properties?.count > 0 ? (
                    <>
                      {properties?.results?.map((property, index) => (
                        <PropertyCard
                          key={index}
                          {...property}
                          builder={property.builder_data.company_name}
                          // project_type={property.project_type_data.title}
                          setArchiveModal={setArchiveModal}
                          setCancelArchiveModal={setCancelArchiveModal}
                          setThisPropertyId={setThisPropertyId}
                          setThisPropertyName={setThisPropertyName}
                        />
                      ))}
                      <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
                    </>
                  ) : (
                    <div
                      className="py-4 d-flex flex-column justify-content-center align-items-center"
                      style={{ minHeight: "70vh" }}
                    >
                      {/* <div className="h2 inter-600">Hey, {user?.full_name} Builders!</div> */}
                      <div
                        className="d-flex justify-content-center align-items-center my-3"
                        style={{
                          backgroundColor: "#E8EAED",
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                        }}
                      >
                        {/* <Image src={professional_icon} style={{ width: 50 }} /> */}
                        <FaBuildingColumns size={60} />
                      </div>
                      <div className="h3 inter-600">
                        No properties added yet.
                      </div>
                      <div className="body-title text-gray-600">
                        Add your first property so that designers can start
                        creating inspiration!
                      </div>
                      <div className="mt-3">
                        <Button
                          className="d-flex align-items-center text-white"
                          style={{ borderRadius: 3 }}
                          onClick={() => navigate("/builder/addproperty")}
                        >
                          <FaPlus className="me-2" /> Add Property
                        </Button>
                      </div>
                    </div>
                  )
                ) : archivedProperties.count > 0 ? (
                  <>
                    {archivedProperties?.results?.map((property, index) => (
                      <PropertyCard
                        key={index}
                        {...property}
                        builder={property.builder_data.company_name}
                        // project_type={property.project_type_data.title}
                        setUnarchiveModal={setUnarchiveModal}
                        setThisPropertyId={setThisPropertyId}
                        setThisPropertyName={setThisPropertyName}
                      />
                    ))}
                    <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
                  </>
                ) : (
                  <div
                    className="py-4 d-flex flex-column justify-content-center align-items-center"
                    style={{ minHeight: "70vh" }}
                  >
                    {/* <div className="h2 inter-600">Hey, {user?.full_name} Builders!</div> */}
                    <div
                      className="d-flex justify-content-center align-items-center my-3"
                      style={{
                        backgroundColor: "#E8EAED",
                        width: 100,
                        height: 100,
                        borderRadius: "50%",
                      }}
                    >
                      {/* <Image src={professional_icon} style={{ width: 50 }} /> */}
                      <FaBuildingColumns size={60} />
                    </div>
                    <div className="h3 inter-600">
                      There are no Archived properties.
                    </div>
                    <div className="body-title text-gray-600">
                      Archived properties will not be shown to designers or
                      brands.
                    </div>
                    {/* <div className="mt-3">
                        <Button
                          className="d-flex align-items-center text-white"
                          style={{ borderRadius: 3 }}
                          onClick={() => navigate("/builder/addproperty")}
                        >
                          <FaPlus className="me-2" /> Add Property
                        </Button>
                      </div> */}
                  </div>
                )}
              </>
            )}
          </Col>
          <Col lg={3}>
            {/* <div className="my-4 position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-gray-900 fw-bold">
                  AD
                </Button>
              </div>
            </div> */}
            {/* <div className="my-4 position-relative" style={{ backgroundColor: "#F1F3F4", minHeight: '50vh', borderRadius: 6 }} >
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-gray-900 fw-bold">
                  AD
                </Button>
              </div>
            </div> */}
            <MultipleAds endPoint={"builder_ads"} />
          </Col>
        </Row>
      </Container>
      <Modal
        className="d-flex justify-content-center align-items-center"
        dialogClassName="archive-modal-width"
        show={archiveModal}
        onHide={() => {
          setArchiveModal(false);
        }}
      >
        <div className="py-3 px-3">
          <Modal.Body className="p-0">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="body-title text-gray-800 inter-600">
                Are you sure you want to ask admin to archive {thisPropertyName}{" "}
                listing?
              </div>
              <div className="body-text2 text-gray-600 inter-400">
                The property will placed in archived section from where you can
                unarchive it anytime. No more inspirations related to that
                property will be visible to you.
              </div>
              <div className="d-flex mt-3 w-100">
                <Button
                  className="text-white body-text2 me-2 w-50"
                  style={{ borderRadius: 3 }}
                  onClick={() => {
                    requestArchive(thisPropertyId);
                  }}
                >
                  Request Archive
                </Button>
                <Button
                  className="bg-brand-50 border-0 text-primary-500 body-text2 w-50"
                  style={{ borderRadius: 3 }}
                  onClick={() => setArchiveModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        className="d-flex justify-content-center align-items-center"
        dialogClassName="archive-modal-width"
        show={cancelArchiveModal}
        onHide={() => {
          setCancelArchiveModal(false);
        }}
      >
        <div className="py-3 px-3">
          <Modal.Body className="p-0">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="body-title text-gray-800 inter-600">
                Cancel Archive Request
              </div>
              <div className="body-text2 text-gray-600 inter-400">
                Are you sure you want cancel archive request for{" "}
                {thisPropertyName} listing?
              </div>
              <div className="d-flex mt-3 w-100">
                <Button
                  className="text-white body-text2 me-2 w-50"
                  style={{ borderRadius: 3 }}
                  onClick={() => {
                    cancelArchiveRequest(thisPropertyId);
                  }}
                >
                  Cancel Request
                </Button>
                <Button
                  className="bg-brand-50 border-0 text-primary-500 body-text2 w-50"
                  style={{ borderRadius: 3 }}
                  onClick={() => setCancelArchiveModal(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        className="d-flex justify-content-center align-items-center"
        dialogClassName="archive-modal-width"
        show={unarchiveModal}
        onHide={() => {
          setUnarchiveModal(false);
        }}
      >
        <div className="py-3 px-3">
          <Modal.Body className="p-0">
            <div className="d-flex flex-column justify-content-center align-items-center text-center">
              <div className="body-title text-gray-800 inter-600">
                Unarchive Property
              </div>
              <div className="body-text2 text-gray-600 inter-400">
                Are you sure you want to unarchive {thisPropertyName} listing?
              </div>
              <div className="d-flex mt-3 w-100">
                <Button
                  className="text-white body-text2 me-2 w-50"
                  style={{ borderRadius: 3 }}
                  onClick={() => {
                    unarchiveProperty(thisPropertyId);
                  }}
                >
                  Unarchive Property
                </Button>
                <Button
                  className="bg-brand-50 border-0 text-primary-500 body-text2 w-50"
                  style={{ borderRadius: 3 }}
                  onClick={() => setUnarchiveModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </Container>
  );
};

export default BuilderProperties;
