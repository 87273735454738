import React, { useState } from "react";
import { Card, Image, Button, Modal } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { useSelector } from "react-redux";
import userImg from "../../assets/navbar/user.webp";

const TeamMember = ({
  id,
  name,
  role,
  image,
  onDelete,
  user,
  handleDelete,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const authUser = useSelector((state) => state.auth.user);

  const handleDeleteClick = () => setShowConfirmModal(true);
  const handleCancelDelete = () => setShowConfirmModal(false);
  const handleConfirmDelete = async () => {
    await handleDelete(id);
    setShowConfirmModal(false);
  };

  return (
    <div className="inter-400 pe-4 position-relative">
      {/* Trash Button */}

      {authUser?.id == user && (
        <div
          className="position-absolute d-flex justify-content-center align-items-center"
          style={{
            top: "5px",

            right: "5px",

            zIndex: 2,

            width: "30px",

            height: "30px",

            backgroundColor: "#f8f9fa",

            borderRadius: "50%",

            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <FaTrash
            onClick={handleDeleteClick}
            className="text-secondary cursor-pointer"
            style={{ fontSize: "16px" }}
          />
        </div>
      )}

      {/* Profile Image */}
      <Image
        src={image || userImg}
        roundedCircle
        style={{
          width: "100px",

          height: "100px",

          border: "1px solid #E8EAED",

          objectFit: "cover",
        }}
        alt={`${name}'s profile`}
      />
      <Card.Body>
        <Card.Title
          className="body-title"
          style={{ marginTop: "10px", marginLeft: "11px" }}
        >
          {name}
        </Card.Title>
        <Card.Text
          className="text-muted body-text-2"
          style={{ marginLeft: "11px" }}
        >
          {role}
        </Card.Text>
      </Card.Body>

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelDelete} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this team member?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => handleConfirmDelete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TeamMember;
