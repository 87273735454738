import { useEffect, useState } from "react";
import ListedPropertyCard from "components/Cards/ListedPropertyCard";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import SavedRooms from "./SavedRooms";

export const SavedProperties = ({
  showAllRecords = false,
  showProperties = true,
}) => {
  const navigate = useNavigate();

  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);

  const [properties, setProperties] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [projects, setProjects] = useState([]);
  const [propertyLength, setPropertyLength] = useState(0);

  const [loading, setLoading] = useState(false);

  const itemsInRow = xlarge ? 3 : large ? 3 : medium ? 2 : 1;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchSavedPropertiesByArchitect({
        user: user.id,
        properties: showProperties,
      });
      if (response.data) {
        if (showProperties) {
          setPropertyLength(response.data["saved_properties"].length);
          setProperties(
            showAllRecords
              ? response.data["saved_properties"]
              : response.data["saved_properties"].slice(0, itemsInRow)
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomsData = async () => {
    try {
      const response = await authActions.fetchSavedRooms(user.id);
      if (response) {
        setRooms(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectsData = async () => {
    try {
      const response = await authActions.fetchSavedProjects(user.id);
      if (response) {
        setProjects(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const notInterestedAction = (index) => {
    const temp = [...properties];
    setProperties([
      ...temp.slice(0, index),
      ...temp.slice(index + 1),
      temp[index],
    ]);
  };

  useEffect(() => {
    fetchData();
    fetchRoomsData();
    fetchProjectsData();
  }, [showProperties]);

  return (
    <Container className="py-4 px-0">
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          <div>
            {showProperties && (
              <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="h4 inter-600 mb-0">
                    Saved Properties ({!loading && propertyLength})
                  </div>
                  {!showAllRecords && propertyLength > 3 && (
                    <div
                      className="body-text2 text-gray-900 inter-500 cursor-pointer"
                      onClick={() => navigate(`/user/saved#properties`)}
                    >
                      View all
                    </div>
                  )}
                </div>
                <Row className={`${properties?.length > 0 ? "px-3" : "px-3"}`}>
                  {properties?.length > 0 ? (
                    properties?.map((property, index) => (
                      <ListedPropertyCard
                        key={index}
                        index={index}
                        {...property?.saved_property_data}
                        builder={
                          property?.saved_property_data?.builder_data
                            ?.company_name
                        }
                        project_type={
                          property?.saved_property_data?.project_type_data
                            ?.title
                        }
                        notInterestedAction={notInterestedAction}
                      />
                    ))
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                      style={{ height: "40vh" }}
                    >
                      No saved properties!
                    </div>
                  )}
                </Row>
              </div>
            )}
          </div>
          {/* <SavedRooms rooms={rooms} loading={loading} /> */}
        </>
      )}
    </Container>
  );
};

export default SavedProperties;
