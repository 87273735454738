import { AccountEditForm, ChangePasswordForm } from "components";
import { Container } from "react-bootstrap";
import Library from "./Library";

const UserProfile = () => {

  return (
    <Container
      fluid
      className="mt-5 pt-4 px-0"
      style={{ minHeight: "100vh" }}
    >
      <Container>
        <h3 className="inter-600 text-gray-900 py-5 mb-0">My Profile</h3>
        <AccountEditForm />
        {/* <ChangePasswordForm /> */}
        <Library />
      </Container>
    </Container>
  );
};

export default UserProfile;
