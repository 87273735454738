import { useEffect, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import SpaceCategoryCard from "components/Cards/SpaceCategoryCard";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ShareModal from "components/General/ShareModal";
import { project_switch_card } from "assets";

import { FiRefreshCcw } from "react-icons/fi";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { useScrollBottom, InfiniteScroll } from "components";
import MobileProjectCard from "components/Cards/MobileProjectCard";
import AllProfessionalCard from "components/Cards/AllProfessionalCard";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import BrandCard from "components/Cards/BrandCard";
import ProfessionalDetails from "pages/Professionals/ProfessionalDetails";
import { FaFilter } from "react-icons/fa6";
import Slider from "react-slick";
import ProjectCategoryCard from "components/Cards/ProjectCategoryCard";

const ProjectList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { pathname } = useLocation();

  // const xsmall = useMediaQuery({ query: "(min-width: 400px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });
  // const xxxlarge = useMediaQuery({ query: "(min-width: 1600px)" });

  let params = new URLSearchParams(document.location.search);
  let projectType = params.get("project_type");
  let property = params.get("property");
  let bhk = params.get("bhk");
  let sortBy = params.get("sort_by");
  let location = params.get("location");
  let professionals = params.get("professionals");
  let search = params.get("search");

  const [switchPage, setSwitchPage] = useState(false);
  const [properties, setProperties] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [showShareProject, setShowShareProject] = useState(false);
  const [professionalsResult, setProfessionalsResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPropertyName, setSelectedPropertyName] = useState("");

  const [showFilters, setShowFilters] = useState(false);

  const [noNextItem, setNoNextItem] = useState(true);
  const bottom = useScrollBottom();

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const layouts = [
    { id: 1, label: "2 BHK", value: "2BHK" },
    { id: 2, label: "2.5 BHK", value: "2.5BHK" },
    { id: 3, label: "3 BHK", value: "3BHK" },
    { id: 4, label: "3.5 BHK", value: "3.5BHK" },
    { id: 5, label: "4 BHK", value: "4BHK" },
  ];

  const filteredProperties = properties.filter((property) =>
    property.property_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchAllLocations = async () => {
    try {
      const response = await authActions.fetchAllCities();
      if (response.data) {
        setAllLocations(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectTypes();
      if (response.data) {
        setProjectTypes(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProperties = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchAllProperties();
      if (response.data) {
        setProperties(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  // const onChangeFilters = (value, type) => {
  //   let filters = "?";
  //   // Project Type
  //   if (type === "project_type") {
  //     filters += `project_type=${value}`;
  //   } else {
  //     filters += `project_type=${projectType}`;
  //   }

  //   // Property
  //   if (type === "property") {
  //     filters += `&property=${value}`;
  //   } else {
  //     filters += `&property=${property}`;
  //   }

  //   // Layout
  //   if (type === "bhk") {
  //     filters += `&bhk=${value}`;
  //   } else {
  //     filters += `&bhk=${bhk}`;
  //   }

  //   // Sort By
  //   if (type === "sort_by") {
  //     // If the value is an empty string, remove the sort_by filter
  //     if (value === null) {
  //       filters = filters.replace(/&sort_by=[^&]*/, ''); // Remove the sort_by part
  //     } else {
  //       filters += `&sort_by=${value}`;
  //     }
  //   } else {
  //     filters += `&sort_by=${sortBy}`;
  //   }

  //   // Location
  //   if (type === "location") {
  //     filters += `&location=${value}`;
  //   } else {
  //     filters += `&location=${location}`;
  //   }

  //   navigate(`/projects${filters}`);
  // };

  const onChangeFilters = (value, type) => {
    const filterParams = {
      project_type: type === "project_type" ? value : projectType,
      property: type === "property" ? value : property,
      bhk: type === "bhk" ? value : bhk,
      sort_by: type === "sort_by" ? value : sortBy,
      location: type === "location" ? value : location,
    };

    // Remove filters with null or empty values
    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val) // Only include filters with non-null, non-empty values
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    // Navigate to the updated URL
    navigate(`/projects?${queryString}`);
  };

  const loadPaginatedProjects = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjects(
        `/project/projects/`,
        null,
        projectType,
        property,
        location,
        bhk,
        search,
        sortBy,
        null
      );
      if (response) {
        setProjects(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const loadProfessionals = async () => {
    try {
      const response = await authActions.fetchAllProfessionalsByText(
        professionals
      );
      if (response) {
        setProfessionalsResult(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const loadMoreProjects = async () => {
    const projectList = projects.results;
    if (projects.next) {
      setNoNextItem(false);
      const newProjects = await authActions.fetchProjects(projects.next);
      if (newProjects) {
        const newResults = projectList.concat(newProjects.results);
        newProjects.results = newResults;
        setProjects(newProjects);
      }
    } else {
      setNoNextItem(true);
    }
  };

  useEffect(() => {
    fetchProjectTypes();
    fetchProperties();
    fetchAllLocations();
  }, []);

  useEffect(() => {
    loadProfessionals();
  }, [professionals]);

  useEffect(() => {
    loadPaginatedProjects();
  }, [projectType, property, location, bhk, search, sortBy]);

  // useEffect(() => {
  //   loadPaginatedProjects();
  // }, []);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow-spaces`}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowLeft size={25} color="black" /> */}
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow-spaces`}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowRight size={25} color="black" /> */}
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: xxlarge
      ? 8.5
      : xlarge
      ? 7
      : large
      ? 6
      : medium
      ? 4
      : small
      ? 2.5
      : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // console.log(projectTypes, "projectTypes")

  useEffect(() => {
    if (bottom) {
      loadMoreProjects();
    }
  }, [bottom]);

  if (slug) {
    return <Outlet />;
  }

  return (
    <Container fluid>
      <Container
        className={`mt-5 pt-5 ${professionals ? "d-none" : "d-block"}`}
      >
        {small && (
          <>
            <div className="d-flex" style={{ overflowX: "scroll" }}>
              <div className="pe-3">
                <Card
                  className={`border-0 p-0 text-start bg-brand-500 text-white shadow-none cursor-pointer`}
                  style={{ width: 128, borderRadius: 5 }}
                  onMouseEnter={() => setSwitchPage(true)}
                  onMouseLeave={() => setSwitchPage(false)}
                  onClick={() => navigate("/spaces")}
                >
                  {switchPage && (
                    <div
                      className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        borderRadius: 6,
                      }}
                    />
                  )}
                  <Card.Img
                    className="img-fluid"
                    vadivriant="top"
                    src={project_switch_card}
                    style={{
                      width: 128,
                      maxHeight: "90px",
                      minHeight: "100px",
                      borderRadius: "",
                    }}
                  />
                  <Card.Body className="px-0 py-2 text-center ">
                    <Card.Title className="body-text2 m-0 d-flex justify-content-center align-items-center">
                      <span className="me-2">
                        {switchPage ? "Spaces" : "Projects"}
                      </span>
                      <FiRefreshCcw size={18} />
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>

              <div
                style={{
                  width: large ? "89%" : medium || small ? "80%" : "50%",
                  paddingLeft: "1rem", // Equivalent to `ps-3` in Bootstrap
                }}
              >
                <Slider {...settings}>
                  {properties?.map((property, index) => (
                    <ProjectCategoryCard
                      key={index}
                      {...property}
                      selected={projectType}
                      onClick={() => {
                        if (projectType === property.slug) {
                          onChangeFilters(null, "property");
                        } else {
                          onChangeFilters(property.slug, "property");
                        }
                      }}
                    />
                  ))}
                </Slider>
              </div>
            </div>
            <div className="body-title my-4">
              {location && location !== "null" && (
                <div>
                  Search results for
                  <span className="inter-600">{location}</span>
                </div>
              )}
              {search && search !== "null" && (
                <div>
                  Search results for <span className="inter-600">{search}</span>
                </div>
              )}
              <span className="inter-600">{projects?.count} real projects</span>{" "}
              found in your city
            </div>
          </>
        )}

        <>
          {/* Mobile Filter Icon */}
          <div className="d-block d-md-none">
            <FaFilter
              size={20}
              className="cursor-pointer my-2"
              onClick={handleToggleFilters}
            />
          </div>

          {/* Filters (Visible on desktop and toggled on mobile) */}
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${
              showFilters ? "" : "d-none d-md-flex" // Show filters when clicked on mobile or always on desktop
            }`}
          >
            <div className="row g-2 mb-3">
              <div className="col-12 col-md-auto">
                {property && property !== "null" ? (
                  <div
                    className="d-flex justify-content-center align-items-center flex-grow bg-transparent filter-container text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {properties.filter((prop) => prop.slug === property)
                        .length > 0
                        ? properties.filter((prop) => prop.slug === property)[0]
                            .property_name
                        : property}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "property")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 cursor-pointer"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                      height: "40px",
                    }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "property")
                    }
                    value={property && property !== "null" ? property : ""}
                  >
                    <option value="" disabled>
                      Property Name
                    </option>
                    {properties.map((property, index) => {
                      return (
                        <option key={index + 1} value={property.slug}>
                          {property.property_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>

              <div className="col-12 col-md-auto">
                {bhk && bhk !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {layouts.find((loc) => loc.value === bhk)?.label}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "bhk")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 cursor-pointer"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                      height: "40px",
                    }}
                    onChange={(e) => onChangeFilters(e.target.value, "bhk")}
                    value={bhk && bhk !== "null" ? bhk : ""}
                  >
                    <option value="" disabled>
                      Floor Layout
                    </option>
                    {layouts.map((layout, index) => {
                      return (
                        <option key={index + 1} value={layout.value}>
                          {layout.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>

              <div className="col-12 col-md-auto">
                {location && location !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {
                        allLocations.find((loc) => loc.title === location)
                          ?.title
                      }
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "location")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 cursor-pointer"
                    style={{
                      border: "1px solid #BDC1C6",
                      borderRadius: 3,
                      height: "40px",
                    }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "location")
                    }
                    value={location && location !== "null" ? location : ""}
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {allLocations.map((loc, index) => (
                      <option key={index} value={loc.title}>
                        {loc.title}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
            </div>

            <div className="col-12 col-md-auto">
              <div className="custom-select-wrapper d-flex">
                <Form.Select
                  className="bg-transparent text-gray-700 cursor-pointer custom-select"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                    height: "40px",
                  }}
                  onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                  value={sortBy || ""}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="-created_at">Featured (New to Old)</option>
                  <option value="created_at">Featured (Old to New)</option>
                </Form.Select>

                {sortBy ? (
                  <IoClose
                    size={24}
                    className="cursor-pointer ms-2 custom-close"
                    onClick={() => onChangeFilters(null, "sort_by")}
                  />
                ) : (
                  <CgSortAz size={24} className="custom-dropdown-icon" />
                )}
              </div>
            </div>
          </div>
        </>

        <div className={`mb-3 ${professionals ? "d-none" : "d-block"}`}>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : projects?.count > 0 ? (
            <div className="mb-3">
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center w-100"
                  style={{ minHeight: "50vh" }}
                >
                  <Spinner />
                </div>
              ) : projects?.count > 0 ? (
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ minHeight: "80vh" }}
                >
                  <Row className="w-100">
                    {projects?.results?.map((item, index) => (
                      <Col
                        key={index}
                        lg={4}
                        md={6}
                        sm={12}
                        className={`d-flex flex-column ps-lg-0 py-2 mb-2 justify-content-start ${
                          small ? "" : "p-0"
                        }`}
                      >
                        {small ? (
                          <ProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            image={item?.rooms[0]?.media[0]?.file}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            architect={item?.company_name}
                            location={item?.property_data?.location}
                            builders={
                              item?.property_data?.builder_data?.company_name
                            }
                            className="mx-0 flex-grow"
                          />
                        ) : (
                          <MobileProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            image={item?.rooms[0]?.media[0]?.file}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            builder_name={
                              item?.property_data?.builder_data?.company_name
                            }
                            architect={item?.company_name}
                            className="w-100 mobile-card"
                          />
                        )}
                      </Col>
                    ))}
                    <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
                  </Row>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
                  style={{ height: "60vh" }}
                >
                  No projects available
                </div>
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
              style={{ height: "60vh" }}
            >
              No projects available
            </div>
          )}
        </div>
      </Container>
      <Container
        className={`mt-5 pt-5 ${professionals ? "d-block" : "d-none"}`}
      >
        {professionalsResult?.architects?.length > 0 && (
          <>
            <div className="body-title text-gray-900 my-4">
              <span className="inter-600">Architects</span> found
            </div>
            {professionalsResult?.architects?.map((architect) => {
              return (
                <ProfessionalCard
                  key={architect.id}
                  {...architect}
                  project_img={""}
                  type={"Architect"}
                  profile_img={architect.user_data?.profile_picture}
                  onClick={() =>
                    navigate(`/professionals/architects/${architect.slug}`)
                  }
                />
              );
            })}
          </>
        )}
        {professionalsResult?.builders?.length > 0 && (
          <>
            <div className="body-title text-gray-900 my-4">
              <span className="inter-600">Builders</span> found
            </div>
            {professionalsResult?.builders?.map((builder) => {
              return (
                <AllProfessionalCard
                  key={builder.id}
                  id={builder.id}
                  slug={builder.slug}
                  company_name={builder.company_name}
                  image={builder.user_data.profile_picture}
                />
              );
            })}
          </>
        )}
        {professionalsResult?.brands?.length > 0 && (
          <>
            <div className="body-title text-gray-900 my-4">
              <span className="inter-600">Brands</span> found
            </div>
            {professionalsResult?.brands?.map((brand) => {
              return (
                <BrandCard
                  key={brand.id}
                  id={brand.user_data.id}
                  slug={brand.slug}
                  companyName={brand.company_name}
                  image={brand?.image}
                  warranty={brand.warranty}
                  rating={brand.rating}
                  averagePricing={brand.averagePricing}
                />
              );
            })}
          </>
        )}
      </Container>
    </Container>
  );
};

export default ProjectList;
