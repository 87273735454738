import { ad_1, ad_2, all_products } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import BrandCard from "components/Cards/BrandCard";
import Pagination from "components/Pagination";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { FaFilter } from "react-icons/fa6";
import MultipleAds from "components/Advertisements/MultipleAds";

const BrandsList = () => {
  const { slug } = useParams();
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [brandCategories, setBrandCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productSubTypes, setProductSubTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [productType, setProductType] = useState("");
  const [productSubType, setProductSubType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [brand, setBrand] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [next, setNext] = useState("");
  const scrollRef = useRef();
  const navigate = useNavigate();

  let searchParams = new URLSearchParams(document.location.search);
  const brandId = searchParams.get("brandId");

  const [brandCategory, setbrandCategory] = useState(
    searchParams.get("category") || ""
  );
  // console.log({ brandId });

  const fetchBrands = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsPagewise(page);
      if (response.data) {
        console.log(response, "response.data.results");
        setBrands((prev) => [...prev, ...response.data.results]);
        // response.data.results
        // setTotalPages(Math.ceil(response.data.count / 12));
        const totalFetched = page * 12;
        setHasMore(totalFetched < response.data.count);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchBrandCategories = async () => {
    try {
      const response = await authActions.fetchBrandCategoriesNoPagination();
      if (response?.data) {
        // setNext(response?.data?.next);
        setBrandCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(brandCategories);

  const fetchProductTypes = async (categoryId) => {
    try {
      const response = await authActions.fetchProductTypes(categoryId);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductSubTypes = async (typeId) => {
    try {
      const response = await authActions.fetchProductSubTypes(typeId);
      console.log(response);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let searchParams = new URLSearchParams(document.location.search);

    let category = searchParams.get("product_category");
    let type = searchParams.get("product_type");
    let subType = searchParams.get("product_sub_type");
    let sortBy = searchParams.get("sort_by");
    let brandParam = searchParams.get("brand");

    if (category) {
      setbrandCategory(category); // Set the selected category
      fetchProductTypes(category); // Fetch product types based on selected category
    }

    if (type) {
      setProductType(type); // Set the selected product type
      fetchProductSubTypes(type); // Fetch product subtypes based on selected type
    }

    if (subType) setProductSubType(subType);
    if (sortBy) setSortBy(sortBy);
    if (brandParam) setBrand(brandParam);
  }, [productType]);

  const onChangeFilters = (value, type) => {
    const filterParams = {
      category: type === "category" ? value : brandCategory,
      product_type: type === "product_type" ? value : productType,
      product_sub_type: type === "product_sub_type" ? value : productSubType,
      sort_by: type === "sort_by" ? value : sortBy,
      brand: type === "brand" ? value : brand,
    };

    // if (type === "category") {
    //   setbrandCategory(value);
    //   setProductType("");
    //   setProductSubType("");
    //   fetchProductTypes(value);
    // }

    // if (type === "product_type") {
    //   setProductType(value);
    //   console.log("Selected product type ID: ", value);

    //   fetchProductSubTypes(value);

    // }

    // if (type === "product_sub_type") {
    //   setProductSubType(value);
    // }

    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    if (type === "category") {
      setbrandCategory(value);
      // } else if (type === "product_type") {
      //   setProductType(value);
      // } else if (type === "product_sub_type") {
      //   setProductSubType(value);
    } else if (type === "sort_by") {
      setSortBy(value);
    } else if (type === "brand") {
      setBrand(value);
    }

    navigate(`/brands?${queryString}`);
  };

  const loadPaginatedBrands = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsByFilter(
        "/user/rest/brand-info/",
        sortBy,
        brandCategory,
        brand
      );
      if (response?.results) {
        // console.log(response, "response.data");
        setBrands(response?.results);
        const totalFetched = currentPage * 12;
        setHasMore(totalFetched < response?.count);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const loadPaginatedBrandsFromBrandId = async (brandId) => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsByFilter(
        "/user/rest/brand-info/",
        null,
        brandId,
        null
      );
      console.log("brandId");

      if (response?.results) {
        console.log(response?.results);
        setBrands(response?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const resetFilters = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchBrandsByFilter(
        "/user/rest/brand-info/",
        null,
        null,
        null
      );
      if (response?.results) {
        setBrands(response?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!brandId && next !== null) {
      loadPaginatedBrands(); // 1
      setCurrentPage(1);
    }
    // alert();
  }, [sortBy, brandCategory, brand]);

  useEffect(() => {
    if (brandId) {
      loadPaginatedBrandsFromBrandId(brandId);
      setCurrentPage(1);
    }
    // alert();
  }, [brandId]);

  useEffect(() => {
    fetchBrandCategories();
  }, []);

  // useEffect(() => {
  //   if (brandCategory) {
  //     fetchProductTypes(brandCategory);
  //   }
  // }, [brandCategory]);

  // useEffect(() => {
  //   if (productType) {
  //     fetchProductSubTypes(productType);
  //   }
  // }, [productType]);

  // useEffect(() => {
  //   if (productSubType) {
  //     fetchBrands();
  //   }
  // }, [productSubType]);

  useEffect(() => {
    if (!brandId && currentPage > 1) {
      fetchBrands(currentPage); // 2
    }
  }, [currentPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasMore && !loading) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1.0 }
    );

    if (scrollRef.current) {
      observer.observe(scrollRef.current);
    }

    return () => {
      if (scrollRef.current) {
        observer.unobserve(scrollRef.current);
      }
    };
  }, [hasMore, loading]);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  if (slug) {
    return <Outlet />;
  }

  return (
    <Container className="mt-5 pt-5">
      <section className="d-flex flex-column alignt-items-center">
        {/* <div className="d-flex">
          <div
            className="card me-2 mb-2"
            style={{
              width: "150px",
              border: "1px solid #BDC1C6",
              borderRadius: "5px",
              cursor: "pointer",
              padding: '0px'
              // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={() => resetFilters()}
          >
            <img
              src={all_products}
              alt='all products'
              className="card-img-top"
              style={{
                width: '100%',
                height: "100px",
                objectFit: "cover",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                margin: '0px'
              }}
            />
            <div className="card-body text-center p-1">
              <h6 className="card-title text-gray-700 mb-0">All Products</h6>
            </div>
          </div>
          {Array.isArray(brandCategories) && brandCategories?.map((brandCat, index) => (
            <div
              key={index}
              className="card me-2 mb-2"
              style={{
                width: "150px",
                border: "1px solid #BDC1C6",
                borderRadius: "5px",
                cursor: "pointer",
                padding: '0px'
                // boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => {
                setbrandCategory(brandCat.id);
                onChangeFilters(brandCat.id, "category");
              }}
            >
              <img
                src={brandCat?.image || all_products} // Replace with the correct image path
                alt={brandCat?.name}
                className="card-img-top"
                style={{
                  width: '100%',
                  height: "100px",
                  objectFit: "cover",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                  margin: '0px'
                }}
              />
              <div className="card-body text-center p-1">
                <h6 className="card-title text-gray-700 mb-0">{brandCat.name}</h6>
              </div>
            </div>
          ))}
        </div> */}
        <div className="body-title my-4">
          <span className="inter-600">
            Brands Available {brands ? brands?.length : "0"}
          </span>
        </div>

        {/* <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="row g-2 mb-3">
            <div className="col-12 col-md-auto">
              {brandCategory && brandCategory !== "null" ? (
                <div
                  className="d-flex justify-content-center align-items-center filter-container flex-grow bg-transparent text-gray-700 px-2 me-2"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <span className="me-2">
                    {brandCategories.find((b) => b.id === brandCategory)?.name}
                  </span>
                  <div
                    className="cursor-pointer"
                    onClick={() => onChangeFilters(null, "category")}
                  >
                    <IoClose size={20} />
                  </div>
                </div>
              ) : (
                <Form.Select
                  className="bg-transparent text-gray-700 me-2 cursor-pointer"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "category")}
                  value={
                    brandCategory && brandCategory !== "null"
                      ? brandCategory
                      : ""
                  }
                >
                  <option value="" disabled>
                    Brand Category
                  </option>
                  {brandCategories.map((b, index) => (
                    <option key={index} value={b.id}>
                      {b.name}
                    </option>
                  ))}
                </Form.Select>
              )}
            </div>

            <div className="col-12 col-md-auto">
              {brand && brand !== "null" ? (
                <div
                  className="d-flex filter-container justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <span className="me-2">
                    {
                      brands?.filter?.((f) => f.slug === brand)?.[0]
                        ?.company_name
                    }
                  </span>
                  {brand && (
                    <IoClose
                      size={24}
                      className="cursor-pointer ms-2"
                      onClick={() => onChangeFilters(null, "brand")}
                    />
                  )}
                </div>
              ) : (
                <Form.Select
                  className="bg-transparent text-gray-700 me-2 cursor-pointer"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "brand")}
                  value={brand && brand !== "null" ? brand : ""}
                >
                  <option value="" disabled>
                    Brand
                  </option>
                  {brands?.map((b, index) => {
                    return (
                      <option key={index + 1} value={b.slug}>
                        {b.company_name}
                      </option>
                    );
                  })}
                </Form.Select>
              )}
            </div>
          </div>

          <div className="col-12 col-md-auto">
            <div>
              <div className="custom-select-wrapper d-flex">
                <Form.Select
                  className="bg-transparent text-gray-700 cursor-pointer custom-select"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                  value={sortBy || ""}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="created_at">Featured (New to Old)</option>
                  <option value="-created_at">Featured (Old to New)</option>
                </Form.Select>
                {sortBy ? (
                  <IoClose
                    size={24}
                    className="cursor-pointer ms-2 custom-close"
                    onClick={() => onChangeFilters(null, "sort_by")}
                  />
                ) : (
                  <CgSortAz size={24} className="custom-dropdown-icon" />
                )}
              </div>
            </div>
          </div>
        </div> */}

        <>
          {/* Mobile Filter Icon */}
          <div className="d-block d-md-none">
            <FaFilter
              size={20}
              className="cursor-pointer my-2"
              onClick={handleToggleFilters}
            />
          </div>

          {/* Filters (Visible on desktop and toggled on mobile) */}
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${
              showFilters ? "" : "d-none d-md-flex" // Show filters when clicked on mobile or always on desktop
            }`}
          >
            <div className="row g-2 mb-3">
              <div className="col-12 col-md-auto">
                {brandCategory && brandCategory !== "null" ? (
                  <div
                    className="d-flex justify-content-center align-items-center filter-container flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {
                        brandCategories.find((b) => b.id === brandCategory)
                          ?.name
                      }
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "category")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "category")
                    }
                    value={
                      brandCategory && brandCategory !== "null"
                        ? brandCategory
                        : ""
                    }
                  >
                    <option value="" disabled>
                      Brand Category
                    </option>
                    {brandCategories.map((b, index) => (
                      <option key={index} value={b.id}>
                        {b.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>

              <div className="col-12 col-md-auto">
                {brand && brand !== "null" ? (
                  <div
                    className="d-flex filter-container justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {
                        brands?.filter?.((f) => f.slug === brand)?.[0]
                          ?.company_name
                      }
                    </span>
                    {brand && (
                      <IoClose
                        size={24}
                        className="cursor-pointer ms-2"
                        onClick={() => onChangeFilters(null, "brand")}
                      />
                    )}
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) => onChangeFilters(e.target.value, "brand")}
                    value={brand && brand !== "null" ? brand : ""}
                  >
                    <option value="" disabled>
                      Brand
                    </option>
                    {brands?.map((b, index) => {
                      return (
                        <option key={index + 1} value={b.slug}>
                          {b.company_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>
            </div>

            <div className="col-12 col-md-auto">
              <div>
                <div className="custom-select-wrapper d-flex">
                  <Form.Select
                    className="bg-transparent text-gray-700 cursor-pointer custom-select"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                    value={sortBy || ""}
                  >
                    <option value="" disabled>
                      Sort By
                    </option>
                    <option value="created_at">Featured (New to Old)</option>
                    <option value="-created_at">Featured (Old to New)</option>
                  </Form.Select>
                  {sortBy ? (
                    <IoClose
                      size={24}
                      className="cursor-pointer ms-2 custom-close"
                      onClick={() => onChangeFilters(null, "sort_by")}
                    />
                  ) : (
                    <CgSortAz size={24} className="custom-dropdown-icon" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>

        <Row>
          <Col lg={8} xl={9}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : brands?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {brands?.map((brand, index) => {
                  return (
                    <BrandCard
                      id={brand.user}
                      slug={brand.slug}
                      companyName={brand.company_name}
                      image={brand?.image}
                      // image={brand.user_data?.profile_picture}
                      brandCategories={brand?.brand_category_data}
                      warranty={brand?.warranty}
                      rating={brand?.rating}
                      averagePricing={brand?.average_pricing}
                      minPricing={brand?.min_pricing}
                      maxPricing={brand?.max_pricing}
                    />
                  );
                })}
                <div
                  ref={scrollRef}
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 50 }}
                >
                  {loading && <Spinner />}
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 160 }}
              >
                No brands available
              </div>
            )}
          </Col>
          <Col lg={4} xl={3} className=" mt-4">
            {/* <div className="my-4 position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-gray-900 fw-bold">
                  AD
                </Button>
              </div>
            </div>
            <div className="position-relative">
              <div className="w-100">
                <img className="img-fluid w-100" src={ad_1} alt="Ads" />
              </div>
              <div className="position-absolute" style={{ top: 10, right: 10 }}>
                <Button variant="info" className="text-gray-900 fw-bold">
                  AD
                </Button>
              </div>
            </div> */}
            <MultipleAds endPoint={"customer_ads"} />
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default BrandsList;
