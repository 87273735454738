import React, { useEffect, useState } from "react";
import { ad_1, ad_2 } from "assets";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import * as authActions from "reduxFiles/actions/authActions";

const BuildersList = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const [builders, setBuilders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchBuilders = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchBuildersPagewise(page);
      if (response.data) {
        setBuilders((prevBuilders) => [
          ...prevBuilders,
          ...response.data?.results,
        ]);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBuilders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      const bottom =
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100;

      if (bottom && !loading && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [currentPage, loading, totalPages]);

  if (detailedSlug) {
    return <Outlet />;
  }

  return (
    <Container className="mt-5 pt-4 px-0">
      <section className="d-flex flex-column alignt-items-center">
        <div>
          <div className="h2 inter-600">Our Builders</div>
          <div className="geist">
            We have builders with 15+ years of experience and with 500+
            completed projects
          </div>
        </div>
        <Row className="mb-4">
          <Col md={8}>
            <div className="d-flex flex-column align-items-center w-100">
              {builders?.map((builder, index) => (
                <ProfessionalCard
                  key={index}
                  {...builder}
                  type={"Builder"}
                  profile_img={builder.user_data?.profile_picture}
                  onClick={() =>
                    navigate(`/professionals/builders/${builder.slug}`)
                  }
                />
              ))}
              {loading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "50vh" }}
                >
                  <Spinner />
                </div>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className="my-4">
              <img className="img-fluid" src={ad_1} alt="Ads" />
            </div>
            <div>
              <img className="img-fluid" src={ad_2} alt="Ads" />
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default BuildersList;
