import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { LuEye, LuEyeOff, LuMail } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpAndLogin } from "reduxFiles/reducer/authSlices";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import { notify } from "utilities/notifications/Notify";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import FacebookGoogleLogin from "components/Auth/FacebookGoogleLogin";
import { validateEmail } from "utilities/validateEmail";

const BookConsultationForm = ({
  form,
  // loading,
  showLoginForm,
  setShowLoginForm,
  otpInput,
  handleSignUp,
  handleSubmitRequest,
  handleLogin,
}) => {
  const dispatch = useDispatch();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const reviewForm = useRef();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [otp, setOtp] = useState("");
  const [resendTimer, setResendTimer] = useState(120);
  const [loading, setLoading] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [otpSent, setOtpSent] = useState(false);

  // const [formData, setFormData] = useState({
  //   requirements_msg: ''
  // })

  // useImperativeHandle(form, () => ({
  //   getData() {
  //     return formData
  //   }
  // }))


  const handleSendOtp = async (e) => {
    e.preventDefault()
    setLoading(true);
    const formData = new FormData(form.current);
    const email = formData.get('email');
    const fullName = formData.get('full_name'); 
    const phone = formData.get('mobile');  

    if (validateEmail(email)) {
      try {
        const response = await authActions.signup({ email, full_name:fullName, mobile:phone });
        if (response?.status === "SUCCESSFUL") {
          setOtpSent(true);
          setResendTimer(response.time_left); // Reset timer
          setIsResendDisabled(true); // Disable resend button
          notify("OTP sent successfully!", "success");
        } else {
          notify(response?.msg || "Failed to send OTP. Try again.", "error");
        }
      } catch (error) {
        console.error(error);
        notify("Error while sending OTP. Please try again.", "error");
      }
    } else {
      notify("Please enter a valid email ID!", "error");
    }
    setLoading(false);
  };

  const verifyOtpLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    const data = {
      username: formData.get("email"),
      email_verified_otp: formData.get("email_verified_otp"),
    };
    console.log(data);
    try {
      const response = await authActions.verfiyOtpLogin(data);
      if (response.data) {
        if (response.data?.user?.is_active) {
          dispatch(verifyOtpAndLogin(response.data));
        } else {
          notify("User is not active", "error");
        }
        if (response.data?.user.id) {
          setOtp("");
          setOtpSent(false);
          handleSubmitRequest(response.data.user.id);
        }
        // navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };



  useEffect(() => {
    let timer;
    if (otpSent && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(timer);
  }, [otpSent, resendTimer]);


  const handleSubmitOnLogin = (e) => {
    e.preventDefault();
    setLoading(true)
    handleSubmitRequest(user.id);
    setLoading(false)
  };

  useEffect(() => {
    if (isAuthenticated && user && reviewForm?.current) {
      reviewForm.current.elements.full_name.value = user.full_name || "";
      reviewForm.current.elements.email.value = user.email || "";
      reviewForm.current.elements.mobile.value = user.mobile || "";
    }
  }, [isAuthenticated, user, reviewForm]);

  useEffect(()=>{
    console.log('OTPSent State:' + otpSent);
  },[otpSent])

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({ ...prev, [name]: value }))
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   handleSubmitRequest(form.current); // Pass the ref data when submitting
  // };

  return (
    <div style={{ minWidth: medium ? 400 : 300 }}>
      {isAuthenticated ? (
        // Logged-in user form for consultation
        <Form ref={form} onSubmit={handleSubmitOnLogin}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Full Name<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              className="py-2 body-text2"
              type="text"
              name="full_name"
              defaultValue={user?.full_name}
              readOnly
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Email<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              >
                <LuMail size={20} color="#1A1C1F" />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="email"
                name="email"
                defaultValue={user?.email}
                readOnly
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Phone Number<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="body-text2 inter-600"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="text"
                name="mobile"
                defaultValue={user?.mobile}
                readOnly
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Requirements<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              className="p-2 body-text2"
              as="textarea"
              rows={3}
              name="requirements_msg"
              required
              // onChange={handleInputChange}
              placeholder="Please enter any requests you have for us."
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                resize: "none",
              }}
            />
          </Form.Group>
          <Button
            type="submit"
            disabled={loading}
            className="text-white w-100 mb-2"
            style={{ borderRadius: 3 }}
          >
            {loading && (
              <Spinner animation="border" size="sm" className="me-2" />
            )}
            Request a Free Call Back
          </Button>
        </Form>
      ) : (
        <Form ref={form} onSubmit={otpSent ? verifyOtpLogin : handleSendOtp}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Full Name<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              className="py-2 body-text2"
              type="text"
              name="full_name"
              placeholder="Enter your Full Name"
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Email<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{
                  backgroundColor: "white",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              >
                <LuMail size={20} color="#1A1C1F" />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="email"
                name="email"
                required
                placeholder="example@gmail.com"
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Phone Number<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="body-text2 inter-600"
                style={{
                  backgroundColor: "white",
                  border: "1px solid #BDC1C6",
                  borderRadius: 3,
                }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="text"
                name="mobile"
                required
                placeholder="9090909090"
                maxLength={10}
                pattern="[0-9]*"
                style={{
                  border: "1px solid #BDC1C6",
                  borderRadius: "0px 3px 3px 0px",
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Requirements<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              className="p-2 body-text2"
              as="textarea"
              rows={3}
              name="requirements_msg"
              required
              placeholder="Please enter any requests you have for us."
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: 3,
                resize: "none",
              }}
            />
          </Form.Group>
          {/* {otpInput && (
            <Form.Group className="my-3">
              <Form.Label className="mb-1 body-text2 inter-500">
                OTP<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Row className="">
                <Col xs="6">
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="email_verified_otp"
                    placeholder="****"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Col>
                <Col xs="3">
                  <Button
                    variant="success"
                    className="w-100 text-white py-2"
                    style={{ borderRadius: 3 }}
                    type="submit"
                  >
                    Verify
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    className="text-white w-100 py-2 px-3"
                    style={{ borderRadius: 3 }}
                  >
                    Resend
                  </Button>
                </Col>
              </Row>
              <Form.Text className="text-success">
                OTP has been sent to the email address
              </Form.Text>
            </Form.Group>
          )} */}
          {otpSent && (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Enter OTP<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  className="p-2"
                  type="text"
                  name="email_verified_otp"
                  required
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>

              <div className="text-center mb-3">
                {isResendDisabled ? (
                  <span className="text-muted">
                    Resend OTP in {resendTimer} seconds
                  </span>
                ) : (
                  <span
                    className="text-primary cursor-pointer"
                    onClick={handleSendOtp}
                    style={{ textDecoration: "underline" }}
                  >
                    Resend OTP
                  </span>
                )}
              </div>
            </>
          )}
          <div className="text-center mb-2">
            <Button
              type="submit"
              disabled={loading}
              className="text-white w-100 mb-2"
              style={{ borderRadius: 3 }}
              // onClick={otpSent ? verifyOtpLogin : handleSendOtp}
            >
              {loading && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              {otpSent
                ? "Verify OTP and Submit"
                : "Request a Free Call Back"}
            </Button>
            {/* <div className="body-text3 text-gray-900 text-center">
              Already have an account?{" "}
              <span
                className="inter-500 body-text3 cursor-pointer"
                onClick={() => {
                  setShowLoginForm(prev=>!prev);
                }}
              >
                Login
              </span>
            </div> */}
          </div>
        </Form>
      )}
    </div>
  );
};

export default BookConsultationForm;
