import LoginModal from "components/Auth/LoginModal";
import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import propertyImg from "../../assets/products/post-placeholder.webp";

const SpaceMobileCard = ({
  id,
  slug,
  image,
  title,
  type,
  projectId,
  project,
  area,
  setShow,
  setRoomData,
  boxShadow,
  imgHeight = "300px",
  className,
  style,
  onClick,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [showShare, setShowShare] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);

  const checkRoomIfSaved = async () => {
    try {
      const response = await authActions.checkRoomIfSaved(user.id, id);
      console.log(response);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(slug);

  const handleSaveRoom = async () => {
    try {
      const response = await authActions.saveRoom(user.id, id);
      if (response) {
        setIsLiked(true);
        notify("Space saved successfully", "success");
      } else {
        notify("Failed to save space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveRoom = async () => {
    try {
      const response = await authActions.unsaveRoom(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Space unsaved successfully", "success");
      } else {
        notify("Failed to unsave space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkRoomIfSaved();
  }, []);

  return (
    <>
      <div
        className="overflow-hidden shadow-sm"
        // style={{
        //     borderRadius: "6px",
        //     border: "1px solid #f8f9fa",
        // }}
        onClick={() => navigate(`/projects/${slug}`)}
      >
        <div className="position-relative">
          <img
            src={image || propertyImg}
            alt={title}
            className="img-fluid"
            style={{ objectFit: "cover", width: "100%", height: "200px" }}
          />
          <div
            className="d-flex position-absolute"
            style={{ zIndex: 999, right: 15, top: 10 }}
          >
            <Button
              className="d-flex justify-content-center align-items-center share-like-btn p-0"
              onClick={(e) => {
                e.stopPropagation();
                setShowShare(true);
              }}
            >
              <FiSend size={20} color="#3C4043" />
            </Button>
            <Button
              className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${
                isLiked ? "bg-primary text-white" : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();

                if (!user) {
                  setShowLoginModal(true);
                } else {
                  isLiked ? handleUnsaveRoom() : handleSaveRoom();
                }
              }}
            >
              <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
            </Button>
          </div>
        </div>
        <div className="m-2" onClick={() => navigate(`/projects/${slug}`)}>
          <div className="h3 inter-600 text-gray-800 mb-0">{title}</div>
          <div className="body-text1 inter-400 text-gray-800">Type: {type}</div>
          <div className="body-text1 inter-400 text-gray-800text-muted small">
            Area: {area}
          </div>
        </div>
      </div>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${type} / ${project?.project_name}`}
        // urlPath={`/spaces/${slug}`} // #defaultUrl
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default SpaceMobileCard;
