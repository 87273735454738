import { AccountEditForm, ChangePasswordForm } from "components";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import SavedProperties from "./SavedProperties";
import ArchitectThoughtForm from "components/Forms/ArchitectThoughtForm";
import { useEffect, useState } from "react";
import {
  fetchBrandsThought,
  fetchThoughts,
} from "reduxFiles/actions/authActions";
import { useLocation } from "react-router-dom";
import BrandThoughtForm from "components/Forms/BrandThoughtForm";
import SavedProducts from "./SavedProducts";

const ProfessionalSettings = () => {
  const user = useSelector((state) => state.auth.user);
  const [thoughts, setThoughts] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const response = pathname.includes("brand")
        ? await fetchBrandsThought(user.id)
        : await fetchThoughts(user.id);
      setThoughts(response.data.results[0]);
    };

    fetchData();
  }, [user.id]);

  return (
    <Container fluid className="mt-5 pt-4 px-0" style={{ minHeight: "100vh" }}>
      <Container>
        <h3 className="inter-600 text-gray-900 py-5 mb-0">Settings</h3>
        <AccountEditForm />
        {/* <ChangePasswordForm /> */}
        {pathname.includes("brand") ? (
          <BrandThoughtForm thoughts={thoughts} user={user} />
        ) : (
          <ArchitectThoughtForm thoughts={thoughts} user={user} />
        )}
        <SavedProperties />
        <SavedProducts />
      </Container>
    </Container>
  );
};

export default ProfessionalSettings;
