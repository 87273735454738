import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  FaFile,
  FaFileImage,
  FaFilePdf,
  FaTrash,
  FaXmark,
} from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PreviewPropertyCard from "components/Cards/PreviewPropertyCard";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useMediaQuery } from "react-responsive";
import DropFileInputProperty from "components/DragInput/DropFileInputProperty";
import UploadingFiles from "components/DragInput/UploadingFiles";
import { formatBytes } from "utilities/formatBytes";
import { getExtension } from "utilities/getExtension";
import { FiTrash2 } from "react-icons/fi";
import axios from "axios";

function AddProperty() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const user = useSelector((state) => state.auth.user);

  const [currentStep, setCurrentStep] = useState(1);
  const propertyInfoRef = useRef();
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [projectTypes, setProjectTypes] = useState([]);
  const [layouts, setLayouts] = useState([
    { id: 1, layout: "", area: "" },
    // layout_file: null
  ]);
  const [pincodeDetails, setPincodeDetails] = useState({});
  // const [selectedFiles, setSelectedFiles] = useState([]);

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [parentId, setParentId] = useState(null);

  const [selectedPropertyImage, setSelectedPropertyImage] = useState(null);
  const [completeProjectFiles, setCompleteProjectFiles] = useState([]);

  const constructionStatuses = [
    { id: 1, label: "Pre-Construction Phase", value: "PRE_CONSTRUCTION" },
    { id: 2, label: "Under Construction", value: "UNDER_CONSTRUCTION" },
    { id: 3, label: "Ready for Occupancy", value: "READY_FOR_OCCUPANCY" },
    { id: 4, label: "Completed", value: "COMPLETED" },
  ];

  const propertySegments = [
    {
      id: 1,
      label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 2,
      label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)",
      value: "BASIC_COMMERCIAL",
    },
    {
      id: 3,
      label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 4,
      label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)",
      value: "MID_RANGE_COMMERCIAL",
    },
    {
      id: 5,
      label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 6,
      label: "High-End Commercial (₹4,000 per sq. ft. and above)",
      value: "HIGH_END_COMMERCIAL",
    },
    {
      id: 7,
      label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
  ];

  const defaultLayouts = [
    { id: 1, layout: "2BHK" },
    { id: 2, layout: "2.5BHK" },
    { id: 3, layout: "3BHK" },
    { id: 4, layout: "3.5BHK" },
    { id: 5, layout: "4BHK" },
    { id: 6, layout: "5BHK" },
  ];
  const [availableLayouts, setAvailableLayouts] = useState(defaultLayouts);

  const handlePropertyImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedPropertyImage(file);
    }
  };

  const addLayout = () => {
    setLayouts([
      ...layouts,
      {
        id: layouts.length + 1,
        layout: "",
        area: "",
        // layout_file: null
      },
    ]);
  };

  const deleteLayout = (index) => {
    setLayouts([...layouts.slice(0, index), ...layouts.slice(index + 1)]);
  };

  const handleInputChange = (layoutId, field, value) => {
    setLayouts(
      layouts.map((layout) =>
        layout.id === layoutId ? { ...layout, [field]: value } : layout
      )
    );
  };

  const fileRemove = (file) => {
    const updatedList = completeProjectFiles.filter((f) => f !== file);
    setCompleteProjectFiles(updatedList);
  };

  const joinLayoutString = () => {
    let formattedProperties = [];
    layouts.forEach((layout) =>
      formattedProperties.push(`${layout.layout} (${layout.area})`)
    );
    console.log(formattedProperties);
    return formattedProperties.join(", ");
  };

  const getPincode = async (pincode) => {
    try {
      if (pincode.length < 6) return;
      const response = await authActions.fetchPincode(pincode);

      setPincodeDetails(response);
      setPropertyDetails((prevDetails) => ({
        ...prevDetails, // Preserve existing propertyDetails
        pincode: response?.Pincode || "",
        country: response?.Country || "",
        city: response?.District || "",
        state: response?.State || "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertySubmit = async () => {
    // const propertyData = { property: propertyDetails, resources: blocks };
    const propertyData = {
      property: propertyDetails,
      layouts: layouts,
    };
    const formData = new FormData();
    // blocks.forEach((block) => {
    //   block.layouts.forEach((layout) => {
    //     formData.append(
    //       `fileInput-block-${block.name}-layout-${layout.id}`
    //       // layout.layout_file
    //     );
    //   });
    // });
    formData.append("user", user.id);
    formData.append("data", JSON.stringify(propertyData));
    console.log(selectedPropertyImage);
    formData.append("property_img", selectedPropertyImage);
    try {
      const response = await authActions.createProperty(formData);
      if (response.data) {
        notify("Property Created!", "success");
        setIsFileUploading(true);
        setParentId(response.data.id);
        // navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertyDetails = () => {
    const formData = new FormData(propertyInfoRef.current);
    let isError = false;
    let data = {};

    formData.forEach((value, key) => {
      // Check if the required field is empty
      if (!value && key !== "property_img") {
        isError = true;
        notify(`${key} is required!`);
        return;
      }

      // Check if property_year is valid (greater than or equal to 2025)
      if (key === "property_year" && value < 2025) {
        isError = true;
        notify("Possession Year must be 2025 or later!");
        return;
      }

      // If no errors, add the field value to data
      data[key] = value;
    });

    // Validate property_img (file required)
    if (!selectedPropertyImage) {
      isError = true;
      notify("Property Image is required!");
    }

    if (isError) {
      return; // Stop further processing if there's an error
    }
    setPropertyDetails(data);
    setCurrentStep(2); // Proceed to the next step
  };

  // useEffect(() => {
  //   fetchProjectTypes();
  // }, []);

  return (
    <Container
      fluid
      className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "60vh" }}
    >
      <div className="w-100 bg-info d-flex justify-content-center align-items-center">
        <h1 className="h2 text-primary inter-600 py-3">Add a new Property</h1>
      </div>
      <div
        className="d-flex flex-column flex-lg-row my-md-4"
        style={large ? { maxWidth: 1000 } : {}}
      >
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${
                  currentStep === 1
                    ? "bg-black text-white"
                    : currentStep === 2 || currentStep === 3
                    ? "bg-success text-white"
                    : "bg-gray-25"
                }`}
              >
                {currentStep === 1 ? "1" : <FaCheck size={12} />}
              </div>
              <div
                className={`ms-2 inter-500 body-text1 ${
                  currentStep !== 1 ? "text-success" : ""
                }`}
              >
                Property Details
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${
                  currentStep === 2
                    ? "bg-black text-white"
                    : currentStep === 3
                    ? "bg-success text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                }`}
              >
                {currentStep === 1 || currentStep === 2 ? (
                  "2"
                ) : (
                  <FaCheck size={12} />
                )}
              </div>
              <div
                className={`ms-2 inter-500 body-text1 ${
                  currentStep !== 1 && currentStep !== 2
                    ? "text-success"
                    : currentStep === 2
                    ? ""
                    : "text-gray-300"
                }`}
              >
                Property Files
              </div>
            </div>
            <div className="d-flex align-items-center mb-4 me-2">
              <div
                className={`p-1 text-center rounded-btn ${
                  currentStep === 3
                    ? "bg-black text-white"
                    : "text-gray-300 bg-gray-25 border border-gray-100"
                }`}
              >
                3
              </div>
              <div
                className={`ms-2 inter-500 body-text1 ${
                  currentStep === 3 ? "text-gray-900" : "text-gray-300"
                }`}
              >
                Property Preview
              </div>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div className="p-3 border" style={{ borderRadius: 6 }}>
            <UploadingFiles
              show={isFileUploading}
              files={completeProjectFiles}
              setShow={setIsFileUploading}
              parentId={parentId}
              app_label={"property"}
              model_name={"property"}
            />
            {currentStep === 1 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Property Details</h4>
                <div className="mt-4">
                  <Form ref={propertyInfoRef}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Property Name<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        name="property_name"
                        placeholder="Enter property name"
                        defaultValue={propertyDetails?.property_name}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Number of Flats<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        placeholder="No. of flats"
                        name="flats"
                        defaultValue={propertyDetails?.flats}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex">
                      <div className="w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Construction Status
                          <sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Select
                          required
                          className="py-2 body-text2"
                          type="text"
                          placeholder="Select Status"
                          name="construction_status"
                          defaultValue={propertyDetails?.construction_status}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        >
                          <option value="">Select</option>
                          {constructionStatuses.map((conStatus) => {
                            return (
                              <option value={conStatus.value}>
                                {conStatus.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </div>
                      <div className="ms-2 w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Possession Year<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="number"
                          min={2025}
                          placeholder="Possession Year"
                          name="property_year"
                          defaultValue={propertyDetails?.property_year}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex">
                      <div className="w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Pincode<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          onChange={(e) => getPincode(e.target.value)}
                          placeholder="Enter pincode"
                          name="pincode"
                          defaultValue={propertyDetails?.pincode}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </div>

                      <div className="ms-2 w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Country<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          disabled={!propertyDetails?.pincode}
                          className="py-2 body-text2"
                          type="text"
                          placeholder="Enter country"
                          name="country"
                          defaultValue={propertyDetails?.country}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex">
                      <div className="w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          City<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          disabled={!propertyDetails?.pincode}
                          type="text"
                          placeholder="Enter city"
                          name="city"
                          defaultValue={propertyDetails?.city}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </div>

                      <div className="ms-2 w-100">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          State<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          disabled={!propertyDetails?.pincode}
                          type="text"
                          placeholder="Enter state"
                          name="state"
                          defaultValue={propertyDetails?.state}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Location<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        placeholder="Enter location"
                        name="location"
                        defaultValue={propertyDetails?.location}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      />
                    </Form.Group> */}
                    {/* <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Project Type<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Select
                        required
                        className="py-2 body-text2"
                        type="text"
                        name="project_type"
                        defaultValue={propertyDetails?.project_type}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      >
                        <option value="">Select</option>
                        {projectTypes?.map((type, index) => (
                          <option key={index} value={type.id}>
                            {type.title}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group> */}
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Property Segment<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Select
                        required
                        className="py-2 body-text2"
                        type="text"
                        name="property_segment"
                        defaultValue={propertyDetails?.property_segment}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      >
                        <option value="">Select</option>
                        {propertySegments?.map((segment, index) => (
                          <option key={index} value={segment.value}>
                            {segment.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Property Image<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      {!selectedPropertyImage ? (
                        <Form.Control
                          required
                          type="file"
                          className="py-2 body-text2"
                          name="property_img"
                          defaultValue={selectedPropertyImage}
                          onChange={handlePropertyImageChange}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                          accept="image/*"
                          // defaultValue={propertyDetails?.property_image}
                        />
                      ) : (
                        <div className="drop-file-preview__item d-flex justify-content-between align-items-start w-100">
                          <div
                            className="rounded overflow-hidden border me-2"
                            // style={{ height: 76, maxWidth: 100 }}
                          >
                            <img
                              src={URL.createObjectURL(selectedPropertyImage)}
                              alt=""
                              className="img-fluid"
                              style={{
                                objectFit: "cover",
                                height: 76,
                                // width: 100,
                              }}
                            />
                          </div>
                          <div className="w-100">
                            {selectedPropertyImage?.name}
                          </div>
                          <div
                            onClick={() => setSelectedPropertyImage(null)}
                            className="drop-file-preview__item__del cursor-pointer bg-gray text-center rounded-circle ms-2"
                          >
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{ width: 40, height: 40 }}
                            >
                              <div className="w-100 mb-1">
                                <FaXmark size={18} className="text-dark" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Form.Group>
                  </Form>
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handlePropertyDetails}
                    >
                      Continue
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Add Property Files</h4>
                <div className="my-4">
                  {layouts.map((layout, index) => (
                    <div key={index}>
                      <Row className="w-100 mt-2 me-2">
                        <Col xs={5} md={5} className="ps-3">
                          {/* <Form.Group className="mb-3 mb-md-0">
                            <Form.Label className="mb-1 body-text2 inter-500">
                              Layout Type<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Control
                              required
                              className="py-2 body-text2"
                              type="text"
                              name="layout"
                              placeholder="2BHK"
                              defaultValue={layout.layout}
                              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                              onChange={(e) =>
                                handleInputChange(
                                  layout.id,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                            />
                          </Form.Group> */}
                          <Form.Group className="mb-3 mb-md-0">
                            <Form.Label className="mb-1 body-text2 inter-500">
                              Layout Type<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <Form.Select
                              required
                              className="py-2 body-text2"
                              name="layout"
                              value={layout.layout} // Current selected value
                              style={{
                                border: "1px solid #BDC1C6",
                                borderRadius: 3,
                              }}
                              onChange={(e) =>
                                handleInputChange(
                                  layout.id,
                                  e.target.name,
                                  e.target.value
                                )
                              }
                            >
                              <option value="" disabled>
                                Select Layout
                              </option>{" "}
                              {/* Default placeholder */}
                              {availableLayouts.map((layoutOption) => (
                                <option
                                  key={layoutOption.id}
                                  value={layoutOption.layout}
                                >
                                  {layoutOption.layout}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col xs={6} md={6} className="px-1">
                          {/* <Form.Group className="mb-3 mb-sm-0">
                            <Form.Label className="mb-1 body-text2 inter-500">
                              Layout Area<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup
                              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                            >
                              <Form.Control
                                required
                                className="py-2 body-text2 border-0"
                                type="text"
                                name="area"
                                placeholder="200"
                                defaultValue={layout.area}
                                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                                onChange={(e) =>
                                  handleInputChange(
                                    // block.id,
                                    layout.id,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                              <InputGroup.Text className="body-text3 border-0 px-2 bg-gray-50" style={{ borderRadius: 3, margin: 3 }}>
                                Sq. Ft.
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group> */}
                          <Form.Group className="mb-3 mb-sm-0">
                            <Form.Label className="mb-1 body-text2 inter-500">
                              Layout Area<sup style={{ color: "red" }}>*</sup>
                            </Form.Label>
                            <InputGroup
                              style={{
                                border: "1px solid #BDC1C6",
                                borderRadius: 3,
                              }}
                            >
                              {/* Area Input - Numbers Only */}
                              <Form.Control
                                required
                                className="py-2 body-text2 border-0"
                                type="number" // Restrict to numbers
                                name="area"
                                placeholder="200"
                                value={layout.area} // Controlled value
                                onChange={(e) =>
                                  handleInputChange(
                                    layout.id,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: "3px 0 0 3px",
                                  width: "60%",
                                }}
                              />
                              {/* Unit Dropdown */}
                              <Form.Select
                                className="body-text2 border-0"
                                name="unit"
                                value={layout.unit || "Sq.ft."} // Default value
                                onChange={(e) =>
                                  handleInputChange(
                                    layout.id,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: "0 3px 3px 0",
                                }}
                              >
                                <option value="Sq.ft.">Sq.ft.</option>
                                <option value="Sq.m.">Sq.m.</option>
                              </Form.Select>
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col
                          xs={1}
                          md={1}
                          className="d-flex jsutify-content-end"
                        >
                          {index > 0 && (
                            <div className="d-flex align-items-end">
                              <Button
                                className="d-flex align-items-center rounded-btn bg-gray py-2"
                                onClick={() => deleteLayout(index)}
                              >
                                <FaTrash size={18} />
                              </Button>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <div
                    className="mt-2 ms-2 body-text3 inter-500 cursor-pointer"
                    onClick={() => addLayout()}
                  >
                    <span style={{ borderBottom: "1px solid #000000" }}>
                      Add another layout
                    </span>
                  </div>
                </div>
                <div>
                  <div className="inter-600">
                    Property Files<sup style={{ color: "red" }}>*</sup>
                  </div>
                  {/* <div className="text-gray-600 body-text3 mb-2">Add all layout files relevant to designers here. Try to label them according to standards.</div> */}
                  <div className="text-gray-600 body-text3 mb-2">
                    Tip: Please try to upload winzip (.zip) file containing
                    relevant data for a layout.
                  </div>
                  <DropFileInputProperty
                    type="property"
                    files={completeProjectFiles}
                    onFileChange={(files) => setCompleteProjectFiles(files)}
                    allowMultiple
                    className="bg-white border-gray-300"
                  />
                  {completeProjectFiles.length > 0 && (
                    <div className="drop-file-preview mt-3">
                      {completeProjectFiles.map((file, index) => (
                        <Card
                          key={index}
                          className="d-flex flex-row justify-content-between align-items-center border-gray-100 shadow-none mb-1"
                          style={{ borderRadius: 3 }}
                        >
                          <div className="d-flex align-items-center">
                            {getExtension(file?.name) === "pdf" ? (
                              <FaFilePdf
                                className="text-primary-500 me-2"
                                size={24}
                              />
                            ) : getExtension(file?.name) === "png" ||
                              getExtension(file?.name) === "jpg" ||
                              getExtension(file?.name) === "jpeg" ||
                              getExtension(file?.name) === "webp" ||
                              getExtension(file?.name) === "heic" ? (
                              <FaFileImage
                                className="me-2"
                                size={24}
                                color="#83c8e4"
                              />
                            ) : (
                              <FaFile
                                className="text-primary-500 me-2"
                                size={24}
                              />
                            )}
                            <div className="d-flex flex-column">
                              <span className="body-text2 text-gray-900">
                                {file.name}
                              </span>
                              <span
                                className="text-gray-600"
                                style={{ fontSize: 10 }}
                              >
                                {formatBytes(file?.size)}
                              </span>
                            </div>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => fileRemove(file)}
                          >
                            <FiTrash2 size={20} color="#3C4043" />
                          </div>
                        </Card>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-4 d-flex">
                  <Button
                    className="d-flex align-items-center text-white body-text2 me-2"
                    style={{ borderRadius: 3 }}
                    onClick={() => {
                      if (layouts[0].layout && layouts[0].area) {
                        setCurrentStep(3);
                      } else {
                        notify("Atleast one layout is required", "error");
                      }
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    className="bg-transparent border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setCurrentStep(1)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div style={large ? { minWidth: 600 } : {}}>
                <h4 className="fw-bold">{currentStep}. Property Preview</h4>
                <PreviewPropertyCard
                  property_name={propertyDetails?.property_name}
                  builder={user?.full_name}
                  // project_type={
                  //   projectTypes.find(
                  //     (type) => type.id === propertyDetails?.project_type
                  //   )?.title
                  // }
                  flats={propertyDetails?.flats}
                  // layout_types={joinLayoutString()}
                  layout_types={layouts}
                  city={propertyDetails?.city}
                  pincode={propertyDetails?.pincode}
                  state={propertyDetails?.state}
                  country={propertyDetails?.country}
                  property_year={propertyDetails?.property_year}
                  construction_status={propertyDetails?.construction_status}
                  project_type={propertyDetails?.project_type}
                  property_segment={propertyDetails?.property_segment}
                  property_img={
                    selectedPropertyImage
                      ? URL.createObjectURL(selectedPropertyImage)
                      : ""
                  }
                  setCurrentStep={setCurrentStep}
                />
                <div className="mt-4 d-flex">
                  <Button
                    className="d-flex align-items-center text-white body-text2 me-2"
                    style={{ borderRadius: 3 }}
                    onClick={handlePropertySubmit}
                  >
                    Submit Property
                  </Button>
                  <Button
                    className="bg-transparent border-gray-200 text-gray-900 body-text2"
                    style={{ borderRadius: 3 }}
                    onClick={() => setCurrentStep(2)}
                  >
                    Back
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AddProperty;
