import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { uploadThought } from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const BuilderThoughtForm = ({ thoughts }) => {
  const user = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState({
    thoughts: thoughts?.builder_thoughts || "",
    image: null,
  });

  const [imagePreview, setImagePreview] = useState(
    thoughts?.builder_image || null
  );
  const [loading, setLoading] = useState(false);

  // Sync only once or when `thoughts` change significantly
  useEffect(() => {
    if (thoughts) {
      setFormData((prev) => ({
        ...prev,
        thoughts: thoughts.builder_thoughts || "",
        image: thoughts.builder_image || null,
      }));
      setImagePreview(thoughts.builder_image || null);
    }
  }, [thoughts?.builder_thoughts, thoughts?.builder_image]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image") {
      setFormData((prev) => ({ ...prev, image: files[0] }));
      setImagePreview(URL.createObjectURL(files[0]));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const uploadThoughts = async () => {
    if (!formData.thoughts || !formData.image) {
      notify("Please fill out both the thoughts and image fields.", 'error');
      // setLoading(false);
      return;
    }

    try {
      const formPayload = new FormData();
      formPayload.append("thoughts", formData.thoughts);
      formPayload.append("image", formData.image);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      await uploadThought(user.id, formPayload, "builders");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while uploading. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Form submitted");
    setLoading(true);

    try {
      await uploadThoughts();
    } catch (error) {
      console.error("Error in submission:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      className="border border-gray-100 mb-4"
      onSubmit={handleSubmit}
      style={{ borderRadius: 6 }}
    >
      <div className="p-3">
        <div className={`h4 inter-600 mt-2`}>Builder Thought Form</div>
      </div>
      <Row className="px-3 pb-3">
        <Col lg={5}>
          {/* Thoughts Field */}
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Thoughts<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              as="textarea"
              rows={4}
              className="body-text2 py-2 border border-gray-300 text-gray-900"
              name="thoughts"
              value={formData.thoughts}
              onChange={handleChange}
              style={{ border: "1px solid #BDC1C6", borderRadius: 3, resize: 'none' }}
            />
          </Form.Group>

          {/* Image Field */}
          <Form.Group className="mb-3 user-select-none">
            <Form.Label className="mb-1 body-text2 inter-500">
              Image<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required={formData.image === null ? true : false}
              type="file"
              accept="image/*"
              className="body-text2 py-2 border border-gray-300"
              name="image"
              onChange={handleChange}
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>

          {/* Submit Button */}
          <Form.Group className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary body-text2 inter-500"
              style={{
                backgroundColor: "#a53030",
                borderRadius: 3,
              }}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" /> {/* Spinner */}
                  
                </>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </Form.Group>
        </Col>

        <Col
          lg={5}
          className="d-flex align-items-center justify-content-center position-relative"
        >
          {imagePreview ? (
            <div
              style={{
                position: "relative",
                maxHeight: "300px",
              }}
            >
              {/* Image Preview */}
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  border: "1px solid #BDC1C6",
                  borderRadius: "3px",
                  display: "block",
                }}
              />

              {/* Cancel Button */}
              <button
                type="button"
                onClick={() => {
                  setFormData((prev) => ({ ...prev, image: null }));
                  setImagePreview(null);
                }}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  border: "none",
                  borderRadius: "50%",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
            </div>
          ) : (
            <div
              className="text-center body-text2 text-gray-600"
              style={{
                border: "1px solid #BDC1C6",
                borderRadius: "3px",
                padding: "10px",
                maxWidth: "100%",
                maxHeight: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No image selected
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default BuilderThoughtForm;
