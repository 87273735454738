import { brand_placeholder } from "assets";
import LoginModal from "components/Auth/LoginModal";
import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import { useSelector } from "react-redux";
// import { FaStar } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const BrandCard = ({
  id,
  slug,
  companyName,
  image,
  brandCategories,
  warranty,
  rating,
  averagePricing,
  minPricing,
  maxPricing
}) => {
  const { user, navbarToggle } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [showShare, setShowShare] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const checkBrandIfSaved = async () => {
    try {
      const response = await authActions.checkIsSavedBrand(user.id, id);

      setIsLiked(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveBrand = async () => {
    if (isProcessing) return;

    setIsProcessing(true);
    try {
      const response = await authActions.saveBrand(user.id, id);
      if (response.status) {
        setIsLiked(true);
        notify("Brand saved!", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnsaveBrand = async () => {
    if (isProcessing) return;

    setIsProcessing(true);
    try {
      const response = await authActions.unsaveBrand(user.id, id);
      console.log(response);
      if (response) {
        setIsLiked(false);
        notify("Brand unsaved!", "success");
      }
      await checkBrandIfSaved();
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    checkBrandIfSaved();
  }, []);

  return (
    <>
      <Card
        key={id}
        className="card-primary-hover d-flex p-4 flex-row text-start my-4 border w-100 position-relative"
        style={{ boxShadow: "none" }}
        onClick={() => navigate(`/brands/${slug}`)}
      // onMouseEnter={() => {}}
      // onMouseLeave={() => {}}
      >
        <Card.Img
          className="bg-white w-50 border p-md-5"
          variant="top"
          src={image ? image : brand_placeholder}
          // width={large ? 300 : 240}
          height={large ? 200 : 150}
          alt="Brand Img"
          style={{ objectFit: "contain", maxWidth: 300 }}
        />
        <div
          className="d-flex position-absolute"
          style={{
            zIndex: 999,
            right: 15,
            top: 10,
            filter: navbarToggle ? "brightness(60%)" : "none",
          }}
        >
          <Button
            className="d-flex justify-content-center align-items-center share-like-btn p-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <FiSend size={20} />
          </Button>

          <Button
            className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""
              }`}
            disabled={isProcessing}
            onClick={(e) => {
              e.stopPropagation();

              if (!user) {
                setShowLoginModal(true);
              } else {
                isLiked ? handleUnsaveBrand() : handleSaveBrand();
              }
            }}
          >
            {isProcessing ? <Spinner size="sm" animation="border" /> :
              <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
            }
          </Button>
        </div>
        <Card.Body className="w-100">
          <Card.Title className="h4 inter-700 text-gray-900">
            {companyName}
          </Card.Title>

          <div className="body-text2 inter-600 text-gray-900">
            {brandCategories?.map((category, index) => {
              return (
                <span>
                  {category?.name}
                  {index === brandCategories?.length - 1 ? "" : ", "}
                </span>
              );
            })}
          </div>
          <div className="body-text2 inter-600 text-gray-900">
            <span>Warranty: </span>
            {warranty ?? "-"}
          </div>
          <div className="body-text2 inter-600 d-flex align-items-center">
            Ratings: {rating}
            {/* {rating ? (<span> {rating} <FaStar color="#D95656" /></span>) : ("Unavailable")} */}
          </div>
          <div className="body-text2 inter-600 text-gray-900">
            {(minPricing && maxPricing) && (
              <>
                <span>Average Pricing: </span> ₹{Math.round(minPricing)} - ₹{Math.round(maxPricing)}
              </>
            )}
          </div>

        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${companyName}`}
        urlPath={`/brands/${slug}`}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </>
  );
};

export default BrandCard;
