import { useEffect, useState } from "react";
import SpaceCard from "components/Cards/SpaceCard";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import SpaceCategoryCard from "components/Cards/SpaceCategoryCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SpacesSliderView from "./SpacesSliderView";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";
import Pagination from "components/Pagination";
import { space_switch_card } from "assets";
import { FiRefreshCcw } from "react-icons/fi";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import SpaceMobileCard from "components/Cards/SpaceMobileCard";
import { InfiniteScroll, useScrollBottom } from "components";
import { FaFilter } from "react-icons/fa6";

const SpacesList = () => {
  const navigate = useNavigate();
  // const { slug } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categorySlug = queryParams.get("room-caterogry");
  const roomTypeSlug = queryParams.get("room-type");

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  let params = new URLSearchParams(document.location.search);
  let roomType = params.get("room_type");
  let property = params.get("property");
  let bhk = params.get("bhk");
  let city = params.get("location");
  let sortBy = params.get("sort_by");

  const [switchPage, setSwitchPage] = useState(false);
  const [roomData, setRoomData] = useState(null);
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [noNextItem, setNoNextItem] = useState(false);
  const [next, setNext] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const bottom = useScrollBottom();
  const [allLocations, setAllLocations] = useState([]);

  const layouts = [
    { id: 1, label: "2 BHK", value: "2BHK" },
    { id: 2, label: "2.5 BHK", value: "2.5BHK" },
    { id: 3, label: "3 BHK", value: "3BHK" },
    { id: 4, label: "3.5 BHK", value: "3.5BHK" },
    { id: 5, label: "4 BHK", value: "4BHK" },
  ];

  const fetchAllLocations = async () => {
    try {
      const response = await authActions.fetchAllCities();
      if (response.data) {
        setAllLocations(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response.data) {
        setProperties(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filterSpaces = async (roomType, property, bhk, sortBy, city) => {
    // setLoading(true);
    const response = await authActions.fetchFilterSpaces(
      1,
      roomType,
      property,
      bhk,
      sortBy,
      city
    );
    try {
      if (response.data) {
        console.log(response.data, "filtered Spaces");
        setRooms(response.data?.results);
        setNext(response?.data?.next);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSpaces = async () => {
    setLoading(true);
    const response = await authActions.fetchRooms("/project/rooms/?page=1");
    try {
      if (response.data) {
        console.log(response.data, "fetch Spaces");
        setRooms(response.data?.results);
        setNext(response?.data?.next);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  /*   const loadMoreRooms = async () => {
    const spacesList = rooms;
    if (next) {
      console.log(next);
      setNoNextItem(false);
      const newRooms = await authActions.loadRooms(next);

      if (newRooms) {
        const newResults = spacesList.concat(newRooms.results);
        newRooms.results = newResults;
        setRooms(newRooms);
      } else {
        setNoNextItem(true);
      }
    }
  }; */

  const loadMoreRooms = async () => {
    if (!next || loading) return; // Prevent multiple API calls

    setLoading(true); // Set loading state

    try {
      const response = await authActions.loadRooms(
        next,
        roomType,
        property,
        bhk,
        sortBy
      );

      if (response.data) {
        console.log(response, "load more");
        setRooms((prevRooms) => [...prevRooms, ...response.data.results]); // Append new rooms

        setNext(response?.data?.next); // Update the next URL
      } else {
        setNoNextItem(true); // Stop loading more items
      }
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    const onScroll = () => {
      if (loading || !next) return;
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadMoreRooms(next, roomType, property, bhk, sortBy);
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [loading, next, roomType, property, bhk, sortBy]);

  const onChangeFilters = (value, type) => {
    // Initialize an object to hold the filters
    const filterParams = {
      room_type: type === "room_type" ? value : roomType,
      property: type === "property" ? value : property,
      bhk: type === "bhk" ? value : bhk,
      sort_by: type === "sort_by" ? value : sortBy,
      location: type === "location" ? value : city,
    };

    // Remove filters with null or empty values
    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val) // Only include filters with non-null, non-empty values
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    // Navigate to the updated URL
    navigate(`/spaces?${queryString}`);
  };

  const fetchRoomsByCateogrySlug = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRoomsByCategorySlug(
      categorySlug,
      page
    );
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomsByRoomTypeSlug = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRoomsByRoomTypeSlug(roomType, page);
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomTypes = async (page) => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      if (response.data) {
        setRoomTypes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (categorySlug) {
      fetchRoomsByCateogrySlug(currentPage);
    } else if (roomType) {
      fetchRoomsByRoomTypeSlug(currentPage);
    } else {
      fetchSpaces();
    }
  }, [categorySlug, roomType]);

  useEffect(() => {
    // Use filterSpaces directly instead of fetching all spaces again.
    filterSpaces(roomType, property, bhk, sortBy, city);
  }, [roomType, property, bhk, sortBy, city]);

  useEffect(() => {
    fetchProperties();
    fetchRoomTypes();
    fetchAllLocations();
  }, []);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow-spaces`}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowLeft size={25} color="black" /> */}
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow-spaces`}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowRight size={25} color="black" /> */}
      </div>
    );
  }

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };
  // console.log(rooms, "rooms");

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: xxlarge
      ? 8.5
      : xlarge
      ? 7
      : large
      ? 6
      : medium
      ? 4
      : small
      ? 2.5
      : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleSpaceCardClick = (room) => {
    setRoomData({
      id: room.id,
      projectId: room.project,
      roomImage: room?.media[0]?.file,
    });
  };

  useEffect(() => {
    // Open the SpacesSliderView only after roomData is updated
    if (roomData) {
      setShowSpaceView(true);
    }
  }, [roomData]);

  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        {small && (
          <>
            <div className="d-flex">
              <div className="pe-3">
                <Card
                  className={`border-0 p-0 text-start bg-brand-500 text-white shadow-none cursor-pointer`}
                  style={{ width: 128, borderRadius: 5 }}
                  onMouseEnter={() => setSwitchPage(true)}
                  onMouseLeave={() => setSwitchPage(false)}
                  onClick={() => navigate("/projects")}
                >
                  {switchPage && (
                    <div
                      className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        borderRadius: 6,
                      }}
                    />
                  )}
                  <Card.Img
                    className="img-fluid"
                    vadivriant="top"
                    src={space_switch_card}
                    style={{
                      width: 128,
                      maxHeight: "90px",
                      minHeight: "100px",
                      borderRadius: "",
                    }}
                  />
                  <Card.Body className="px-0 py-2 text-center ">
                    <Card.Title className="body-text2 m-0 d-flex justify-content-center align-items-center">
                      <span className="me-2">
                        {switchPage ? "Projects" : "Spaces"}
                      </span>
                      <FiRefreshCcw size={18} />
                    </Card.Title>
                  </Card.Body>
                </Card>
              </div>
              {roomTypes?.length > 0 && (
                <div
                  className={`${
                    large ? "w-90" : medium ? "w-80" : small ? "w-80" : "w-50"
                  } ps-3`}
                >
                  <Slider {...settings}>
                    {roomTypes?.map((room, index) => (
                      <SpaceCategoryCard
                        key={index}
                        {...room}
                        selected={roomType}
                        onClick={() => {
                          // navigate(`/spaces?room-type=${room.slug}`)
                          onChangeFilters(room.slug, "room_type");
                        }}
                      />
                    ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="body-title my-4">
              {city && city !== "null" && (
                <div>
                  Search results for <span className="inter-600">{city}</span>
                </div>
              )}
              <span className="inter-600">{rooms?.length} spaces</span> found in
              your city
            </div>
          </>
        )}

        {/* <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="row g-2 mb-3">
            <div className="col-12 col-md-auto">
              {property && property !== "null" ? (
                <div
                  className="d-flex justify-content-center align-items-center filter-container  flex-grow bg-transparent text-gray-700 px-2 me-2"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <span className="me-2">
                    {properties.filter((prop) => prop.slug === property)
                      .length > 0
                      ? properties.filter((prop) => prop.slug === property)[0]
                          .property_name
                      : property}
                  </span>
                  <div
                    className="cursor-pointer"
                    onClick={() => onChangeFilters(null, "property")}
                  >
                    <IoClose size={20} />
                  </div>
                </div>
              ) : (
                <Form.Select
                  className="bg-transparent text-gray-700 me-2 cursor-pointer"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "property")}
                  value={property && property !== "null" ? property : ""}
                >
                  <option value="" disabled>
                    Property Name
                  </option>
                  {properties.map((property) => {
                    return (
                      <option value={property.slug}>
                        {property.property_name}
                      </option>
                    );
                  })}
                </Form.Select>
              )}
            </div>

            <div className="col-12 col-md-auto">
              {bhk && bhk !== "null" ? (
                <div
                  className="d-flex justify-content-center filter-container  align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <span className="me-2">
                    {layouts.filter((lay) => lay.value === bhk)[0].label}
                  </span>
                  <div
                    className="cursor-pointer"
                    onClick={() => onChangeFilters(null, "bhk")}
                  >
                    <IoClose size={20} />
                  </div>
                </div>
              ) : (
                <Form.Select
                  className="bg-transparent text-gray-700 me-2 cursor-pointer"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "bhk")}
                  value={bhk && bhk !== "null" ? bhk : ""}
                >
                  <option value="" disabled>
                    Floor Layout
                  </option>
                  {layouts.map((layout) => {
                    return <option value={layout.value}>{layout.label}</option>;
                  })}
                </Form.Select>
              )}
            </div>

            <div className="col-12 col-md-auto">
              {city && city !== "null" ? (
                <div
                  className="d-flex justify-content-center filter-container  align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <span className="me-2">
                    {allLocations.find((loc) => loc.title === city)?.title}
                  </span>
                  <div
                    className="cursor-pointer"
                    onClick={() => onChangeFilters(null, "location")}
                  >
                    <IoClose size={20} />
                  </div>
                </div>
              ) : (
                <Form.Select
                  className="bg-transparent text-gray-700 me-2 cursor-pointer"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "location")}
                  value={city && city !== "null" ? city : ""}
                >
                  <option value="" disabled>
                    Select Location
                  </option>
                  {allLocations.map((loc, index) => (
                    <option key={index} value={loc.title}>
                      {loc.title}
                    </option>
                  ))}
                </Form.Select>
              )}
            </div>
          </div>
          <div className="col-12 col-md-auto">
            <div className="custom-select-wrapper d-flex">
              <Form.Select
                className="bg-transparent text-gray-700 cursor-pointer custom-select"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                value={sortBy || ""}
              >
                <option value="" disabled>
                  Sort By
                </option>
                <option value="created_at">Featured (New to Old)</option>
                <option value="-created_at">Featured (Old to New)</option>
              </Form.Select>
              {sortBy ? (
                <IoClose
                  size={24}
                  className="cursor-pointer ms-2 custom-close"
                  onClick={() => onChangeFilters(null, "sort_by")}
                />
              ) : (
                <CgSortAz size={24} className="custom-dropdown-icon" />
              )}
            </div>
          </div>
        </div> */}

        <>
          {/* Mobile Filter Icon */}
          <div className="d-block d-md-none">
            <FaFilter
              size={20}
              className="cursor-pointer my-2"
              onClick={handleToggleFilters}
            />
          </div>

          {/* Filters (Visible on desktop and toggled on mobile) */}
          <div
            className={`d-flex flex-column flex-md-row justify-content-between ${
              showFilters ? "" : "d-none d-md-flex" // Show filters when clicked on mobile or always on desktop
            }`}
          >
            <div className="row g-2 mb-3">
              {/* Property Filter */}
              <div className="col-12 col-md-auto">
                {property && property !== "null" ? (
                  <div
                    className="d-flex justify-content-center align-items-center filter-container flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {properties.filter((prop) => prop.slug === property)
                        .length > 0
                        ? properties.filter((prop) => prop.slug === property)[0]
                            .property_name
                        : property}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "property")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "property")
                    }
                    value={property && property !== "null" ? property : ""}
                  >
                    <option value="" disabled>
                      Property Name
                    </option>
                    {properties.map((property) => {
                      return (
                        <option value={property.slug} key={property.slug}>
                          {property.property_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>

              {/* Floor Layout (BHK) Filter */}
              <div className="col-12 col-md-auto">
                {bhk && bhk !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {layouts.filter((lay) => lay.value === bhk)[0].label}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "bhk")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) => onChangeFilters(e.target.value, "bhk")}
                    value={bhk && bhk !== "null" ? bhk : ""}
                  >
                    <option value="" disabled>
                      Floor Layout
                    </option>
                    {layouts.map((layout) => {
                      return (
                        <option value={layout.value} key={layout.value}>
                          {layout.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              </div>

              {/* Location Filter */}
              <div className="col-12 col-md-auto">
                {city && city !== "null" ? (
                  <div
                    className="d-flex justify-content-center filter-container align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <span className="me-2">
                      {allLocations.find((loc) => loc.title === city)?.title}
                    </span>
                    <div
                      className="cursor-pointer"
                      onClick={() => onChangeFilters(null, "location")}
                    >
                      <IoClose size={20} />
                    </div>
                  </div>
                ) : (
                  <Form.Select
                    className="bg-transparent text-gray-700 me-2 cursor-pointer"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onChange={(e) =>
                      onChangeFilters(e.target.value, "location")
                    }
                    value={city && city !== "null" ? city : ""}
                  >
                    <option value="" disabled>
                      Select Location
                    </option>
                    {allLocations.map((loc, index) => (
                      <option key={index} value={loc.title}>
                        {loc.title}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
            </div>

            {/* Sort By Filter */}
            <div className="col-12 col-md-auto">
              <div className="custom-select-wrapper d-flex">
                <Form.Select
                  className="bg-transparent text-gray-700 cursor-pointer custom-select"
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                  value={sortBy || ""}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="-created_at">Featured (New to Old)</option>
                  <option value="created_at">Featured (Old to New)</option>
                </Form.Select>

                {sortBy ? (
                  <IoClose
                    size={24}
                    className="cursor-pointer ms-2 custom-close"
                    onClick={() => onChangeFilters(null, "sort_by")}
                  />
                ) : (
                  <CgSortAz size={24} className="custom-dropdown-icon" />
                )}
              </div>
            </div>
          </div>
        </>

        <div className="mb-3">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : rooms?.length > 0 ? (
            <div
              className="d-flex flex-column align-items-center"
              style={{ minHeight: "80vh" }}
            >
              <Row className="row-cols-1 row-cols-md-3 w-100">
                {rooms?.map((room, index) => (
                  <Col
                    key={index}
                    className={`d-flex flex-column ps-lg-0 py-2 mb-2 justify-content-start`}
                  >
                    {/*  ${index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : ""} */}
                    {small ? (
                      <SpaceCard
                        {...room?.room_data}
                        id={room.id}
                        projectId={room.project}
                        project={room.project_data}
                        image={room.media[0]?.file}
                        title={`${room.room_type_data?.title}/${room.project_data?.property_name}`}
                        // type={`${room.project_data.bhk}, Block ${room.project_data.block}, ${room.project_data.project_area}`}
                        type={`${room.project_data.bhk}, ${room.project_data.flat_area}`}
                        setShow={setShowSpaceView}
                        setRoomData={setRoomData}
                        onClick={() => {
                          console.log(room);
                          setRoomData({
                            id: room.id,
                            projectId: room.project,
                            roomImage: room?.media[0]?.file,
                          });
                          setShowSpaceView(true);
                        }}
                      />
                    ) : (
                      <SpaceMobileCard
                        {...room?.room_data}
                        id={room.id}
                        projectId={room.project}
                        project={room.project_data}
                        image={room.media[0]?.file}
                        slug={room.project_data.slug}
                        title={`${room.room_type_data?.title}/${room.project_data?.property_name}`}
                        type={`${room.project_data.bhk}, ${room.project_data.project_area}`}
                        setShow={setShowSpaceView}
                        setRoomData={setRoomData}
                        onClick={() => handleSpaceCardClick(room)}
                      />
                    )}
                  </Col>
                ))}
                {/* <InfiniteScroll bottom={bottom} noNextItem={noNextItem} /> */}
              </Row>
              {/* <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                onPageChange={handlePageChange}
              /> */}
              <SpacesSliderView
                ativeRoomIndex={activeSpaceIndex}
                setActiveRoomIndex={setActiveSpaceIndex}
                show={showSpaceView}
                setShow={setShowSpaceView}
                roomData={roomData}
              />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
              style={{ height: "60vh" }}
            >
              No spaces available
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default SpacesList;
