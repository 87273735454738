import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { BuilderAccountEditForm, ChangePasswordForm } from "components";
import Library from "pages/User/Library";
import BuilderThoughtForm from "components/Forms/BuilderThoughtForm";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fetchBuildersThoughs } from "reduxFiles/actions/authActions";

const BuilderPersonalProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const [thoughts, setThoughts] = useState({});
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const response =
        pathname.includes("builder") && (await fetchBuildersThoughs(user.id));
      setThoughts(response.data.results[0]);
    };

    fetchData();
  }, [user.id]);

  return (
    <Container fluid className="mt-5 pt-4 px-0" style={{ minHeight: "100vh" }}>
      <Container>
        <BuilderAccountEditForm />
        {/* <ChangePasswordForm /> */}
        <BuilderThoughtForm thoughts={thoughts} />
        {/* <Library showSpace={false} showProfessional={false} /> */}
      </Container>
    </Container>
  );
};

export default BuilderPersonalProfile;
