import { ad_1, ad_2 } from "assets";
import * as authActions from "reduxFiles/actions/authActions";
import ProfessionalCard from "components/Cards/ProfessionalCard";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MultipleAds from "components/Advertisements/MultipleAds";

const ProfessionalsList = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { detailedSlug } = useParams();

  console.log({slug});
  

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  const [professionals, setProfessionals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchArchitectsByTypeSlug = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchArchitectsByTypeSlug(slug, page);
      if (response.data) {
        setProfessionals(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProfessionals = async (page) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProfessionalsPagewise(page);
      if (response.data) {
        setProfessionals((prev) => [...prev, ...response.data?.results]);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (slug) {
      fetchArchitectsByTypeSlug(currentPage);
    } else {
      fetchProfessionals(currentPage);
    }
  }, [slug, currentPage]);

  // Handle infinite scroll
  const handleScroll = () => {
    const bottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;
    if (bottom && !loading && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, currentPage, totalPages]);

  if (detailedSlug) {
    return <Outlet />;
  }

  return (
    <Container className="mt-5 pt-4">
      <section className="d-flex flex-column alignt-items-center">
        <div>
          <div className="h2 inter-600 text-capitalize pt-4">
            Our {slug?.split("-").join(" ")}
          </div>
          {/* <div className="geist mb-2">
            We have professionals with 15+ years of experience and with 500+
            completed projects
          </div> */}
        </div>
        <Row>
          <Col md={8}>
            {loading && currentPage === 1 ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : professionals?.length > 0 ? (
              <div className="d-flex flex-column align-items-center w-100">
                {professionals?.map((architect, index) => (
                  <ProfessionalCard
                    key={index}
                    {...architect}
                    type={"Architect"}
                    profile_img={architect.user_data?.profile_picture}
                    onClick={() =>
                      navigate(`/professionals/${slug}/${architect.slug}`)
                    }
                  />
                ))}
                {loading && currentPage > 1 && (
                  <div className="d-flex justify-content-center my-3">
                    <Spinner />
                  </div>
                )}
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded my-3"
                style={{ height: 160 }}
              >
                {slug
                  ? `No ${slug?.split("-").join(" ")} available`
                  : "No data available"}
              </div>
            )}
          </Col>
          <Col md={4}>
            {/* <div className="my-4">
              <img className="img-fluid" src={ad_1} alt="Ads" />
            </div>
            <div>
              <img className="img-fluid" src={ad_2} alt="Ads" />
            </div> */}
            <MultipleAds endPoint={"architect_ads"}/>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default ProfessionalsList;
