import { useRef } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { truncateText } from "utilities/truncateText";

// items should contain image and title
const Banners = ({ items, speed }) => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  let sliderRef = useRef(null);
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: large ? 6 : 1,
    slidesToScroll: 1,
  };

  return (
    <Container>
      <Row className="mb-4">
        <Slider
          ref={(slider) => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {items.map((item, index) => (
            <Col key={index} className="pe-3">
              <Card
                className="d-flex justify-content-center align-items-center my-2 shadow-none"
                style={{ minHeight: 86, border: "1px solid #E8EAED", borderRadius: 6 }}
              >
                <img
                  src={item?.image}
                  alt={item?.title}
                  className="img-fluid"
                />
              </Card>
            </Col>
          ))}
        </Slider>
      </Row>
    </Container>
  );
};

export default Banners;
