import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const TermsAndPrivacy = () => {
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [step, setStep] = useState(1);
  const location = useLocation();
  const hash = location.hash.substring(1);
  const hashValues = hash.split("|");

  useEffect(() => {
    if (hashValues[0] === "homeowner") {
      setIsHomeOwner(true);
      setStep(3);
    } else if (hashValues[0] === "professional") {
      setIsHomeOwner(false);
      setStep(2);
    }
  }, [location]);
  return (
    <Container
      fluid
      className="my-5 p-0 py-4 d-flex justify-content-center align-items-center"
      style={{ minHeight: "80vh" }}
    >
      <div
        className="h2 text-primary-500 inter-600 p-5"
        style={{ backgroundColor: "#F8F9FA" }}
      >
        {step === 1
          ? "Terms and Privacy"
          : `Signup as Interiokeys ${
              isHomeOwner ? "Homeowner" : "Professional"
            }`}
      </div>
    </Container>
  );
};

export default TermsAndPrivacy;
