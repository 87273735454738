import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Library from "./Library";
import { useLocation } from "react-router-dom";

const Saved = () => {
  const location = useLocation();

  const [showInspiration, setShowInspiration] = useState(false);
  const [showSpace, setShowSpace] = useState(false);
  const [showProfessional, setShowProfessional] = useState(false);
  const [showRooms, setShowRooms] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const [showBrands, setShowBrands] = useState(false);


  useEffect(() => {
    if (location.hash !== "") {
      if (location.hash === "#inspirations") {
        setShowInspiration(true);
      } else if (location.hash === "#spaces") {
        setShowSpace(true);
      } else if (location.hash === "#professionals") {
        setShowProfessional(true);
      } else if (location.hash === "#rooms") {
        setShowRooms(true);
      } else if (location.hash === "#products") {
        setShowProducts(true);
      } else if (location.hash === "#properties") {
        setShowProperties(true);
      }else if (location.hash === "#brands") {
        setShowBrands(true);
      }

    }
  }, [location]);

  return (
    <Container className="mt-5 pt-4 px-0" style={{ minHeight: "80vh" }}>
      <Library
        showAllRecords={true}
        showInspiration={showInspiration}
        showSpace={showSpace}
        showProfessional={showProfessional}
        showRooms={showRooms}
        showProducts={showProducts}
        showProperties={showProperties}
        showBrands={showBrands}
      />
    </Container>
  );
};

export default Saved;
