import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { ad_1 } from "assets";
import ArchitectProjectCard from "components/Cards/ArchitectProjectCard";
import { FaPlus } from "react-icons/fa6";
import { MdDesignServices } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { useScrollBottom, InfiniteScroll } from "components";
import { useSelector } from "react-redux";
import MultipleAds from "components/Advertisements/MultipleAds";

function MyProjects({
  companyName,
  projects,
  setProjects,
  loading,
  setLoading,
  projectType,
  constructionStatus,
  propertySegment,
  sortBy,
  location,
  setProjectsCount,
  setNextProject,
  nextProject,
}) {
  const navigate = useNavigate();
  const bottom = useScrollBottom();
  const [noNextItem, setNoNextItem] = useState(true);
  const user = useSelector((state) => state.auth.user);
  const loadMoreActiveProjects = async () => {
    if (nextProject) {
      const newProject = await authActions.loadProjects(nextProject);
      setNextProject(newProject?.next);
      setProjects((prev) => [...prev, ...newProject?.results]);
    }
  };

  useEffect(() => {
    if (bottom) {
      loadMoreActiveProjects();
    }
  }, [bottom]);

  return (
    <Row>
      <Col lg={9}>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "50vh" }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            {projects?.length > 0 ? (
              projects?.map((project, index) => (
                <>
                  <ArchitectProjectCard
                    key={project.id}
                    project={project}
                    setProjects={setProjects}
                  />
                </>
              ))
            ) : (
              <div
                className="py-4 d-flex flex-column justify-content-center align-items-center"
                style={{ minHeight: "70vh" }}
              >
                {/* <div className="h2 inter-600">Welcome, {companyName}!</div> */}
                <div
                  className="d-flex justify-content-center align-items-center my-3"
                  style={{
                    backgroundColor: "#E8EAED",
                    width: 100,
                    height: 100,
                    borderRadius: 6,
                  }}
                >
                  {/* <MdDesignServices size={30} /> */}
                </div>
                <div className="h3 inter-600">No properties designed yet.</div>
                <div className="body-title text-gray-600">
                  Add your first project to start building your portfolio!
                </div>
                {/* <div className="mt-3">
                  <Button
                    className="d-flex align-items-center text-white"
                    style={{ borderRadius: 3 }}
                    onClick={() => navigate("/architect/addproject")}
                  >
                    <FaPlus className="me-2" /> Add Project
                  </Button>
                </div> */}
              </div>
            )}

            <InfiniteScroll bottom={bottom} noNextItem={noNextItem} />
          </>
        )}
      </Col>
      <Col lg={3} >
        {/* <div className="my-4 position-relative">
          <div className="w-100">
            <img className="img-fluid w-100" src={ad_1} alt="Ads" />
          </div>
          <div className="position-absolute" style={{ top: 10, right: 10 }}>
            <Button variant="info" className="text-gray-900 fw-bold">
              AD
            </Button>
          </div>
        </div> */}

        <MultipleAds
          endPoint={user?.user_type === "brand" ? "brand_ads" : "architect_ads"}
        />
      </Col>
    </Row>
  );
}

export default MyProjects;
