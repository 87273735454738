import React, { useEffect, useRef, useState, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Col,
  Container,
  Form,
  Dropdown,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "components/Forms/RangeSlider";
import ReactSelect from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";

import { notify } from "utilities/notifications/Notify";
import { formatNumber } from "utilities/numberFormatter";
import AddPropertyModal from "./AddPropertyModal";
import LoadingOverlay from "components/General/LoadingOverlay";
import DropFileInputProject from "components/DragInput/DropFileInputProject";
import AddProductsModal from "./AddProductsModal";
import * as authActions from "reduxFiles/actions/authActions";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

function EditProjects() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const { projectId } = useParams();
  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);

  const projectInfoRef = useRef();
  const [brandInfo, setBrandInfo] = useState(null);
  const [architectInfo, setArchitectInfo] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const defaultAddProperty = { id: "add", property_name: "Add New Property" };
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const defaultLayouts = [
    { id: 1, layout: "1BHK" },
    { id: 2, layout: "2BHK" },
    { id: 3, layout: "2.5BHK" },
    { id: 4, layout: "3BHK" },
    { id: 5, layout: "3.5BHK" },
    { id: 6, layout: "4BHK" },
    { id: 7, layout: "5BHK" },
  ];
  const [availableLayouts, setAvailableLayouts] = useState(defaultLayouts);
  const projectSegments = [
    {
      id: 1,
      label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 2,
      label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 3,
      label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 4,
      label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
    {
      id: 5,
      label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)",
      value: "BASIC_COMMERCIAL",
    },
    {
      id: 6,
      label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)",
      value: "MID_RANGE_COMMERCIAL",
    },
    {
      id: 7,
      label: "High-End Commercial (₹4,000 per sq. ft. and above)",
      value: "HIGH_END_COMMERCIAL",
    },
  ];
  const projectStatuses = [
    { id: 1, label: "Design Phase Completed", value: "DESIGN_PHASE_COMPLETED" },
    { id: 2, label: "Execution In Progress ", value: "EXECUTION_IN_PROGRESS" },
    {
      id: 3,
      label: "Execution Phase Completed ",
      value: "EXECUTION_PHASE_COMPLETED",
    },
  ];
  const projectThemes = [
    { value: "MODERN", label: "Modern" },
    { value: "CONTEMPORARY", label: "Contemporary" },
    { value: "ART_MODERNE", label: "Art Moderne" },
    { value: "MID_CENTURY", label: "Mid-Century" },
    { value: "MINIMALIST", label: "Minimalist" },
    { value: "SCANDINAVIAN", label: "Scandinavian" },
    { value: "SHABBY_CHIC", label: "Shabby Chic" },
    { value: "ECLECTIC", label: "Eclectic" },
    { value: "INDUSTRIAL", label: "Industrial" },
    { value: "FARMHOUSE", label: "Farmhouse" },
    { value: "ART_DECO", label: "Art Deco" },
    { value: "BOHO", label: "Boho – Bohemian" },
    { value: "COASTAL", label: "Coastal" },
    { value: "FRENCH_COUNTRY", label: "French Country" },
    { value: "HOLLYWOOD_GLAM", label: "Hollywood Glam" },
    { value: "JAPANDI", label: "Japandi" },
    { value: "MEDITERRANEAN", label: "Mediterranean" },
    { value: "ASIAN_DECOR", label: "Asian Décor" },
    { value: "RUSTIC", label: "Rustic" },
    { value: "TRADITIONAL", label: "Traditional" },
    { value: "TRANSITIONAL", label: "Transitional" },
    { value: "MAXIMALIST", label: "Maximalist" },
    { value: "1970S_REVIVAL", label: "1970s Revival" },
    { value: "ENGLISH_COUNTRY", label: "English Country" },
    { value: "COTTAGECORE", label: "Cottagecore" },
    { value: "GRANDMILLENNIAL", label: "Grandmillennial" },
    { value: "MOROCCAN", label: "Moroccan" },
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedRoomtypes, setSelectedRoomtypes] = useState([]);
  const [roomsToDisplay, setRoomsToDisplay] = useState(null);
  const [roomtypeIndex, setRoomTypeIndex] = useState(0);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [customProperty, setCustomProperty] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailsForProject, setDetailsForProject] = useState({});
  const [rooms, setRooms] = useState([]);
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTheme, setSelectedTheme] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [temp, setTemp] = useState({});

  useEffect(() => {
    const fetchDataById = async () => {
      const response = await authActions.fetchProject(projectId);
      setDetailsForProject(response?.data);
      setProjectDetails(response?.data);
      const mappedRooms = response?.data?.rooms?.map((item) => item) || [];

      console.log(mappedRooms, "mappedRooms");
      setRooms(mappedRooms);
      setSelectedRoomtypes(response?.data?.rooms);
    };
    fetchDataById();
  }, [projectId]);

  const filteredThemes = projectThemes.filter((theme) =>
    theme.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response?.data) {
        setProperties([defaultAddProperty, ...response.data]);
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const layouts = properties.find(
    (property) => property.id === projectDetails?.project_property
  );

  // console.log(layouts?.layout_type_data);

  const fetchRoomTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      // console.log(response?.data);
      if (response.data) {
        const formattedData = response?.data.map((item) => ({
          room_type_data: {
            title: item.title,
            image: item.image,
            slug: item.slug,
            id: item.id || "",
          },
        }));

        // console.log(formattedData, "formattedData");
        setRoomTypes(formattedData);
        const masterBedroom = response.data?.filter(
          (item) => item?.title?.toLowerCase() === "master bedroom"
        )[0];
        /* console.log(masterBedroom);
        if (masterBedroom) {
          addSelectedRoomtype([masterBedroom]);
        } */
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const addSelectedRoomtype = (types) => {
    setProcessing(true);
    // console.log(types, "types");
    if (selectedRoomtypes.length < types.length) {
      if (types?.length > 0) {
        const item = types[types.length - 1];
        const newRoomtype = {
          room_type_data: {
            id: item?.room_type_data?.id,
            title: item?.room_type_data?.title,
            image: item?.room_type_data?.image,
          },
        };

        setSelectedRoomtypes([...selectedRoomtypes, newRoomtype]);
      }
    } else {
      const rtypesIds = new Set(types.map((item) => item.id));
      console.log(selectedRoomtypes, "selectedRoomtypes after removing");
      // const temp = selectedRoomtypes.filter((item) => rtypesIds.has(item.id));
      const temp = [];
      for (const item of selectedRoomtypes) {
        if (rtypesIds.has(item.id)) {
          temp.push(item);
        }
      }
      console.log(rtypesIds, temp);
      setSelectedRoomtypes(temp);
    }
    setProcessing(false);
  };

  const onSelectRoomFiles = (files, index = roomtypeIndex) => {
    // setProcessing(true);
    console.log(files[0]);
    console.log(selectedRoomtypes[roomtypeIndex], "selectedRoomtypes");
    let temp = {
      file: files[0],
      products: [],
    };
    setTemp(files[0]);
    setSelectedRoomtypes([
      ...selectedRoomtypes.slice(0, index),
      {
        ...selectedRoomtypes[index],
        rooms: [temp],
      },
      ...selectedRoomtypes.slice(index + 1),
    ]);
    // setProcessing(false);
  };

  console.log(selectedRoomtypes, "full");
  /* const onEditRoomFiles = (files, index = roomtypeIndex) => {
    setProcessing(true);

    // Get the current files already added for the room
    const currentFiles = new Set(
      selectedRoomtypes[index].rooms.map((item) => item.file)
    );

    // Filter out files that already exist
    const result = files.filter((file) => !currentFiles.has(file));

    // Map new files into the expected format
    const temp = result?.map((item) => {
      return { file: item, products: [] };
    });

    // Update the selected roomtypes, adding the new files to the rooms
    setSelectedRoomtypes([
      ...selectedRoomtypes.slice(0, index),
      {
        ...selectedRoomtypes[index],
        rooms: selectedRoomtypes[index].rooms
          .map((room) => {
            // Here you can check if the room file needs to be edited (if applicable)
            return {
              ...room,
              file: room.file, // Assuming 'file' can be edited if necessary
            };
          })
          .concat(temp), // Add the new files (temp) to the existing rooms
      },
      ...selectedRoomtypes.slice(index + 1),
    ]);

    setProcessing(false);
  }; */

  /*   const onRemoveRoomFile = (index) => {
    const temp = [...selectedRoomtypes];

    const updatedRoomtype = {
      ...temp[roomtypeIndex],
      rooms: [
        ...temp[roomtypeIndex].rooms.slice(0, index),
        ...temp[roomtypeIndex].rooms.slice(index + 1),
      ],
    };

    const updatedRoomtypes = [
      ...temp.slice(0, roomtypeIndex),
      updatedRoomtype,
      ...temp.slice(roomtypeIndex + 1),
    ];

    console.log(updatedRoomtype);

    setSelectedRoomtypes(updatedRoomtypes);
  }; */

  const onRemoveRoomFile = (index) => {
    const updatedRoomtypes = [...selectedRoomtypes];

    // Remove room_product_data from the selected index
    delete updatedRoomtypes[index].room_product_data;

    // Update the state with the modified array
    setSelectedRoomtypes(updatedRoomtypes);

    console.log(selectedRoomtypes);
  };

  const handleGoToPreview = () => {
    // if (selectedRoomtypes?.length > 0) {
    //   let allRoomsHaveProducts = true;
    //   selectedRoomtypes.forEach((item) => {
    //     if (item?.rooms.length > 0) {
    //       item?.rooms.forEach((r) => {
    //         if (r.products.length === 0) {
    //           allRoomsHaveProducts = false;
    //           notify(
    //             "At least one product should be selected for each room",
    //             "error"
    //           );
    //         }
    //       });
    //     } else {
    //       allRoomsHaveProducts = false;
    //       notify("Atleast one room is required in each room type", "error");
    //     }
    //   });
    //   if (allRoomsHaveProducts) {

    //   }
    // } else {
    //   notify("Atleast one room type is required", "error");
    // }

    setCurrentStep(3);
  };

  const handleProjectDetails = () => {
    const formData = new FormData(projectInfoRef.current);
    let isError;
    let data = {};

    const requiredFields = [
      "project_name",
      "property_name",
      "layout",
      "location",
      "flat_area",
      "project_theme",
    ];

    // Iterate through FormData to capture and validate inputs
    formData.forEach((value, key) => {
      // console.log(`${key}: ${value}`);

      // Check if the current field is mandatory
      if (requiredFields.includes(key) && !value.trim()) {
        isError = true;
        console.warn(`Missing required value for: ${key}`); // Log missing fields
      }

      // Add the value (trimmed) to the data object
      data[key] = value.trim();
    });
    if (isError) {
      notify("Please enter the required fields!");
      return;
    }
    data.project_theme = selectedTheme;
    data.id = projectId;
    setProjectDetails(data);
    setCurrentStep(2);
  };

  useEffect(() => {
    if (customProperty) {
      setProperties((prev) => [...prev, customProperty]);
    }
  }, [customProperty]);

  /* useEffect(() => {
    if (propertyId && properties?.length > 0) {
      const temp = {
        ...projectDetails,
        property: propertyId,
        // property: { ...projectDetails?.property, id: propertyId },
      };
      setProjectDetails(temp);
      setSelectedProperty(properties.find((item) => item?.id === propertyId));
    } else {
      setProjectDetails({});
    }
  }, [properties, propertyId]); */

  useEffect(() => {
    if (selectedProperty) {
      if (selectedProperty.layout_type_data) {
        setAvailableLayouts(selectedProperty.layout_type_data);
      } else {
        setAvailableLayouts(defaultLayouts);
      }
    } else {
      setAvailableLayouts(defaultLayouts);
    }
  }, [selectedProperty]);

  useEffect(() => {
    let temp = [];
    if (selectedRoomtypes?.length > 0) {
      selectedRoomtypes.forEach((rt) => {
        if (rt?.rooms?.length > 0) {
          rt?.rooms.forEach((r) => {
            temp.push(r);
          });
        }
      });
    }
    setRoomsToDisplay(temp);
  }, [selectedRoomtypes]);

  console.log(selectedRoomtypes[roomtypeIndex], "selectedRoomtypes");
  console.log(roomsToDisplay, "RoomsToDisplay");

  const handleThemeSelect = (theme) => {
    setSelectedTheme(theme.value);
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      project_theme: theme.label,
    }));
    setShowDropdown(false);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
  };

  const handleRoomSelect = (index, item) => {
    setRoomTypeIndex(index);
    setSelectedRoom(item.title);
  };

  const handleProjectSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("user", JSON.stringify(user?.id));
    formData.append(`project`, JSON.stringify(projectDetails));
    formData.append("image", temp);
    formData.append(
      "room_types",
      JSON.stringify(selectedRoomtypes[roomtypeIndex])
    );

    try {
      const response = await authActions.editProject(projectId, formData);
      console.log(response, "project");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProperties();
    fetchRoomTypes();
  }, []);

  if (!detailsForProject) return;

  return (
    <>
      <AddProductsModal
        show={showProductsModal}
        setShow={setShowProductsModal}
        selectedRoomtypes={selectedRoomtypes}
        setSelectedRoomtypes={setSelectedRoomtypes}
        roomtypeIndex={roomtypeIndex}
        activeRoomIndex={activeRoomIndex}
        setActiveRoomIndex={setActiveRoomIndex}
      />
      <Container
        fluid
        className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "60vh" }}
      >
        <div className="w-100 bg-info d-flex justify-content-center align-items-center">
          <h1 className="h2 text-primary inter-600 py-3">Edit the Project</h1>
        </div>
        {processing && (
          <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
        )}
        <div
          className="d-flex flex-column flex-lg-row my-md-4"
          style={large ? { maxWidth: 1000, width: "100%" } : { width: "100%" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ minWidth: 250 }}
          >
            <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${
                    currentStep === 1
                      ? "bg-black text-white"
                      : currentStep === 2 || currentStep === 3
                      ? "bg-success text-white"
                      : "bg-gray-25"
                  }`}
                >
                  {currentStep === 1 ? "1" : <FaCheck size={12} />}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${
                    currentStep !== 1 ? "text-success" : ""
                  }`}
                >
                  Project Details
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${
                    currentStep === 2
                      ? "bg-black text-white"
                      : currentStep === 3
                      ? "bg-success text-white"
                      : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
                >
                  {currentStep === 1 || currentStep === 2 ? (
                    "2"
                  ) : (
                    <FaCheck size={12} />
                  )}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${
                    currentStep !== 1 && currentStep !== 2
                      ? "text-success"
                      : currentStep === 2
                      ? ""
                      : "text-gray-300"
                  }`}
                >
                  Project Images
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${
                    currentStep === 3
                      ? "bg-black text-white"
                      : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
                >
                  3
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${
                    currentStep === 3 ? "text-gray-900" : "text-gray-300"
                  }`}
                >
                  Project Review
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="p-3 border" style={{ borderRadius: 6 }}>
              {currentStep === 1 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">{currentStep}. Project Details</h4>
                  <div className="mt-4">
                    <Form ref={projectInfoRef}>
                      {/* Project Name */}
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Project Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          name="project_name"
                          defaultValue={projectDetails?.project_name || ""}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Property Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        {properties?.length > 0 ? (
                          <ReactSelect
                            name="project_property"
                            isMulti={false}
                            options={properties}
                            getOptionLabel={(e) => e.property_name}
                            getOptionValue={(e) => e.id}
                            value={properties.find(
                              (property) =>
                                property.id === projectDetails?.project_property
                            )}
                            onChange={(selectedProperty) => {
                              if (selectedProperty?.id !== "add") {
                                setShowPropertyModal(false);
                                setSelectedProperty(selectedProperty);
                                setProjectDetails({
                                  ...projectDetails,
                                  project_property: selectedProperty.id,
                                });
                              } else {
                                setShowPropertyModal(true);
                                setSelectedProperty(null);
                              }
                            }}
                            filterOption={(option, inputValue) => {
                              if (option.value === "add") return true;
                              const propertyName = option.label || "";
                              return propertyName
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                            isClearable
                            placeholder="Select Property"
                            className="body-text2"
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                border: "1px solid #BDC1C6",
                                borderRadius: 3,
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                borderBottom: "1px solid #DADCE0",
                                backgroundColor:
                                  state.data.id === "add"
                                    ? "#FDF3F3"
                                    : state.isFocused
                                    ? "#f0f0f0"
                                    : baseStyles.backgroundColor,
                                color:
                                  state.data.id === "add"
                                    ? "#C53D3D"
                                    : baseStyles.color,
                                fontWeight:
                                  state.data.id === "add"
                                    ? 500
                                    : baseStyles.fontWeight,
                              }),
                            }}
                          />
                        ) : (
                          <div>
                            Please wait while we are loading the properties...
                          </div>
                        )}
                      </Form.Group>

                      {/* Layout and Flat Area */}
                      <div className="d-flex w-100 mb-3">
                        <Form.Group className="w-50">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Layout<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Select
                            required
                            className="py-2 body-text2"
                            name="bhk"
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                bhk: e.target.value,
                              })
                            }
                            value={projectDetails?.bhk || ""}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {layouts?.layout_type_data?.map((type, index) => (
                              <option key={index} value={type.layout}>
                                {type.layout}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="w-50 ms-3">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Flat Area<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Select
                            required
                            className="py-2 body-text2"
                            name="flat_area"
                            value={projectDetails?.flat_area || ""}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                flat_area: e.target.value,
                              })
                            }
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {layouts?.layout_type_data?.map((type, index) => (
                              <option key={index} value={type.area}>
                                {type?.area ? `${type.area} sq.ft.` : ""}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>

                      {/* Project Segment and Cost */}
                      <div className="d-flex w-100 mb-3">
                        {/* Project Segment */}
                        <Form.Group className="w-50">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Project Segment
                          </Form.Label>
                          <Form.Select
                            className="py-2 body-text2"
                            name="property_segment"
                            value={projectDetails?.property_segment || ""}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                property_segment: e.target.value,
                              })
                            }
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {projectSegments?.map((type, index) => (
                              <option key={index} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        {/* Project Cost */}
                        <Form.Group className="w-50 ms-3">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            BOQ Cost
                          </Form.Label>
                          <InputGroup
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <Form.Control
                              required
                              className="py-2 body-text2 border-0"
                              type="number"
                              name="project_cost"
                              placeholder="Enter Amount"
                              value={projectDetails?.project_cost}
                              onChange={(e) =>
                                setProjectDetails({
                                  ...projectDetails,
                                  project_cost: e.target.value,
                                })
                              }
                              style={{
                                border: "1px solid #BDC1C6",
                                borderRadius: 3,
                                width: "60%",
                              }}
                            />
                            <Form.Select
                              className="body-text2 border-0"
                              name="cost_unit"
                              value={projectDetails?.cost_unit || "Lakhs"}
                              onChange={(e) =>
                                setProjectDetails({
                                  ...projectDetails,
                                  cost_unit: e.target.value,
                                })
                              }
                            >
                              <option value="Lakhs">Lakhs</option>
                              <option value="Crores">Crores</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </div>

                      {/* Project Status and Year */}
                      <div className="d-flex w-100 mb-3">
                        {/* Project Status */}
                        <Form.Group className="w-50">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Project Status
                          </Form.Label>
                          <Form.Select
                            className="py-2 body-text2"
                            name="project_status"
                            value={projectDetails?.project_status || ""}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                project_status: e.target.value,
                              })
                            }
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {projectStatuses?.map((type, index) => (
                              <option key={index} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        {/* Project Year */}
                        <Form.Group className="w-50 ms-3">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Project Year
                          </Form.Label>
                          <Form.Control
                            className="py-2 body-text2"
                            type="number"
                            name="project_year"
                            placeholder="2010"
                            value={projectDetails?.project_year || ""}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                project_year: e.target.value,
                              })
                            }
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          />
                        </Form.Group>
                      </div>

                      {/* Location */}
                      {!projectDetails?.property_data?.pincode ? (
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Location<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className="py-2 body-text2"
                            type="text"
                            placeholder="Enter location"
                            name="location"
                            value={
                              projectDetails?.property_data?.location || ""
                            }
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                property_data: {
                                  ...projectDetails.property_data,
                                  location: e.target.value,
                                },
                              })
                            }
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          />
                        </Form.Group>
                      ) : (
                        <>
                          <Form.Group className="mb-3 d-flex">
                            <div className="w-100">
                              <Form.Label className="mb-1 body-text2 inter-500">
                                Pincode<sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Form.Control
                                required
                                disabled
                                className="py-2 body-text2"
                                type="text"
                                onChange={(e) =>
                                  setProjectDetails({
                                    ...projectDetails,
                                    property_data: {
                                      ...projectDetails.property_data,
                                      pincode: e.target.value,
                                    },
                                  })
                                }
                                placeholder="Enter pincode"
                                name="pincode"
                                value={projectDetails?.property_data?.pincode}
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: 3,
                                }}
                              />
                            </div>

                            <div className="ms-2 w-100">
                              <Form.Label className="mb-1 body-text2 inter-500">
                                Country<sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Form.Control
                                required
                                disabled
                                // disabled={projectDetails?.pincode !== ""}
                                className="py-2 body-text2"
                                type="text"
                                placeholder="Enter country"
                                name="country"
                                onChange={(e) =>
                                  setProjectDetails({
                                    ...projectDetails,
                                    property_data: {
                                      ...projectDetails.property_data,
                                      country: e.target.value,
                                    },
                                  })
                                }
                                value={projectDetails?.property_data?.country}
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: 3,
                                }}
                              />
                            </div>
                          </Form.Group>
                          <Form.Group className="mb-3 d-flex">
                            <div className="w-100">
                              <Form.Label className="mb-1 body-text2 inter-500">
                                City<sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Form.Control
                                required
                                disabled
                                className="py-2 body-text2"
                                // disabled={projectDetails?.pincode !== ""}
                                type="text"
                                placeholder="Enter city"
                                name="city"
                                onChange={(e) =>
                                  setProjectDetails({
                                    ...projectDetails,
                                    property_data: {
                                      ...projectDetails.property_data,
                                      city: e.target.value,
                                    },
                                  })
                                }
                                value={projectDetails?.property_data?.city}
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: 3,
                                }}
                              />
                            </div>

                            <div className="ms-2 w-100">
                              <Form.Label className="mb-1 body-text2 inter-500">
                                State<sup style={{ color: "red" }}>*</sup>
                              </Form.Label>
                              <Form.Control
                                required
                                disabled
                                className="py-2 body-text2"
                                // disabled={projectDetails?.pincode !== ""}
                                type="text"
                                placeholder="Enter state"
                                name="state"
                                value={projectDetails?.property_data?.state}
                                onChange={(e) =>
                                  setProjectDetails({
                                    ...projectDetails,
                                    property_data: {
                                      ...projectDetails.property_data,
                                      state: e.target.value,
                                    },
                                  })
                                }
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: 3,
                                }}
                              />
                            </div>
                          </Form.Group>
                        </>
                      )}

                      {/* Project Theme */}
                      {/* <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Project Theme
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          placeholder="Enter Theme"
                          name="project_theme"
                          value={projectDetails?.project_theme || ""}
                          onChange={(e) =>
                            setProjectDetails({
                              ...projectDetails,
                              project_theme: e.target.value,
                            })
                          }
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </Form.Group> */}

                      <div className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Project Theme
                        </Form.Label>
                        <Dropdown
                          show={showDropdown}
                          onToggle={(isOpen) => setShowDropdown(isOpen)}
                        >
                          <Dropdown.Toggle
                            className="bg-transparent text-gray-700 body-text2 inter-500 cursor-pointer w-100"
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                              textAlign: "left",
                            }}
                          >
                            {selectedTheme
                              ? projectThemes.find(
                                  (theme) =>
                                    theme.value.toLowerCase() ===
                                    selectedTheme.toLowerCase()
                                )?.label
                              : projectDetails?.project_theme
                              ? projectThemes.find(
                                  (theme) =>
                                    theme.value.toLowerCase() ===
                                    projectDetails.project_theme.toLowerCase()
                                )?.label
                              : "Select a theme"}
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            style={{
                              maxHeight: "250px",
                              maxWidth: "100%",
                              overflowY: "auto",
                            }}
                          >
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Search themes..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{
                                  border: "1px solid #BDC1C6",
                                  borderRadius: 3,
                                }}
                              />
                            </InputGroup>

                            {filteredThemes.length > 0 ? (
                              filteredThemes.map((theme, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleThemeSelect(theme)}
                                  style={{
                                    whiteSpace: "normal", // Allow wrapping
                                    wordWrap: "break-word", // Break long words
                                    overflowWrap: "break-word", // Handle wrapping for long strings
                                  }}
                                >
                                  {theme.label}
                                </Dropdown.Item>
                              ))
                            ) : (
                              <Dropdown.Item disabled>
                                No themes found
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      {/* Project Description */}
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Project Description
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          as="textarea"
                          rows={6}
                          name="project_description"
                          placeholder="Describe the project..."
                          value={projectDetails?.project_description || ""}
                          onChange={(e) =>
                            setProjectDetails({
                              ...projectDetails,
                              project_description: e.target.value,
                            })
                          }
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                            resize: "none",
                          }}
                        />
                      </Form.Group>

                      {/* Buttons */}
                      <div className="d-flex">
                        <Button
                          className="d-flex align-items-center text-white body-text2 me-2"
                          style={{ borderRadius: 3 }}
                          onClick={handleProjectDetails}
                        >
                          Continue
                        </Button>
                        <Button
                          className="bg-transparent border-gray-200 text-gray-900 body-text2"
                          style={{ borderRadius: 3 }}
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">
                    {currentStep}. Add Project Images
                  </h4>
                  {!loading ? (
                    <div style={{ minHeight: 300 }}>
                      <div className="my-3">
                        <ReactSelect
                          name="type_of_space"
                          defaultValue={rooms || []}
                          options={roomTypes}
                          isMulti
                          getOptionLabel={(e) => e?.room_type_data?.title}
                          getOptionValue={(e) => e?.room_type_data?.id}
                          onChange={(e) => addSelectedRoomtype(e)}
                          isClearable
                          placeholder="Select type of space"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: "#BDC1C6",
                            }),
                          }}
                        />
                      </div>
                      <div
                        className="d-flex align-items-start mb-3 flex-wrap"
                        style={{ width: "100%" }}
                      >
                        {selectedRoomtypes?.length > 0 &&
                          selectedRoomtypes?.map((item, index) => {
                            return (
                              <div key={index}>
                                <Button
                                  className={`cursor-pointer text-center body-text2 inter-500 ${
                                    index == roomtypeIndex
                                      ? ""
                                      : "bg-white border-dark text-dark"
                                  } me-2`}
                                  style={{
                                    flexBasis: "calc(33.33% - 10px)",
                                    whiteSpace: "nowrap",
                                    marginBottom: 10,
                                    borderRadius: 3,
                                  }}
                                  onClick={() => handleRoomSelect(index, item)}
                                >
                                  {item?.room_type_data?.title}{" "}
                                  {/* ({item.rooms.length}) */}
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                      {selectedRoomtypes.length > 0 &&
                        selectedRoomtypes.map((item, index) => {
                          return (
                            <div key={index} className="mb-3">
                              <DropFileInputProject
                                allowMultiple
                                allowOnlyImages
                                className={`bg-white border-dark ${
                                  index === roomtypeIndex ? "d-block" : "d-none"
                                }`}
                                files={() =>
                                  item?.media?.map((item) => item?.file)
                                }
                                onFileChange={(files) => {
                                  onSelectRoomFiles(files);
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: 240 }}
                    >
                      <Spinner />
                    </div>
                  )}
                  {selectedRoomtypes[roomtypeIndex] && (
                    <div>
                      <Row
                        xs={2}
                        sm={2}
                        md={3}
                        lg={3}
                        xl={4}
                        className="pt-2 pb-2"
                      >
                        {/* {selectedRoomtypes.map((item, index) => {
                          return (
                            <Col key={index} className="p-2 mb-2">
                              <div
                                className="d-flex flex-column bg-white w-100 h-100 border border-grey position-relative"
                                style={{ height: 160, borderRadius: 3 }}
                              >
                                <div className="d-flex justify-content-center align-items-center flex-grow">
                                  <Image
                                    src={item?.image}
                                    alt=""
                                    className="img-fluid w-100"
                                    style={{ objectFit: "cover" }}
                                  />
                                </div>
                                <div
                                  className="cursor-pointer text-center text-primary text-decoration-underline body-text2 py-1"
                                  onClick={() => {
                                    setRoomTypeIndex(roomtypeIndex);
                                    setActiveRoomIndex(index);
                                    setShowProductsModal(!showProductsModal);
                                  }}
                                >
                                  {item?.room_product_data?.length} Products
                                  Added
                                </div>
                                <div
                                  className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden position-absolute"
                                  style={{
                                    top: -16,
                                    right: -16,
                                    width: 40,
                                    height: 40,
                                  }}
                                >
                                  <div
                                    className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
                                    onClick={() => onRemoveRoomFile(index)}
                                  >
                                    <MdDelete
                                      className="text-primary cursor-pointer"
                                      size={28}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        })} */}
                        {selectedRoomtypes[roomtypeIndex]?.media && (
                          <Col className="p-2 mb-2">
                            <div
                              className="d-flex flex-column bg-white w-100 h-100 border border-grey position-relative"
                              style={{ height: 160, borderRadius: 3 }}
                            >
                              <div className="d-flex justify-content-center align-items-center flex-grow">
                                <Image
                                  src={
                                    selectedRoomtypes[roomtypeIndex]?.media[0]
                                      ?.file
                                  }
                                  alt=""
                                  className="img-fluid w-100"
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                              <div
                                className="cursor-pointer text-center text-primary text-decoration-underline body-text2 py-1"
                                onClick={() => {
                                  setRoomTypeIndex(roomtypeIndex);
                                  // setActiveRoomIndex(index);
                                  setShowProductsModal(!showProductsModal);
                                }}
                              >
                                {
                                  selectedRoomtypes[roomtypeIndex]?.my_products
                                    ?.length
                                }{" "}
                                Products Added
                              </div>
                              <div
                                className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden position-absolute"
                                style={{
                                  top: -16,
                                  right: -16,
                                  width: 40,
                                  height: 40,
                                }}
                              >
                                <div
                                  className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
                                  onClick={() =>
                                    onRemoveRoomFile(roomtypeIndex)
                                  }
                                >
                                  <MdDelete
                                    className="text-primary cursor-pointer"
                                    size={28}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  )}
                  {selectedRoomtypes[roomtypeIndex]?.rooms?.length > 0 && (
                    <div>
                      <Row
                        xs={2}
                        sm={2}
                        md={3}
                        lg={3}
                        xl={4}
                        className="pt-2 pb-2"
                      >
                        {selectedRoomtypes[roomtypeIndex]?.rooms?.map(
                          (item, index) => {
                            return (
                              <Col key={index} className="p-2 mb-2">
                                <div
                                  className="d-flex flex-column bg-white w-100 h-100 border border-grey position-relative"
                                  style={{ height: 160, borderRadius: 3 }}
                                >
                                  <div className="d-flex justify-content-center align-items-center flex-grow">
                                    {item?.file && (
                                      <Image
                                        src={
                                          item?.file
                                            ? URL.createObjectURL(item?.file)
                                            : null
                                        }
                                        alt=""
                                        className="img-fluid w-100"
                                        style={{ objectFit: "cover" }}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="cursor-pointer text-center text-primary text-decoration-underline body-text2 py-1"
                                    onClick={() => {
                                      setRoomTypeIndex(roomtypeIndex);
                                      setActiveRoomIndex(index);
                                      setShowProductsModal(!showProductsModal);
                                    }}
                                  >
                                    {item.products.length} Products Added
                                  </div>
                                  <div
                                    className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden position-absolute"
                                    style={{
                                      top: -16,
                                      right: -16,
                                      width: 40,
                                      height: 40,
                                    }}
                                  >
                                    <div
                                      className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
                                      onClick={() => onRemoveRoomFile(index)}
                                    >
                                      <MdDelete
                                        className="text-primary cursor-pointer"
                                        size={28}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </div>
                  )}
                  <div className="mt-4 d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={() => {
                        handleGoToPreview();
                      }}
                    >
                      Go to Preview
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(1)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div style={large ? { width: 600 } : {}}>
                  <h4 className="inter-600">{currentStep}. Project Preview</h4>
                  <Slider className="w-100" {...settings}>
                    {roomsToDisplay?.map((room, index) => {
                      return (
                        <div
                          key={index}
                          className="position-relative overflow-hidden"
                        >
                          <div
                            className="bg-gray-50 rounded p-2 position-absolute"
                            style={{ top: 16, left: 16 }}
                          >
                            {room?.products?.length} Products Added
                          </div>
                          <div className="w-100">
                            <img
                              key={index}
                              className="img-fluid w-100 rounded-3"
                              src={URL.createObjectURL(room.file)}
                              style={{
                                height: xlarge
                                  ? 500
                                  : large
                                  ? 500
                                  : medium
                                  ? 400
                                  : small
                                  ? 300
                                  : 200,
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                  <div className="mb-4">
                    {!status && (
                      <div className="mb-4 body-text2">
                        <div className="d-flex justify-content-between">
                          <h4 className="fw-bold">Project Details</h4>
                          <div
                            className="cursor-pointer"
                            onClick={() => setCurrentStep(1)}
                            style={{ width: "24px" }}
                          >
                            <CiEdit size={24} />
                          </div>
                        </div>
                        <div className="my-1">
                          Project Name: {projectDetails.project_name}
                        </div>
                        <div className="my-1">
                          Project Layout: {projectDetails.bhk}
                        </div>
                        <div className="my-1">
                          Project Year: {projectDetails.project_year}
                        </div>
                        <div className="my-1">
                          Flat Area: {projectDetails.project_area}
                        </div>
                        {projectDetails?.project_property?.location && (
                          <div className="my-1">
                            Location: {projectDetails.project_property.location}
                          </div>
                        )}
                        {projectDetails?.project_property?.builder_data && (
                          <div className="my-1">
                            Builder:{" "}
                            {projectDetails.builder_data?.name
                              ? projectDetails.builder_data?.name
                              : "-"}
                          </div>
                        )}
                        <div className="my-1">Architect: {user.full_name}</div>
                        <div className="my-1">
                          Project Description:{" "}
                          {projectDetails?.project_description
                            ? projectDetails?.project_description
                            : "-"}
                        </div>
                      </div>
                    )}
                    {/* <Slider className="w-100" {...settings}>
                      {roomsToDisplay?.map((room, index) => {
                        return (
                          <div
                            key={index}
                            className="position-relative overflow-hidden"
                          >
                            <div
                              className="bg-gray-50 rounded p-2 position-absolute"
                              style={{ top: 16, left: 16 }}
                            >
                              {room?.products?.length} Products Added
                            </div>
                            <div className="w-100">
                              <img
                                key={index}
                                className="img-fluid w-100 rounded-3"
                                src={URL.createObjectURL(room.file)}
                                style={{
                                  height: xlarge ? 500 : large ? 500 : medium ? 400 : small ? 300 : 200
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Slider> */}
                  </div>
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleProjectSubmit}
                    >
                      Save
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(2)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddPropertyModal
          show={showPropertyModal}
          setShow={setShowPropertyModal}
          setCustomProperty={setCustomProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </Container>
    </>
  );
}

export default EditProjects;
