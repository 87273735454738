import { Card } from "react-bootstrap";
import { truncateText } from "utilities/truncateText";

const ProjectCategoryCard = ({title, property_img, onClick, slug, selected, className}) => {
  return (
    <Card
      className={`card-primary-hover p-0 text-start ${
        selected === slug || (title === "All Projects" && selected === "null")
          ? "text-primary-500 border-gray-300"
          : "text-gray-900"
      } ${className}`}
      style={{ width: 128, borderRadius: 5 }}
      onClick={onClick}
    >
      <Card.Img
        className="img-fluid h-100 w-100"
        variant="top"
        src={property_img}
        style={{ width: 128, maxHeight: "100px", minHeight: "100px" }}
      />
      <Card.Body className="d-flex justify-content-center overflow-scroll scrollbar-none p-2">
        <Card.Title
          className="body-text2 text-gray-900 m-0"
          style={{ textOverflow: "ellipsis" }}
        >
          {truncateText(title, 14)}
        </Card.Title>
      </Card.Body>
    </Card>
  )
}

export default ProjectCategoryCard