import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { LuMail } from "react-icons/lu";
import { buildersignup } from "assets";

const BuilderSignup = ({
  form,
  step,
  setStep,
  handleSignUp,
  verifyOtpLogin,
  loading,
  emailError,
  setEmailError,
  otp,
  setOtp,
  setUsername,
  resendTimer,
  setResendTimer,
  isResendDisabled,
  setIsResendDisabled,
  setShowOtpFields,
  showOtpFields
}) => {
  // const [showOtpFields, setShowOtpFields] = useState(false);

  useEffect(() => {
    let timer;
    if (showOtpFields && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(timer);
  }, [showOtpFields, resendTimer]);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (value.match(/^\d$/)) {
      setOtp((prev) => {
        const otpArray = prev.split("");
        otpArray[index] = value;
        return otpArray.join("");
      });
      if (index < 3) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    } else if (value === "") {
      setOtp((prev) => {
        const otpArray = prev.split("");
        otpArray[index] = "";
        return otpArray.join("");
      });
    }
  };

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center mt-5"
      style={{ minHeight: "70vh" }}
    >
      <Row style={{ maxWidth: 1000 }}>
        <Col
          lg={6}
          className="mb-3 mb-lg-0 d-flex flex-column justify-content-between align-items-center"
        >
          <Image src={buildersignup} alt="Builder signup" width="80%" />
          <Card
            style={{
              boxShadow: "none",
              borderRadius: 0,
              border: "none",
              borderLeft: "1px solid #BDC1C6",
            }}
          >
            <h4 className="text-gray-900 inter-600">Builder Registration</h4>
            <div className="text-gray-500">
              List your upcoming and built properties to get the best interior
              inspiration showcasing their versatility.
            </div>
          </Card>
        </Col>
        <Col lg={6}>
          <Form
            ref={form}
            onSubmit={(e) => {
              // e.preventDefault();
              if (!showOtpFields) {
                handleSignUp(e);
                // setShowOtpFields(true);
              } else {
                verifyOtpLogin(e);
              }
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-600">
                Company Name<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Form.Control
                required
                className="py-2 body-text2"
                type="text"
                name="full_name"
                placeholder="Enter your Company Name"
                minLength={3}
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                disabled={showOtpFields}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-1 body-text2 inter-600">
                Phone Number<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text
                  className="body-text2 inter-600"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #BDC1C6",
                    borderRadius: "3px 0px 0px 3px",
                  }}
                >
                  +91
                </InputGroup.Text>
                <Form.Control
                  className="border-start-0 p-2 body-text2"
                  type="text"
                  name="mobile"
                  required
                  placeholder="9090909090"
                  maxLength={10}
                  pattern="[0-9]*"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: "0px 3px 3px 0px",
                  }}
                  disabled={showOtpFields}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label className="mb-1 body-text2 inter-600">
                Company Email<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <InputGroup>
                <InputGroup.Text
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #BDC1C6",
                    borderRadius: "3px 0px 0px 3px",
                  }}
                >
                  <LuMail size={20} color="#1A1C1F" />
                </InputGroup.Text>
                <Form.Control
                  className="border-start-0 p-2 body-text2"
                  type="email"
                  name="email"
                  required
                  placeholder="example@gmail.com"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: "0px 3px 3px 0px",
                  }}
                  onFocus={() => setEmailError("")}
                  onChange={(e) => setUsername(e.target.value)}
                // disabled={showOtpFields}
                />
              </InputGroup>
              <div className="d-flex flex-column">
                {emailError && (
                  <span className="body-text3 text-danger inter-400">
                    {emailError}
                  </span>
                )}
                <span className="body-text3 text-gray-700 inter-400">
                  Note: Your Email ID cannot be changed later. Choose one
                  accordingly.
                </span>
              </div>
            </Form.Group>
            {showOtpFields && (
              <>
                <Form.Group className="my-3">
                  <Form.Label className="mb-1 body-text2 inter-600">
                    OTP<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Row>
                    {[0, 1, 2, 3].map((index) => (
                      <Col key={index} xs="3">
                        <Form.Control
                          required
                          id={`otp-${index}`}
                          maxLength="1"
                          value={otp[index] || ""}
                          onChange={(e) => handleOtpChange(e, index)}
                          style={{
                            textAlign: "center",
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Form.Text className="text-success">
                    OTP has been sent to the email address
                  </Form.Text>
                </Form.Group>
                <div className="text-center mb-3">
                  {isResendDisabled ? (
                    <span className="text-muted">
                      Resend OTP in {resendTimer} seconds
                    </span>
                  ) : (
                    <span
                      className="text-primary cursor-pointer"
                      onClick={(e) => handleSignUp(e)}
                      style={{ textDecoration: "underline" }}
                    >
                      Resend OTP
                    </span>
                  )}
                </div>
              </>
            )}
            <Form.Group className="mb-1">
              <Form.Check
                type="checkbox"
                label={
                  <div className="body-text2 inter-400">
                    Send me important emails for work and job leads.
                  </div>
                }
              />
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Check
                type="checkbox"
                label={
                  <div className="body-text2 inter-400">
                    Yes, I understand and agree to the
                    <span className="text-primary-500">
                      {" "}
                      Interiokeys Terms of Service
                    </span>
                    , including the
                    <span className="text-primary-500">
                      {" "}
                      User Agreement
                    </span>{" "}
                    and
                    <span className="text-primary-500"> Privacy Policy</span>.
                  </div>
                }
                required
              />
            </Form.Group>
            <div className="d-flex mt-5 w-100">
              <Button
                className="px-3 py-2 me-3 text-gray-900 bg-white border-gray-200"
                style={{ borderRadius: 3 }}
                onClick={() => setStep(step - 1)}
                disabled={loading || showOtpFields}
              >
                Back
              </Button>
              <Button
                className="w-100 p-2"
                type="submit"
                style={{ borderRadius: 3 }}
                disabled={loading}
              >
                {loading && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                {showOtpFields ? "Verify OTP" : "Register"}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default BuilderSignup;
