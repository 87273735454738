import { useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import SpaceCard from "components/Cards/SpaceCard";
import Review from "components/Review/Review";
import * as authActions from "reduxFiles/actions/authActions";
import SpacesSliderView from "pages/Projects/SpacesSliderView";
import { useSelector } from "react-redux";
import RatingStarsView from "components/General/RatingStarsView";
import ShareModal from "components/General/ShareModal";
import { notify } from "utilities/notifications/Notify";
import LoginModal from "components/Auth/LoginModal";
import ProductListCard from "components/Cards/ProductListCard";
import { useMediaQuery } from "react-responsive";
import BrochureModal from "components/Professionals/BrochureModal";
import brandDefaultImg from "../../assets/products/post-placeholder.webp";
import ProjectCard from "components/Cards/ProjectCard";

const BrandDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const categoryId = queryParams.get("product_category");
  const category_name = queryParams.get("category_name");

  const form = useRef();
  const [productCategory, setProductCategory] = useState("");
  const [brandDetails, setBrandDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [showShare, setShowShare] = useState(false);
  const [roomData, setRoomData] = useState({});
  const [activeFilter, setActiveFilter] = useState(
    category_name || "all_products"
  );
  const [status, setStatus] = useState("");
  const [sortBy, setSortBy] = useState(queryParams.get("sort_by") || "");
  const [rating, setRating] = useState(5);
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [brandCategories, setBrandCategories] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const [brandRelatedInspirations, setBrandRelatedInspirations] = useState([]);
  const [showBrochureModal, setShowBrochureModal] = useState(false);
  const userBrandId = brandDetails?.id;
  const [loading, setLoading] = useState(false);

  const fetchBrand = async (page) => {
    try {
      const response = await authActions.fetchBrandsBySlug(slug);
      if (response.data) {
        // console.log(response.data);
        setBrandDetails(response.data.results[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandCategories = async () => {
    try {
      const response = await authActions.fetchBrandCategories();
      // console.log(response);
      if (response?.data.results) {
        setBrandCategories(response.data.results);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /*  const fetchProjects = async () => {
    try {
      const resProjects = await authActions.fetchProjects();
      if (resProjects.data) {
        setProjects(resProjects.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  }; */

  const fetchReviews = async (id) => {
    try {
      const resReviews = await authActions.fetchReviewsByArchitectUser(id);
      if (resReviews?.data?.results) {
        setReviews(resReviews.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitReview = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      setShowLoginModal(true);
      return;
    }

    const formData = new FormData(form.current);
    formData.append("for_user", brandDetails?.user_data?.id);
    formData.append("by_user", user.id);
    formData.append("rating", rating);
    if (formData.get("review")) {
      // let data = {};
      // formData.forEach((value, key) => (data[key] = value));
      const response = await authActions.addReview(formData);
      if (response.data) {
        notify("Review submitted successfully!", "success");

        if (brandDetails?.user_data?.id) {
          fetchReviews(brandDetails?.user_data?.id);
        }
        form.current.reset();
      }
    } else {
      notify("Review cannot be empty!", "error");
    }
  };

  const downloadBrochure = (fileUrl) => {
    // Open the modal
    setShowBrochureModal(true);
  };

  const sendEnquiry = async (e) => {
    const response = await authActions.submitBrandEnquiry({
      brand: slug,
    });
    // name, email, pincode, request_type {QUOTE, CATALOGUE, INFORMATION}, message
    console.log(response);
    if (response.data) {
      notify("Brand enquiry submitted successfully!", "success");

      if (brandDetails?.user_data?.id) {
        fetchReviews(brandDetails?.user_data?.id);
      }
    }
  };

  const checkBrandIfSaved = async () => {
    try {
      const response = await authActions.checkIsSavedBrand(
        user?.id,
        brandDetails?.user
      );

      setIsLiked(response.status);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveBrand = async () => {
    if (isProcessing) return;

    setIsProcessing(true);
    try {
      const response = await authActions.saveBrand(
        user?.id,
        brandDetails?.user
      );
      if (response.status) {
        setIsLiked(true);
        notify("Brand saved!", "success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnsaveBrand = async () => {
    if (isProcessing) return;

    setIsProcessing(true);
    try {
      const response = await authActions.unsaveBrand(
        user?.id,
        brandDetails?.user
      );
      console.log(response);
      if (response) {
        setIsLiked(false);
        notify("Brand unsaved!", "success");
      }
      await checkBrandIfSaved();
    } catch (error) {
      console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const brandCategoriesData = async (id) => {
    try {
      const response = await authActions.fetchBrandCategoriesData(id);

      // console.log(response, "brandCategoriesData");
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetFromCategory = (categoryName, brandCategoryId) => {
    // if (categoryName !== activeFilter) {
    //   setActiveFilter(categoryName);
    //   fetchAllProductsbyBrandUser(brandCategoryId);
    // }

    if (categoryName !== activeFilter) {
      const updatedSearchParams = new URLSearchParams(location.search);
      updatedSearchParams.set("category_name", categoryName);
      updatedSearchParams.delete("product_category"); // Optional: Reset related params

      navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

      setActiveFilter(categoryName || "all_products");
      fetchAllProductsbyBrandUser(brandCategoryId, sortBy);
    }
  };

  useEffect(() => {
    if (user?.id && brandDetails?.user) {
      checkBrandIfSaved();
    }
  }, [user?.id, brandDetails?.user]);

  /*  useEffect(() => {
    fetchProjects();
  }, [activeFilter, status, sortBy]); */

  useEffect(() => {
    if (slug && slug !== "undefined") {
      fetchBrand();
    } else {
      navigate(-1);
    }
  }, [slug]);

  useEffect(() => {
    if (userBrandId) {
      fetchReviews(userBrandId);
    }
  }, [userBrandId]);

  const fetchAllProductsbyBrandUser = async (brandId, sortBy) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsByBrandId(
        brandId,
        sortBy
      );

      // console.log(response, "fetchAllProductsbyBrandUser");
      if (response?.data) {
        setBrandRelatedInspirations(response?.data.projects);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userBrandId) {
      // console.log(userBrandId, "brandDetails?.user_data?.id");
      if (category_name === "all_products" || category_name === null) {
        fetchAllProductsbyBrandUser(userBrandId, sortBy);
        setActiveFilter(category_name || "all_products");
      }
    }
  }, [userBrandId, sortBy, activeFilter]);

  // for fetching product by category
  const handleProductCategory = (
    categoryId,
    productId,
    type,
    value = category_name
  ) => {
    setProductCategory(categoryId);
    const updatedSearchParams = new URLSearchParams(location.search);

    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
    setActiveFilter(value || "all_products");
    updatedSearchParams.set("product_category", categoryId);

    if (type === "category_name") {
      updatedSearchParams.set("category_name", value);
    }

    if (type === "sort_by") {
      updatedSearchParams.set("sort_by", value);
    }

    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

    console.log(categoryId, productId, type, value);

    fetchInspirationsByProductCategory(productId, categoryId, sortBy);
  };

  const fetchInspirationsByProductCategory = async (
    brandId,
    categoryId,
    sortBy
  ) => {
    setLoading(true);
    try {
      const response = await authActions.fetchInspirationsByProductCategory(
        brandId,
        categoryId,
        sortBy
      );
      // console.log(response, "fetchInspirationsByProductCategory");
      if (response) {
        setBrandRelatedInspirations(response?.data?.projects);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchInspirationsBySort = async (brandId, categoryId, ordering) => {
    setLoading(true);
    try {
      const response = await authActions.fetchInspirationsSortBy(
        brandId,
        categoryId,
        ordering
      );
      // console.log(response, "fetchInspirationsByProductCategory");
      if (response) {
        console.log(response);
        setBrandRelatedInspirations(response?.data?.projects);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSortBy = (brandId, categoryId, ordering) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("sort_by", ordering);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

    setSortBy(ordering);
    fetchInspirationsBySort(brandId, categoryId, ordering);
  };

  useEffect(() => {
    // Fetch data based on categoryId and productId
    if (categoryId && userBrandId) {
      fetchInspirationsByProductCategory(userBrandId, categoryId, sortBy);
      setActiveFilter(category_name || "all_products");
    }
  }, [categoryId, userBrandId, category_name, sortBy]);

  const handleClearFilters = () => {
    // Create a copy of the current URL search params
    const updatedSearchParams = new URLSearchParams(location.search);

    // Remove specific filters from the search params
    updatedSearchParams.delete("product_category");
    updatedSearchParams.delete("category_name");
    updatedSearchParams.delete("sort_by");
    setSortBy("");
    updatedSearchParams.set("category_name", "all_products");
    setActiveFilter("all_products");
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

    // Navigate to the updated URL without the filters
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  };

  /*   console.log(brandDetails, "brandDetails");
  console.log(brandRelatedInspirations, "brandRelatedInspirations"); */

  return (
    <Container fluid className="pt-5 mt-4 p-0">
      <Container fluid className="bg-gray-25 p-0 border-bottom">
        <Container className="d-flex flex-column flex-md-row geist py-md-5">
          <div className="me-md-4">
            <div className="d-flex justify-content-start w-100 mt-3 mt-lg-0">
              <div style={{ width: 200, height: 200 }}>
                <img
                  className="p-2 bg-white img-fluid ms-auto h-100"
                  style={{
                    width: 200,
                    // height: 200,
                    border: "1px solid #D0D5DD",
                    borderRadius: "10px",
                    objectFit: "contain",
                  }}
                  // src={brandDetails?.user_data?.profile_picture}
                  src={brandDetails?.image || brandDefaultImg}
                  alt="brand Image"
                />
              </div>
            </div>
          </div>
          <div className="flex-grow">
            <div className="pt-2 pt-lg-0 d-flex justify-content-between align-items-center">
              <div className="h2 inter-600">{brandDetails?.company_name}</div>
              <div className="d-flex align-items-center ms-3">
                <Button
                  className="d-flex justify-content-center align-items-center social-btn py-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowShare(true);
                  }}
                >
                  <FiSend size={18} />
                </Button>
                <Button
                  className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${
                    isLiked ? "bg-primary text-white" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!user) {
                      setShowLoginModal(true);
                    } else {
                      isLiked ? handleUnsaveBrand() : handleSaveBrand();
                    }
                  }}
                >
                  {isProcessing ? (
                    <Spinner size="sm" animation="border" />
                  ) : (
                    <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
                  )}
                </Button>
              </div>
            </div>
            <div className="body-text inter-600">
              {brandDetails?.brand_category_data?.name}
            </div>
            <div className="d-flex align-items-center body-text inter-600">
              Ratings: {brandDetails?.rating} <FaStar color="#D95656" />
              {/* { brandDetails?.rating ? (<span> {brandDetails.rating} <FaStar color="#D95656" /></span>) : ( "Unavailable" )} */}
            </div>
            <div>
              <span className="body-text inter-600">Warranty:</span>{" "}
              {brandDetails?.warranty ?? "-"}
            </div>
            <div>
              <span className="body-text inter-600">Average Price:</span>{" "}
              {brandDetails?.min_pricing && brandDetails?.max_pricing && (
                <>
                  ₹{Math.round(brandDetails?.min_pricing)} - ₹
                  {Math.round(brandDetails?.max_pricing)}
                </>
              )}
            </div>
            <div className="mt-3 mb-4">
              {brandDetails?.brochure && (
                <Button
                  className="text-primary bg-info border-0 me-3"
                  style={{ borderRadius: 3 }}
                  onClick={() => downloadBrochure(brandDetails?.brochure)}
                >
                  Download Brochures
                </Button>
              )}
              {/* <Button
                className="text-white"
                style={{ borderRadius: 3 }}
                onClick={sendEnquiry}
              >
                Send Enquiry
              </Button> */}
            </div>
          </div>
        </Container>
      </Container>
      {brandDetails?.youtube_embeded && (
        <Container fluid className="bg-gray-25 p-0 border-bottom">
          <Container className="d-flex flex-column flex-md-row geist py-md-4">
            <div className="d-flex justify-content-center me-md-4">
              <iframe
                maxWidth="300"
                height="200"
                src={brandDetails?.youtube_embeded}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
                className="mb-2 mb-lg-0"
              ></iframe>
            </div>
            <div className="d-flex flex-column justify-content-center m">
              <h4 className="inter-700 m-0">
                “We can finally showcase all the possibilities of our product”
              </h4>
              <div className="body-text1 inter-600">
                {brandDetails?.company_name}
              </div>
              <div
                className="mt-3 mb-3 mb-lg-0"
                style={{ textAlign: "justify" }}
              >
                "We are thrilled to partner with Interiokeys, a dynamic platform
                that seamlessly integrates our range of{" "}
                {brandDetails?.company_name} products into their innovative
                interior designs. It's incredibly gratifying to see our paints
                and finishes featured so prominently in the stunning designs
                showcased on the Interiokeys website”
              </div>
            </div>
          </Container>
        </Container>
      )}
      <Container className="py-3">
        <div className="geist body-title mb-2">
          {/* <span className="inter-700">5,000 real space designs</span> created */}
        </div>
        <Row className="mb-2">
          <Col lg={9}>
            <div className="d-flex mb-2 overflow-scroll scrollbar-none">
              <div className="pe-2 me-2 border-end">
                <Button
                  className={`border inter-500 ${activeFilter === "all_products"
                    ? "bg-primary text-white"
                    : "bg-gray-25 text-dark"
                    }`}
                  onClick={() =>
                    handleGetFromCategory("all_products", userBrandId)
                  }
                >
                  All Inspirations
                </Button>
              </div>

              {brandDetails?.product_category_data?.map((brand, index) => (
                <Button
                  key={index}
                  className={`border me-2 inter-500 ${
                    activeFilter === brand?.title
                      ? "bg-primary text-white"
                      : "bg-gray-25 text-dark"
                  }`}
                  onClick={() =>
                    handleProductCategory(
                      brand?.id,
                      userBrandId,
                      "category_name",
                      brand?.title
                    )
                  }
                >
                  {brand.title}
                </Button>
              ))}
              <Button
                onClick={handleClearFilters}
                className={`border me-2 inter-500 bg-primary text-white`}
              >
                Clear Filters
              </Button>
            </div>
          </Col>
          <Col lg={3}>
            <Row>
              {/* <Col className="px-2">
                <Form.Select
                  defaultValue=""
                  className="bg-gray-25"
                  onSelect={(e) => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Status
                  </option>
                  <option value="completed">Complete</option>
                  <option value="ongoing">Ongoing</option>
                  <option value="upcoming">Upcoming</option>
                </Form.Select>
              </Col> */}
              <Col className="px-2">
                <Form.Select
                  defaultValue=""
                  className="bg-gray-25"
                  value={sortBy}
                  onChange={(e) =>
                    handleSortBy(userBrandId, productCategory, e.target.value)
                  }
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="-created_at">Featured (New to Old)</option>
                  <option value="created_at">Featured (Old to New)</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {loading === true ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "200px" }}
            >
              <Spinner size="sm" className="me-2" />
            </div>
          ) : brandRelatedInspirations?.length <= 0 ? (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
              style={{ height: 160 }}
            >
              No Inspirations available
            </div>
          ) : (
            brandRelatedInspirations &&
            brandRelatedInspirations?.map((item, index) => (
              <Col
                key={index}
                lg={4}
                md={6}
                sm={12}
                className={`d-flex flex-column py-2 mb-2 justify-content-start ${
                  large
                    ? index % 3 === 0
                      ? "ps-0"
                      : index % 3 === 2
                      ? "pe-0"
                      : ""
                    : medium
                    ? index % 2 === 0
                      ? "ps-0"
                      : "pe-0"
                    : "p-0"
                }`}
              >
                {/* <ProductListCard
                  {...product}
                  images={product?.media}
                  product_type={product?.title}
                  className={`cursor-pointer`}
                /> */}
                <ProjectCard
                  {...item}
                  id={item.id}
                  project_name={item?.project_name}
                  image={item?.rooms[0]?.media[0]?.file}
                  // type={`${item?.bhk}, Block ${item?.block}, ${item?.project_area}`}
                  type={`${item?.bhk}, ${item?.project_area}`}
                  architect={item?.company_name}
                  location={item?.property_data?.location}
                  builders={
                    item?.property_data?.builder_data?.company_name
                  }
                />
              </Col>
            ))
          )}
        </Row>
        <div>
          <Row>
            {projects?.map((project) =>
              project?.rooms?.map((room, index) => (
                <Col
                  key={index}
                  md={6}
                  lg={4}
                  className="d-flex justify-content-around my-3"
                >
                  <SpaceCard
                    {...room?.room_data}
                    id={room.id}
                    projectId={room.project}
                    type={room.type}
                    image={room?.media[0]?.file}
                    title={`${room?.room_type_data?.title}/${room?.project_data?.property_name}`}
                    project={room?.project_data?.project_name}
                    area={room?.project_data?.property_area}
                    setShow={setShowSpaceView}
                    setRoomData={setRoomData}
                    onClick={() => {
                      setRoomData({
                        id: room.id,
                        projectId: room.project,
                        roomImage: room?.media[0]?.file,
                      });
                      showSpaceView(true);
                    }}
                  />
                </Col>
              ))
            )}
          </Row>
          <SpacesSliderView
            ativeRoomIndex={activeSpaceIndex}
            setActiveRoomIndex={setActiveSpaceIndex}
            show={showSpaceView}
            setShow={setShowSpaceView}
            roomData={roomData}
          />
        </div>
        <div className="my-4">
          {/* {user && user.user_type === "customer" && ( */}
          <>
            <div className="h3 inter-600 mb-3">Add Review</div>
            <div className="d-flex justify-content-end mb-3">
              <RatingStarsView rating={rating} setRating={setRating} />
            </div>
            <Form ref={form} onSubmit={submitReview}>
              <Form.Group>
                <Form.Control
                  name="review"
                  as="textarea"
                  rows={5}
                  placeholder="I love interiokeys because..."
                  style={{ resize: "none" }}
                />
              </Form.Group>
              <div className="w-100 my-3 d-flex justify-content-end">
                <Button className="text-white" type="submit">
                  Submit Review
                </Button>
              </div>
            </Form>
          </>
          {/* )} */}
          <div className="h4 inter-600">Reviews and Ratings</div>
          {reviews?.length > 0 ? (
            reviews?.map((review, index) => <Review key={index} {...review} />)
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
              style={{ height: 160 }}
            >
              No reviews available
            </div>
          )}
        </div>
      </Container>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={brandDetails?.id}
        image={brandDetails?.image}
        title={brandDetails?.company_name}
        urlPath={slug !== "undefined" ? `/brands/${slug}` : null}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
      <BrochureModal
        brandDetails={brandDetails}
        showBrochureModal={showBrochureModal}
        setShowBrochureModal={setShowBrochureModal}
      />
    </Container>
  );
};

export default BrandDetails;
