import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { FaFile, FaFileImage, FaFilePdf } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { useMediaQuery } from "react-responsive";
import DropFileInputProperty from "components/DragInput/DropFileInputProperty";
import UploadingFiles from "components/DragInput/UploadingFiles";
import { FiRefreshCcw, FiTrash2 } from "react-icons/fi";

function EditProperty() {
  const { propertyId } = useParams();
  const navigate = useNavigate();

  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  // const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const propertyInfoRef = useRef();
  const [propertyData, setPropertyData] = useState(null);
  const [previousPincode, setPreviousPincode] = useState("");

  const [hover, setHover] = useState(false);
  const [projectTypes, setProjectTypes] = useState([]);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [pincodeLength, setPincodeLength] = useState(0);

  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [selectedConstructionStatus, setSelectedConstructionStatus] =
    useState("");
  const [selectedPropertySegment, setSelectedPropertySegment] = useState("");

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [parentId, setParentId] = useState(null);

  const [selectedPropertyImage, setSelectedPropertyImage] = useState(null);
  const [completeProjectFiles, setCompleteProjectFiles] = useState([]);
  const [mediaList, setMediaList] = useState([]);

  const constructionStatuses = [
    { id: 1, label: "Pre-Construction Phase", value: "PRE_CONSTRUCTION" },
    { id: 2, label: "Under Construction", value: "UNDER_CONSTRUCTION" },
    { id: 3, label: "Ready for Occupancy", value: "READY_FOR_OCCUPANCY" },
    { id: 4, label: "Completed", value: "COMPLETED" },
  ];

  // const propertySegments = [
  //   { id: 1, label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)", value: "AFFORDABLE_RESIDENTIAL" },
  //   { id: 2, label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)", value: "MID_RANGE_RESIDENTIAL" },
  //   { id: 3, label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)", value: "LUXURY_RESIDENTIAL" },
  //   { id: 4, label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)", value: "ULTRA_LUXURY_RESIDENTIAL" },
  //   { id: 5, label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)", value: "BASIC_COMMERCIAL" },
  //   { id: 6, label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)", value: "MID_RANGE_COMMERCIAL" },
  //   { id: 7, label: "High-End Commercial (₹4,000 per sq. ft. and above)", value: "HIGH_END_COMMERCIAL" },
  // ]

  const propertySegments = [
    {
      id: 1,
      label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 2,
      label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)",
      value: "BASIC_COMMERCIAL",
    },
    {
      id: 3,
      label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 4,
      label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)",
      value: "MID_RANGE_COMMERCIAL",
    },
    {
      id: 5,
      label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 6,
      label: "High-End Commercial (₹4,000 per sq. ft. and above)",
      value: "HIGH_END_COMMERCIAL",
    },
    {
      id: 7,
      label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
  ];

  const fetchPropertyData = async () => {
    const response = await authActions.fetchPropertyById(propertyId);
    try {
      if (response.data) {
        setPropertyData(response.data);
        setPreviousPincode(response?.data?.pincode);
        setPincodeLength(response?.data?.pincode?.length);
        setSelectedProjectType(response.data.project_type);
        setSelectedConstructionStatus(response.data.construction_status);
        setSelectedPropertySegment(response.data.project_segment);
        setMediaFiles(response.data?.media_files);
        setMediaList(response.data?.media_files);
        setLayouts(response.data?.layout_type_data);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(completeProjectFiles, "completeProjectFiles");

  useEffect(() => {
    if (propertyId) {
      fetchPropertyData();
    }
  }, [propertyId]);

  const fetchProjectTypes = async () => {
    const response = await authActions.fetchProjectTypes();
    try {
      if (response.data) {
        setProjectTypes(response.data?.results);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePropertyImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedPropertyImage(file);
    }
  };

  const addLayout = () => {
    setLayouts([
      ...layouts,
      {
        layout: "",
        area: "",
      },
    ]);
  };

  const deleteLayout = (index) => {
    setLayouts([...layouts.slice(0, index), ...layouts.slice(index + 1)]);
  };

  const handleInputChange = (layoutId, field, value) => {
    console.log(layoutId, field, value, layouts);
    setLayouts(
      layouts.map((layout) =>
        layout.id === layoutId ? { ...layout, [field]: value } : layout
      )
    );
  };

  /* const handlePropertyDetails = () => {
    const formData = new FormData(propertyInfoRef.current);
    let isError;
    let data = {};
    formData.forEach((value, key) => {
      // console.log(key, value);
      if (!value) {
        isError = true;
      } else {
        data[key] = value;
      }
    });

    // console.log(formData, "formData");

    if (isError) {
      notify("Please enter in all the required fields!");
      return;
    } else {
      handlePropertyUpdate();
    }
  }; */

  const handleChange = (name, value) => {
    if (name === "pincode") {
      setPincodeLength(value.length);

      if (value.length === 6 && value !== previousPincode) {
        getPincode(value);
      }

      setPreviousPincode(value); // Update the state regardless
    }
    setPropertyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getPincode = async (pincode) => {
    try {
      if (pincode.length < 6 || pincode === previousPincode) return; // Avoid unnecessary API calls

      const response = await authActions.fetchPincode(pincode);
      setPropertyData((prevDetails) => ({
        ...prevDetails,
        pincode: response?.Pincode || "",
        country: response?.Country || "",
        city: response?.District || "",
        state: response?.State || "",
      }));

      setPreviousPincode(pincode);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFiles = (files) => {
    setCompleteProjectFiles(files);
    setMediaList((prev) => [
      ...prev,
      {
        temp_file_name: files[0]?.name,
        size: files[0]?.size,
      },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToUpdate = {
      property_name: propertyData?.property_name,
      flats: propertyData?.flats,
      construction_status: propertyData?.construction_status,
      property_year: propertyData?.property_year,
      city: propertyData?.city,
      country: propertyData?.country,
      pincode: propertyData?.pincode,
      state: propertyData?.state,
      project_type: propertyData?.project_type,
      property_segment: propertyData?.property_segment,
    };

    const propertyDetails = {
      property: dataToUpdate,
      layouts: layouts,
      mediaFiles: mediaFiles,
    };

    const formData = new FormData();

    formData.append(`property`, JSON.stringify(propertyDetails.property));
    // Append `layouts`

    formData.append(`layouts`, JSON.stringify(propertyDetails.layouts));

    formData.append(`mediaFiles`, JSON.stringify(propertyDetails.mediaFiles));
    // Append `selectedPropertyImage` if it exists
    if (selectedPropertyImage) {
      formData.append("property_img", selectedPropertyImage);
    }

    // Debugging output
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    // console.log(formData, "formData");
    try {
      const response = await authActions.updateProperty(propertyId, formData);
      if (response.data) {
        notify("Property Updated!", "success");
        if (completeProjectFiles.length > 0) {
          setIsFileUploading(true);
          setParentId(response.data.id);
        } else {
          navigate(`/builder/properties`, { state: { reload: true } });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (id) => {
    setMediaFiles((prevMediaFiles) =>
      prevMediaFiles.filter((file) => file.id !== id)
    );
    setMediaList((prevMediaList) =>
      prevMediaList.filter((file) => file.id !== id)
    );
  };

  /* const handlePropertyUpdate = async () => {
    const propertyDetails = {
      property: dataToUpdate,
      layouts: layouts,
      mediaFiles: mediaFiles,
    };
    const formData = new FormData();
    formData.append("data", JSON.stringify(propertyDetails));
    if (selectedPropertyImage) {
      formData.append("property_img", selectedPropertyImage);
    }
    try {
      const response = await authActions.updateProperty(propertyId, formData);
      if (response.data) {
        notify("Property Updated!", "success");
        if (completeProjectFiles.length > 0) {
          setIsFileUploading(true);
          setParentId(response.data.id);
        } else {
          navigate(`/builder/properties`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }; */

  useEffect(() => {
    fetchProjectTypes();
  }, []);

  return (
    <Container
      className={`mt-5 pt-4 d-flex flex-column justify-content-center ${
        large ? "px-0" : ""
      }`}
      style={{ minHeight: "100vh" }}
    >
      <div className="d-flex justify-content-between align-items-center mt-5 pt-4">
        <h3 className="text-gray-900 inter-600 m-0">Edit Property Details</h3>
        <div className="d-flex">
          <Button
            className="d-flex align-items-center text-white body-text2 me-2"
            style={{ borderRadius: 3 }}
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
          <Button
            className="bg-transparent border-gray-200 text-gray-900 body-text2"
            style={{ borderRadius: 3 }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row my-4 w-100">
        <div
          className={`p-3 me-2 border border-gray-100 ${
            large ? "w-50" : "w-100"
          }`}
          style={{ borderRadius: 6 }}
        >
          <h4 className="fw-bold">Property Details</h4>
          <div className="mt-4">
            <Form ref={propertyInfoRef}>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Property Image<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Card
                  className="d-flex justify-content-center position-relative overflow-hidden cursor-pointer shadow-none p-0 mb-3"
                  style={{
                    width: 180,
                    height: 150,
                    border: "1px dashed #BDC1C6",
                    borderRadius: 6,
                  }}
                >
                  <Image
                    className="p-0"
                    style={{ borderRadius: 6, objectFit: "cover" }}
                    maxWidth={180}
                    height="100%"
                    src={
                      selectedPropertyImage
                        ? URL.createObjectURL(selectedPropertyImage)
                        : propertyData?.property_img
                    }
                    alt="avatar"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  />
                  {hover && (
                    <div
                      className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: 6,
                      }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      onClick={() =>
                        document.getElementById("propertyImageUpload").click()
                      }
                    >
                      <FiRefreshCcw size={20} color="white" />
                      <span className="mt-1">Change Property Image</span>
                    </div>
                  )}
                  <input
                    type="file"
                    id="propertyImageUpload"
                    style={{ display: "none" }}
                    onChange={handlePropertyImageChange}
                  />
                </Card>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Property Name<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  name="property_name"
                  placeholder="Enter property name"
                  onChange={(e) =>
                    handleChange("property_name", e.target.value)
                  }
                  defaultValue={propertyData?.property_name}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Number of Flats<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="No. of flats"
                  name="flats"
                  onChange={(e) => handleChange("flats", e.target.value)}
                  defaultValue={propertyData?.flats}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group>
              <Form.Group className="mb-3 d-flex">
                <div className="w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Construction Status<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Select
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Select Status"
                    name="construction_status"
                    onChange={(e) =>
                      handleChange("construction_status", e.target.value)
                    }
                    value={propertyData?.construction_status}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <option value="">Select</option>
                    {constructionStatuses.map((conStatus) => {
                      return (
                        <option value={conStatus.value}>
                          {conStatus.label}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <div className="ms-2 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Possession Year<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Possession Year"
                    name="property_year"
                    onChange={(e) =>
                      handleChange("property_year", e.target.value)
                    }
                    defaultValue={propertyData?.property_year}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </div>
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Location<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Control
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="Enter location"
                  name="location"
                  onChange={(e) => handleChange("location", e.target.value)}
                  defaultValue={propertyData?.location}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                />
              </Form.Group> */}
              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Pincode<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter pincode"
                    name="pincode"
                    onChange={(e) => handleChange("pincode", e.target.value)}
                    defaultValue={propertyData?.pincode}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Form.Group>

                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    Country<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter country"
                    name="country"
                    onChange={(e) => handleChange("country", e.target.value)}
                    defaultValue={propertyData?.country}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Form.Group>
              </div>

              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    State<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter state"
                    name="state"
                    onChange={(e) => handleChange("state", e.target.value)}
                    defaultValue={propertyData?.state}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Form.Group>

                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-1 body-text2 inter-500">
                    City<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    placeholder="Enter city"
                    name="city"
                    onChange={(e) => handleChange("city", e.target.value)}
                    defaultValue={propertyData?.city}
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Form.Group>
              </div>

              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Project Type<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Select
                  required
                  className="py-2 body-text2"
                  type="text"
                  placeholder="Enter location"
                  name="project_type"
                  onChange={(e) => handleChange("project_type", e.target.value)}
                  value={propertyData?.project_type}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <option value="">Select</option>
                  {projectTypes?.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-500">
                  Property Segment<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <Form.Select
                  required
                  className="py-2 body-text2"
                  type="text"
                  name="property_segment"
                  onChange={(e) =>
                    handleChange("property_segment", e.target.value)
                  }
                  value={propertyData?.property_segment}
                  style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                >
                  <option value="">Select</option>
                  {propertySegments?.map((segment, index) => (
                    <option key={index} value={segment.value}>
                      {segment.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div
          className={`p-3 border border-gray-100 ${
            large ? "ms-2 w-50" : "mt-4 w-100"
          }`}
          style={{ borderRadius: 6 }}
        >
          <h4 className="fw-bold">Property Details</h4>
          <div className="my-4">
            {layouts.map((layout, index) => (
              <div key={index}>
                <Row className="w-100 mt-2 me-2">
                  <Col xs={5} md={5} className="ps-3">
                    {/* <Form.Group className="mb-3 mb-md-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Layout Type<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Control
                        required
                        className="py-2 body-text2"
                        type="text"
                        name="layout"
                        placeholder="2BHK"
                        defaultValue={layout.layout}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        onChange={(e) =>
                          handleInputChange(
                            layout.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Form.Group> */}
                    <Form.Group className="mb-3 mb-md-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Layout Type<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <Form.Select
                        required
                        className="py-2 body-text2"
                        name="layout"
                        value={layout.layout}
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                        onChange={(e) =>
                          handleInputChange(
                            layout.id,
                            e.target.name,
                            e.target.value
                          )
                        }
                      >
                        <option value="" disabled>
                          Select Layout
                        </option>
                        {layouts.map((layoutOption) => (
                          <option
                            key={layoutOption.id}
                            value={layoutOption.layout}
                          >
                            {layoutOption.layout}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={6} className="px-1">
                    <Form.Group className="mb-3 mb-sm-0">
                      <Form.Label className="mb-1 body-text2 inter-500">
                        Layout Area<sup style={{ color: "red" }}>*</sup>
                      </Form.Label>
                      <InputGroup
                        style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                      >
                        {/* Area Input - Numbers Only */}
                        <Form.Control
                          required
                          className="py-2 body-text2 border-0"
                          type="number" // Restrict to numbers
                          name="area"
                          placeholder="200"
                          value={layout.area} // Controlled value
                          onChange={(e) =>
                            handleInputChange(
                              layout.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: "3px 0 0 3px",
                            width: "40%",
                          }}
                        />
                        {/* Unit Dropdown */}
                        <Form.Select
                          className="body-text2 border-0"
                          name="unit"
                          value={layout.unit || "Sq.ft."} // Default value
                          onChange={(e) =>
                            handleInputChange(
                              layout.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: "0 3px 3px 0",
                          }}
                        >
                          <option value="Sq.ft.">Sq.ft.</option>
                          <option value="Sq.m.">Sq.m.</option>
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col xs={1} md={1} className="d-flex jsutify-content-end">
                    {index > 0 && (
                      <div className="d-flex align-items-end">
                        <Button
                          className="d-flex align-items-center rounded-btn bg-gray py-2"
                          onClick={() => deleteLayout(index)}
                        >
                          <FiTrash2 size={18} />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
            <div
              className="mt-2 ms-2 body-text3 inter-500 cursor-pointer"
              onClick={() => addLayout()}
            >
              <span style={{ borderBottom: "1px solid #000000" }}>
                Add another layout
              </span>
            </div>
          </div>
          <div>
            <h4 className="fw-bold">Property Files</h4>
            <div className="text-gray-600 body-text3 mb-2">
              Add all layout files relevant to designers here. Try to label them
              according to standards.
            </div>
            <DropFileInputProperty
              type="property"
              files={completeProjectFiles}
              allowMultiple
              className="bg-white border-dark"
              onFileChange={(files) => handleFiles(files)}
            />
            <div className="inter-600 mt-3 mb-2">Files Added:</div>
            {mediaList.map((file, index) => {
              return (
                <Card
                  key={index}
                  className="d-flex flex-row justify-content-between align-items-center border-gray-100 shadow-none mb-1"
                  style={{ borderRadius: 3 }}
                >
                  <div className="d-flex align-items-center">
                    {file?.extension === ".pdf" ? (
                      <FaFilePdf className="text-primary-500 me-2" size={24} />
                    ) : file?.extension === ".png" ||
                      file?.extension === ".jpg" ||
                      file?.extension === ".jpeg" ||
                      file?.extension === ".webp" ||
                      file?.extension === ".heic" ? (
                      <FaFileImage className="me-2" size={24} color="#83c8e4" />
                    ) : (
                      <FaFile className="text-primary-500 me-2" size={24} />
                    )}
                    <div className="d-flex flex-column">
                      <span className="body-text2 text-gray-900">
                        {file?.temp_file_name}
                      </span>
                      <span className="text-gray-600" style={{ fontSize: 10 }}>
                        {file?.size}
                      </span>
                    </div>
                  </div>
                  <FiTrash2
                    size={20}
                    color="#3C4043"
                    onClick={() => handleDelete(file?.id)}
                  />
                </Card>
              );
            })}
          </div>
        </div>
      </div>
      <UploadingFiles
        show={isFileUploading}
        files={completeProjectFiles}
        setShow={setIsFileUploading}
        parentId={parentId}
        app_label={"property"}
        model_name={"property"}
      />
    </Container>
  );
}

export default EditProperty;
