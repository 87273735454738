import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ProjectCard from "./ProjectCard";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { default_property_image } from "assets";

function PropertyCard({
  id,
  slug,
  property_name,
  property_img,
  flats,
  layout_types,
  project_type,
  location,
  city,
  state,
  pincode,
  country,
  builder,
  recommended,
  new_property,
  status,
  is_archived,
  archive_request,
  setArchiveModal,
  setCancelArchiveModal,
  setUnarchiveModal,
  setThisPropertyId,
  setThisPropertyName,
  allFunction = true,
}) {
  const navigate = useNavigate();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [display, setDisplay] = useState(false);
  const [inspirations, setInspirations] = useState([]);
  const [menu, setMenu] = useState(false);
  const [hover, setHover] = useState("");

  const fetchProjects = async () => {
    try {
      const response = await authActions.fetchProjectByProperty(id);
      if (response?.data?.results) {
        setInspirations(response?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchProjects();
    }
  }, [id]);

  return (
    <Card
      className={`p-0 d-flex flex-column text-start my-4 mx-2 mx-lg-0 shadow-none ${
        allFunction ? "border-gray-100" : "border-0"
      }`}
      style={{ minHeight: 200, borderRadius: 6 }}
    >
      <Row className={`flex-wrap mx-0 ${display ? "border-bottom" : ""}`}>
        <Col
          md={4}
          lg={3}
          className="mb-2 mb-md-0 px-0 position-relative"
          style={{ flexGrow: 1 }}
        >
          <Card.Img
            className="bg-gray-25 border-0"
            variant="top"
            src={property_img ?? default_property_image}
            style={
              allFunction ? { height: 200 } : { height: 200, borderRadius: 6 }
            }
          />
          {!property_img && (
            <div
              className="position-absolute inter-500 body-text2"
              style={{ top: "50%", left: "30%" }}
            >
              No Property Image
            </div>
          )}
        </Col>
        <Col md={8} lg={9} className="p-0">
          <Card.Body
            className={`w-100 h-100 p-2 p-md-3 d-flex flex-column ${
              allFunction ? "justify-content-between" : "justify-content-center"
            }`}
          >
            <div>
              {" "}
              {/* For justify-content-between, do not remove */}
              <div className="d-flex flex-row justify-content-between mb-2 responsive-container">
                <Card.Title className="inter-700 m-0 d-flex align-items-center responsive-text ">
                  {property_name} / {builder}
                </Card.Title>
                {allFunction && (
                  <div className="d-flex justify-content-center align-items-center">
                    {archive_request && (
                      <div className="inter-400 body-text2">
                        <span
                          className="border border-gray-300 bg-gray-50 text-gray-900 py-1 px-2 responsive-text"
                          style={{
                            borderRadius: 5,
                            display: "inline-block", // Consistent block behavior
                            minWidth: "100px", // Prevents breaking into new lines
                          }}
                        >
                          {is_archived ? "Archived" : "Archive Requested"}
                        </span>
                      </div>
                    )}
                    <div
                      className={`inter-400 mx-2 body-text2 ${
                        archive_request && !is_archived && "strike-out-text"
                      }`}
                    >
                      {status === "UNDER_APPROVAL" ? (
                        <span
                          className="border border-warning-300 bg-warning-50 text-warning-900 py-1 px-2 responsive-text"
                          style={{
                            borderRadius: 5,
                            display: "inline-block", // Ensures consistent alignment
                            minWidth: "80px", // Avoids shrinkage
                          }}
                        >
                          Under Review
                        </span>
                      ) : status === "APPROVED" ? (
                        <span
                          className="border border-success-300 bg-success-50 text-success-900 py-1 px-2"
                          style={{ borderRadius: 5 }}
                        >
                          Approved
                        </span>
                      ) : status === "REJECTED" ? (
                        <span
                          className="border border-error-300 bg-error-50 text-error-900 py-1 px-2"
                          style={{ borderRadius: 5 }}
                        >
                          Rejected
                        </span>
                      ) : (
                        <span
                          className="border border-gray-300 bg-gray-50 text-gray-900 py-1 px-2"
                          style={{ borderRadius: 5 }}
                        >
                          Designing
                        </span>
                      )}
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center position-relative cursor-pointer"
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "#F1F3F4",
                      }}
                      onClick={() => setMenu(!menu)}
                    >
                      <BsThreeDots size={18} />
                      <Card
                        className={`position-absolute shadow-none body-text2 p-0 ${
                          menu ? "" : "d-none"
                        }`}
                        style={{
                          right: 0,
                          top: 30,
                          borderRadius: 3,
                          border: "1px solid #DADCE0",
                          minWidth: archive_request ? 170 : 140,
                        }}
                        onMouseLeave={() => setMenu(false)}
                      >
                        <div
                          className={`p-2 border-bottom ${
                            hover === "edit" ? "bg-gray-50" : ""
                          }`}
                          onMouseEnter={() => setHover("edit")}
                          onMouseLeave={() => setHover("")}
                          onClick={() => navigate(`${id}`)}
                        >
                          <span
                            className={`${
                              hover === "edit" ? "inter-500" : "inter-400"
                            }`}
                          >
                            Edit Property
                          </span>
                        </div>
                        {archive_request && !is_archived ? (
                          <div
                            className={`p-2 border-bottom ${
                              hover === "cancelarchiverequest"
                                ? "bg-gray-50"
                                : ""
                            }`}
                            onMouseEnter={() =>
                              setHover("cancelarchiverequest")
                            }
                            onMouseLeave={() => setHover("")}
                            onClick={() => {
                              setThisPropertyId(id);
                              setThisPropertyName(property_name);
                              setCancelArchiveModal(true);
                            }}
                          >
                            <span
                              className={`${
                                hover === "cancelarchiverequest"
                                  ? "inter-500"
                                  : "inter-400"
                              }`}
                            >
                              Cancel Archive Request
                            </span>
                          </div>
                        ) : archive_request && is_archived ? (
                          <div
                            className={`p-2 border-bottom ${
                              hover === "unarchive" ? "bg-gray-50" : ""
                            }`}
                            onMouseEnter={() => setHover("unarchive")}
                            onMouseLeave={() => setHover("")}
                            onClick={() => {
                              setThisPropertyId(id);
                              setThisPropertyName(property_name);
                              setUnarchiveModal(true);
                            }}
                          >
                            <span
                              className={`${
                                hover === "unarchive"
                                  ? "inter-500"
                                  : "inter-400"
                              }`}
                            >
                              Unarchive Property
                            </span>
                          </div>
                        ) : (
                          <div
                            className={`p-2 border-bottom ${
                              hover === "archive" ? "bg-gray-50" : ""
                            }`}
                            onMouseEnter={() => setHover("archive")}
                            onMouseLeave={() => setHover("")}
                            onClick={() => {
                              setThisPropertyId(id);
                              setThisPropertyName(property_name);
                              setArchiveModal(true);
                            }}
                          >
                            <span
                              className={`${
                                hover === "archive" ? "inter-500" : "inter-400"
                              }`}
                            >
                              Archive Property
                            </span>
                          </div>
                        )}
                      </Card>
                    </div>
                  </div>
                )}
              </div>
              <div className="body-text2 mb-2">
                <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Number of Flats: {flats}</span>
                </Card.Text>
                <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Layout Types: {layout_types ?? "-"}</span>
                </Card.Text>
                {location ? (
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">Location: {location}</span>
                  </Card.Text>
                ) : (
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">
                      Location:
                      {city + ", " + state + " - " + pincode + ", " + country}
                    </span>
                  </Card.Text>
                )}
              </div>
            </div>
            {allFunction && (
              <>
                {inspirations?.length > 0 ? (
                  !display && (
                    <div
                      className="text-primary-500 inter-500 body-text3 text-end"
                      style={{ cursor: "pointer" }}
                      onClick={() => setDisplay(true)}
                    >
                      See Inspirations Created ({inspirations?.length}){" "}
                      <IoIosArrowDown size={16} color="black" />
                    </div>
                  )
                ) : (
                  <div
                    className="inter-500 body-text3 text-end"
                    style={{ color: "#2E3134" }}
                  >
                    No Inspirations Created
                  </div>
                )}
              </>
            )}
          </Card.Body>
        </Col>
      </Row>
      <div className={`px-3 py-2 ${display ? "d-block" : "d-none"}`}>
        <div className="d-flex justify-content-between">
          <div className="text-gray-900 inter-400 mt-2 mb-1">
            Inspirations Made By:
          </div>
          <div className="cursor-pointer" onClick={() => setDisplay(false)}>
            <IoIosArrowUp size={16} color="black" />
          </div>
        </div>
        <Row className="row-cols-3">
          {inspirations?.map((item, index) => (
            <Col key={index} className="p-2">
              <ProjectCard
                {...item}
                id={item.id}
                project_name={item.project_name}
                image={item.rooms[0]?.media[0]?.file}
                architect={item?.company_name}
                boxShadow="none"
                imgHeight="150px"
              />
            </Col>
          ))}
        </Row>
        <div
          className="text-primary-500 inter-500 body-text2 text-end px-2 cursor-pointer"
          onClick={() => navigate(`/builder/inspirations/${slug}`)}
        >
          View All ({inspirations.length})
        </div>
      </div>
    </Card>
  );
}

export default PropertyCard;
