import { combineReducers } from "@reduxjs/toolkit";
import utilitiesReducer from "../reducer/utilitySlice";
import authReducer from "../reducer/authSlices";
import projectReducer from "../reducer/projectSlice";
import locationReducer from "../reducer/locationSlice"

const rootReducer = combineReducers({
  utilities: utilitiesReducer,
  auth: authReducer,
  project: projectReducer,
  location:locationReducer
});

export default rootReducer;
