import React, { useEffect, useRef, useState, useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import RangeSlider from "components/Forms/RangeSlider";
import ReactSelect from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaCircleMinus, FaXmark } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";

import { notify } from "utilities/notifications/Notify";
import { formatNumber } from "utilities/numberFormatter";
import AddPropertyModal from "./AddPropertyModal";
import LoadingOverlay from "components/General/LoadingOverlay";
import DropFileInputProject from "components/DragInput/DropFileInputProject";
import AddProductsModal from "./AddProductsModal";
import * as authActions from "reduxFiles/actions/authActions";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";

function AddProject() {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const { propertyId } = useParams();
  const { status } = useParams();
  const user = useSelector((state) => state.auth.user);

  const projectInfoRef = useRef();
  const [brandInfo, setBrandInfo] = useState(null);
  const [architectInfo, setArchitectInfo] = useState(null);
  const [projectDetails, setProjectDetails] = useState({});
  const defaultAddProperty = { id: "add", property_name: "Add New Property" };
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [propertyName, setPropertyName] = useState("");
  const defaultLayouts = [
    { id: 1, layout: "1BHK" },
    { id: 2, layout: "2BHK" },
    { id: 3, layout: "2.5BHK" },
    { id: 4, layout: "3BHK" },
    { id: 5, layout: "3.5BHK" },
    { id: 6, layout: "4BHK" },
    { id: 7, layout: "5BHK" },
  ];
  const [availableLayouts, setAvailableLayouts] = useState([]);
  const projectSegments = [
    {
      id: 1,
      label: "Affordable Residential (₹500 to ₹1,500 per sq. ft.)",
      value: "AFFORDABLE_RESIDENTIAL",
    },
    {
      id: 2,
      label: "Mid-Range Residential (₹1,500 to ₹3,000 per sq. ft.)",
      value: "MID_RANGE_RESIDENTIAL",
    },
    {
      id: 3,
      label: "Luxury Residential (₹3,000 to ₹8,000 per sq. ft.)",
      value: "LUXURY_RESIDENTIAL",
    },
    {
      id: 4,
      label: "Ultra-Luxury Residential (₹8,000 per sq. ft. and above)",
      value: "ULTRA_LUXURY_RESIDENTIAL",
    },
    {
      id: 5,
      label: "Budget Commercial (₹1,500 to ₹2,500 per sq. ft.)",
      value: "BASIC_COMMERCIAL",
    },
    {
      id: 6,
      label: "Mid-Range Commercial (₹2,500 to ₹4,000 per sq. ft.)",
      value: "MID_RANGE_COMMERCIAL",
    },
    {
      id: 7,
      label: "High-End Commercial (₹4,000 per sq. ft. and above)",
      value: "HIGH_END_COMMERCIAL",
    },
  ];
  const projectStatuses = [
    { id: 1, label: "Design Phase Completed", value: "DESIGN_PHASE_COMPLETED" },
    { id: 2, label: "Execution In Progress ", value: "EXECUTION_IN_PROGRESS" },
    {
      id: 3,
      label: "Execution Phase Completed ",
      value: "EXECUTION_PHASE_COMPLETED",
    },
  ];

  const projectThemes = [
    { value: "modern", label: "Modern" },
    { value: "contemporary", label: "Contemporary" },
    { value: "art_moderne", label: "Art Moderne" },
    { value: "mid_century", label: "Mid-Century" },
    { value: "minimalist", label: "Minimalist" },
    { value: "scandinavian", label: "Scandinavian" },
    { value: "shabby_chic", label: "Shabby Chic" },
    { value: "eclectic", label: "Eclectic" },
    { value: "industrial", label: "Industrial" },
    { value: "farmhouse", label: "Farmhouse" },
    { value: "art_deco", label: "Art Deco" },
    { value: "boho", label: "Boho – Bohemian" },
    { value: "coastal", label: "Coastal" },
    { value: "french_country", label: "French Country" },
    { value: "hollywood_glam", label: "Hollywood Glam" },
    { value: "japandi", label: "Japandi" },
    { value: "mediterranean", label: "Mediterranean" },
    { value: "asian_decor", label: "Asian Décor" },
    { value: "rustic", label: "Rustic" },
    { value: "traditional", label: "Traditional" },
    { value: "transitional", label: "Transitional" },
    { value: "maximalist", label: "Maximalist" },
    { value: "1970s_revival", label: "1970s Revival" },
    { value: "english_country", label: "English Country" },
    { value: "cottagecore", label: "Cottagecore" },
    { value: "grandmillennial", label: "Grandmillennial" },
    { value: "moroccan", label: "Moroccan" },
  ];

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedRoomtypes, setSelectedRoomtypes] = useState([]);
  const [roomsToDisplay, setRoomsToDisplay] = useState(null);
  const [roomtypeIndex, setRoomTypeIndex] = useState(0);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [customProperty, setCustomProperty] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  let [selectedLayout, setSelectedLayout] = useState("");
  // for modal (sidebar rooms)
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: null,
    prevArrow: null,
  };

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response?.data) {
        setProperties([defaultAddProperty, ...response.data]);
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (customProperty) {
      setProperties((prev) => [...prev, customProperty]);
    }
  }, [customProperty]);

  const fetchPropertyById = async (propertyId) => {
    try {
      const response = await authActions.fetchPropertyById(propertyId);
      // console.log(response, "response property");
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchRoomTypes = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      console.log(response?.data);
      if (response.data) {
        setRoomTypes(response?.data);
        const masterBedroom = response.data?.filter(
          (item) => item?.title?.toLowerCase() === "master bedroom"
        )[0];
        console.log(masterBedroom);
        if (masterBedroom) {
          addSelectedRoomtype([masterBedroom]);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const fetchArchitectByUser = async () => {
    try {
      const response = await authActions.fetchArchitectByUser(user.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setArchitectInfo(response.data?.results[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandByUser = async () => {
    try {
      const response = await authActions.fetchBrandByUser(user?.id);
      if (response.data) {
        if (response.data?.results[0]) {
          setBrandInfo(response.data?.results[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addSelectedRoomtype = (types) => {
    setProcessing(true);
    if (selectedRoomtypes.length < types.length) {
      if (types?.length > 0) {
        const item = types[types.length - 1];
        const newRoomtype = {
          id: item.id,
          title: item.title,
          image: item.image,
          rooms: [],
        };
        setSelectedRoomtypes([...selectedRoomtypes, newRoomtype]);
      }
    } else {
      const rtypesIds = new Set(types.map((item) => item.id));
      console.log(selectedRoomtypes);
      const temp = [];
      for (const item of selectedRoomtypes) {
        if (rtypesIds.has(item.id)) {
          temp.push(item);
        }
      }
      console.log(rtypesIds, temp);
      setSelectedRoomtypes(temp);
    }
    setProcessing(false);
  };

  const onSelectRoomFiles = (files, index = roomtypeIndex) => {
    setProcessing(true);
    const currentFiles = new Set(
      selectedRoomtypes[index].rooms.map((item) => item.file)
    );
    console.log(currentFiles);
    const result = files.filter((file) => !currentFiles.has(file));
    console.log(result);
    const temp = result?.map((item) => {
      return { file: item, products: [] };
    });
    console.log(temp);
    setSelectedRoomtypes([
      ...selectedRoomtypes.slice(0, index),
      {
        ...selectedRoomtypes[index],
        rooms: [...selectedRoomtypes[index].rooms, ...temp],
      },
      ...selectedRoomtypes.slice(index + 1),
    ]);
    setProcessing(false);
  };

  const onRemoveRoomFile = (index) => {
    const temp = [...selectedRoomtypes];

    const updatedRoomtype = {
      ...temp[roomtypeIndex],
      rooms: [
        ...temp[roomtypeIndex].rooms.slice(0, index),
        ...temp[roomtypeIndex].rooms.slice(index + 1),
      ],
    };

    const updatedRoomtypes = [
      ...temp.slice(0, roomtypeIndex),
      updatedRoomtype,
      ...temp.slice(roomtypeIndex + 1),
    ];

    setSelectedRoomtypes(updatedRoomtypes);
  };

  const handleGoToPreview = () => {
    setCurrentStep(3);
  };

  const handleFileButtonClick = (roomId) => {
    if (roomId) {
      document.getElementById(`fileInput-${roomId}`).click();
    } else {
      // document.getElementById("fileInput").click();
    }
  };

  const handleProjectDetails = () => {
    const formData = new FormData(projectInfoRef.current);
    let isError;
    let data = {};

    const requiredFields = [
      "project_name",
      "property_name",
      "bhk",
      // "location",
      "project_area",
    ];

    // Iterate through FormData to capture and validate inputs
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`); // Debugging each field

      // Check if the current field is mandatory
      if (requiredFields.includes(key) && !value.trim()) {
        isError = true;
        console.warn(`Missing required value for: ${key}`); // Log missing fields
      }

      // Add the value (trimmed) to the data object
      data[key] = value.trim();
    });

    if (isError) {
      notify("Please enter the required fields!");
      return;
    }
    console.log("Captured Data:", data);
    setSelectedLayout(data.bhk);
    setProjectDetails(data);
    setCurrentStep(2);
  };

  const handleProjectSubmit = async () => {
    const costUnit = projectDetails?.cost_unit || "Lakhs";

    // Get the numeric value from the input field
    let projectCost = projectDetails.project_cost;

    // const projectData = { project: projectDetails, rooms: rooms };

    let data = {
      is_new_project: true,
      // architect: user.id,
      project_property: customProperty?.id
        ? customProperty.id
        : selectedProperty.id,
      project_name: projectDetails.project_name,
      project_type: projectDetails.project_property.project_type_data?.Id,
      project_description: projectDetails.project_description,
      bhk: projectDetails.bhk,
      block: projectDetails.block,
      flat_area: projectDetails.project_area,
      country: projectDetails.country,
      state: projectDetails.state,
      city: projectDetails.city,
      pincode: projectDetails.pincode,
      // location: projectDetails.location,
      // project_cost_minimum: parseFloat(projectDetails.project_cost_minimum),
      // project_cost_maximum: parseFloat(projectDetails.project_cost_maximum),
      project_cost_minimum: parseFloat(projectCost), // Include the unit here
      project_cost_maximum: parseFloat(projectCost),
      project_cost_unit: costUnit,
    };

    if (projectDetails.project_year) {
      data["project_year"] = projectDetails.project_year;
    }

    if (architectInfo?.id) {
      data = { ...data, architect: architectInfo.id };
    } else if (brandInfo?.id) {
      data = { ...data, brand: brandInfo.id };
    }
    if (data?.architect || data?.brand) {
      console.log(data);
      const formData = new FormData();
      let count = 1;
      selectedRoomtypes.forEach((roomType, i) => {
        selectedRoomtypes[i]?.rooms.forEach((room, j) => {
          formData.append(`fileInput-${count}`, room.file);
          count += 1;
        });
      });
      formData.append("project_data", JSON.stringify(data));
      formData.append("room_types", JSON.stringify(selectedRoomtypes));
      formData.append("user", user.id);
      try {
        const response = await authActions.createProject(formData);
        if (response.status === 201) {
          notify("Project Created!", "success");
          navigate("/");
        }
        console.log(formData);
      } catch (error) {
        notify(error.response.data.msg || "An error occured!", "error", 1);
      }
    } else {
      notify(`Failed to identify this ${user?.user_type ?? "user"}`, "error");
    }
  };

  useEffect(() => {
    let temp = [];
    if (selectedRoomtypes?.length > 0) {
      selectedRoomtypes.forEach((rt) => {
        if (rt?.rooms.length > 0) {
          rt?.rooms.forEach((r) => {
            temp.push(r);
          });
        }
      });
    }
    setRoomsToDisplay(temp);
  }, [selectedRoomtypes]);

  useEffect(() => {
    if (status) {
      setCurrentStep(2);
    }
  }, [status]);

  useEffect(() => {
    if (propertyId && properties?.length > 0) {
      const temp = {
        ...projectDetails,
        property: propertyId,
        // property: { ...projectDetails?.property, id: propertyId },
      };
      // console.log(temp, "temp in useEffect");
      setProjectDetails(temp);
      setSelectedProperty(properties.find((item) => item?.id === propertyId));
    } else {
      setProjectDetails({});
    }
  }, [properties, propertyId]);

  useEffect(() => {
    if (selectedProperty) {
      if (selectedProperty.layout_type_data) {
        setAvailableLayouts(selectedProperty.layout_type_data);
      } else {
        setAvailableLayouts(defaultLayouts);
      }
    } else {
      setAvailableLayouts(defaultLayouts);
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (user?.user_type === "architect") {
      fetchArchitectByUser();
    } else if (user?.user_type === "brand") {
      fetchBrandByUser();
    }
  }, [user]);

  useEffect(() => {
    fetchProperties();
    fetchRoomTypes();
    fetchPropertyById(propertyId);
  }, []);

  console.log(selectedRoomtypes, "selectedRoomtypes");
  console.log(
    selectedRoomtypes[roomtypeIndex],
    "selectedRoomtypes[roomtypeIndex]"
  );

  return (
    <>
      <AddProductsModal
        show={showProductsModal}
        setShow={setShowProductsModal}
        selectedRoomtypes={selectedRoomtypes}
        setSelectedRoomtypes={setSelectedRoomtypes}
        roomtypeIndex={roomtypeIndex}
        activeRoomIndex={activeRoomIndex}
        setActiveRoomIndex={setActiveRoomIndex}
      />
      <Container
        fluid
        className="mt-5 pt-4 px-0 d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "60vh" }}
      >
        <div className="w-100 bg-info d-flex justify-content-center align-items-center">
          <h1 className="h2 text-primary inter-600 py-3">Add a new Project</h1>
        </div>
        {processing && (
          <LoadingOverlay isLoading={processing} setLoading={setProcessing} />
        )}
        <div
          className="d-flex flex-column flex-lg-row my-md-4"
          style={large ? { maxWidth: 1000, width: "100%" } : { width: "100%" }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ minWidth: 250 }}
          >
            <div className="d-flex flex-lg-column mt-2 mt-md-4 mt-lg-3 ml-2 mb-1 px-3 px-lg-0">
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${
                    currentStep === 1
                      ? "bg-black text-white"
                      : currentStep === 2 || currentStep === 3
                      ? "bg-success text-white"
                      : "bg-gray-25"
                  }`}
                >
                  {currentStep === 1 ? "1" : <FaCheck size={12} />}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${
                    currentStep !== 1 ? "text-success" : ""
                  }`}
                >
                  Project Details
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${
                    currentStep === 2
                      ? "bg-black text-white"
                      : currentStep === 3
                      ? "bg-success text-white"
                      : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
                >
                  {currentStep === 1 || currentStep === 2 ? (
                    "2"
                  ) : (
                    <FaCheck size={12} />
                  )}
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${
                    currentStep !== 1 && currentStep !== 2
                      ? "text-success"
                      : currentStep === 2
                      ? ""
                      : "text-gray-300"
                  }`}
                >
                  Project Images
                </div>
              </div>
              <div className="d-flex align-items-center mb-4 me-2">
                <div
                  className={`p-1 text-center rounded-btn ${
                    currentStep === 3
                      ? "bg-black text-white"
                      : "text-gray-300 bg-gray-25 border border-gray-100"
                  }`}
                >
                  3
                </div>
                <div
                  className={`ms-2 inter-500 body-text1 ${
                    currentStep === 3 ? "text-gray-900" : "text-gray-300"
                  }`}
                >
                  Project Review
                </div>
              </div>
            </div>
          </div>
          <div className="p-3">
            <div className="p-3 border" style={{ borderRadius: 6 }}>
              {currentStep === 1 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">{currentStep}. Project Details</h4>
                  <div className="mt-4">
                    <Form ref={projectInfoRef}>
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Project Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          name="project_name"
                          defaultValue={projectDetails?.project_name}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Property Name<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        {projectDetails && properties?.length > 0 ? (
                          <ReactSelect
                            name="project_property"
                            isMulti={false}
                            options={properties}
                            getOptionLabel={(e) => e.property_name}
                            getOptionValue={(e) => e.id}
                            value={selectedProperty}
                            onChange={(selectedOption) => {
                              if (selectedOption) {
                                setProjectDetails({
                                  ...projectDetails,
                                  property_name: selectedOption.property_name, // Use the selected option's property
                                });
                                setPropertyName(selectedOption.property_name);

                                if (selectedOption.id !== "add") {
                                  setShowPropertyModal(false);
                                  setSelectedProperty(selectedOption); // Update selected property
                                } else {
                                  setShowPropertyModal(true);
                                  setSelectedProperty(""); // Clear selected property
                                }
                              } else {
                                // Clear the selection
                                setSelectedProperty("");
                                setProjectDetails({
                                  ...projectDetails,
                                  property_name: "",
                                });
                              }
                            }}
                            filterOption={(option, inputValue) => {
                              if (option.value === "add") {
                                return true;
                              }
                              const propertyName = option.label || "";
                              return propertyName
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                            isClearable
                            placeholder="Select Property"
                            className="body-text2"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: "1px solid #BDC1C6",
                                borderRadius: 3,
                              }),
                              option: (baseStyles, state) => ({
                                ...baseStyles,
                                borderBottom: "1px solid #DADCE0",
                                backgroundColor:
                                  state.data.id === "add"
                                    ? "#FDF3F3"
                                    : state.isFocused
                                    ? "#f0f0f0"
                                    : baseStyles.backgroundColor,
                                color:
                                  state.data.id === "add"
                                    ? "#C53D3D"
                                    : baseStyles.color,
                                fontWeight:
                                  state.data.id === "add"
                                    ? 500
                                    : baseStyles.fontWeight,
                              }),
                            }}
                          />
                        ) : (
                          <div>
                            Please wait while we area loading the properties...
                          </div>
                        )}
                      </Form.Group>
                      <div className="d-flex w-100 mb-3">
                        <Form.Group className="w-50">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Layout<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Select
                            required
                            className="py-2 body-text2"
                            type="text"
                            placeholder="Select Layout"
                            name="bhk"
                            defaultValue={
                              projectDetails?.bhk || selectedLayout || ""
                            }
                            onChange={(e) => {
                              setProjectDetails({
                                ...projectDetails,
                                bhk: e.target.value,
                              });
                              setSelectedLayout(e.target.value);
                            }}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {propertyName &&
                              availableLayouts?.map((type, index) => (
                                <option key={index} value={type.layout}>
                                  {type.layout}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="w-50 ms-3">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Flat Area<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>

                          <Form.Select
                            required
                            className="py-2 body-text2"
                            type="text"
                            placeholder="Select Layout"
                            name="project_area"
                            defaultValue={projectDetails?.project_area}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                project_area: e.target.value,
                              })
                            }
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {availableLayouts[0]?.area &&
                              projectDetails.bhk &&
                              availableLayouts?.map((type, index) => (
                                <option key={index} value={type.area}>
                                  {type?.area ? `${type.area} sq.ft.` : ""}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="d-flex w-100 mb-3">
                        <Form.Group className="w-50">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Project Segment
                          </Form.Label>
                          <Form.Select
                            className="py-2 body-text2"
                            type="text"
                            placeholder="Select Layout"
                            name="property_segment"
                            value={projectDetails?.property_segment}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {projectSegments?.map((type, index) => (
                              <option key={index} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="w-50 ms-3">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            BOQ Cost
                          </Form.Label>
                          <InputGroup
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <Form.Control
                              required
                              className="py-2 body-text2 border-0"
                              type="number"
                              name="project_cost"
                              placeholder="Enter Amount"
                              value={projectDetails?.project_cost}
                              min={0}
                              onChange={(e) =>
                                setProjectDetails({
                                  ...projectDetails,
                                  project_cost: e.target.value,
                                })
                              }
                              style={{
                                border: "1px solid #BDC1C6",
                                borderRadius: 3,
                                width: "60%",
                              }}
                            />
                            <Form.Select
                              className="body-text2 border-0"
                              style={{ borderLeft: "1px solid #BDC1C6" }}
                              name="cost_unit"
                              value={projectDetails?.cost_unit} // Ensure unit is set
                              onChange={(e) =>
                                setProjectDetails({
                                  ...projectDetails,
                                  cost_unit: e.target.value,
                                })
                              }
                            >
                              <option value="Lakhs">Lakhs</option>
                              <option value="Crores">Crores</option>
                            </Form.Select>
                          </InputGroup>
                        </Form.Group>
                      </div>
                      <div className="d-flex w-100 mb-3">
                        <Form.Group className="w-50">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Project Status
                          </Form.Label>
                          <Form.Select
                            className="py-2 body-text2"
                            type="text"
                            name="project_status"
                            defaultValue={projectDetails?.project_status}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          >
                            <option value="">Select</option>
                            {projectStatuses?.map((type, index) => (
                              <option key={index} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group className="w-50 ms-3">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Project Year
                          </Form.Label>
                          <Form.Control
                            className="py-2 body-text2"
                            type="number"
                            name="project_year"
                            placeholder="2010"
                            defaultValue={projectDetails?.project_year}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          />
                        </Form.Group>
                      </div>
                      <Form.Group className="mb-3 d-flex">
                        <div className="w-100">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Pincode<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className="py-2 body-text2"
                            type="text"
                            // onChange={(e) => getPincode(e.target.value)}
                            placeholder="Enter pincode"
                            name="pincode"
                            defaultValue={selectedProperty?.pincode}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          />
                        </div>

                        <div className="ms-2 w-100">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            Country<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            // disabled={projectDetails?.pincode !== ""}
                            className="py-2 body-text2"
                            type="text"
                            placeholder="Enter country"
                            name="country"
                            defaultValue={selectedProperty?.country}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          />
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3 d-flex">
                        <div className="w-100">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            City<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className="py-2 body-text2"
                            // disabled={projectDetails?.pincode !== ""}
                            type="text"
                            placeholder="Enter city"
                            name="city"
                            defaultValue={selectedProperty?.city}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          />
                        </div>

                        <div className="ms-2 w-100">
                          <Form.Label className="mb-1 body-text2 inter-500">
                            State<sup style={{ color: "red" }}>*</sup>
                          </Form.Label>
                          <Form.Control
                            required
                            className="py-2 body-text2"
                            // disabled={projectDetails?.pincode !== ""}
                            type="text"
                            placeholder="Enter state"
                            name="state"
                            defaultValue={selectedProperty?.state}
                            style={{
                              border: "1px solid #BDC1C6",
                              borderRadius: 3,
                            }}
                          />
                        </div>
                      </Form.Group>
                      {/* <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Location<sup style={{ color: "red" }}>*</sup>
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          type="text"
                          placeholder="Enter location"
                          name="location"
                          defaultValue={selectedProperty?.location}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        />
                      </Form.Group> */}

                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Project Theme
                        </Form.Label>

                        <Form.Select
                          required
                          className="py-2 body-text2"
                          name="project_theme"
                          defaultValue={projectDetails?.project_theme || ""}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                          }}
                        >
                          {/* Default option */}
                          <option value="" disabled>
                            Select a theme
                          </option>

                          {/* Map over the array to create options */}
                          {projectThemes?.map((theme, index) => (
                            <option key={index} value={theme.value}>
                              {theme.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="mb-1 body-text2 inter-500">
                          Project Description
                        </Form.Label>
                        <Form.Control
                          required
                          className="py-2 body-text2"
                          as="textarea"
                          rows={6}
                          name="project_description"
                          placeholder="Describe the project..."
                          defaultValue={projectDetails?.project_description}
                          style={{
                            border: "1px solid #BDC1C6",
                            borderRadius: 3,
                            resize: "none",
                          }}
                        />
                      </Form.Group>
                    </Form>
                    <div className="d-flex">
                      <Button
                        className="d-flex align-items-center text-white body-text2 me-2"
                        style={{ borderRadius: 3 }}
                        onClick={handleProjectDetails}
                      >
                        Continue
                      </Button>
                      <Button
                        className="bg-transparent border-gray-200 text-gray-900 body-text2"
                        style={{ borderRadius: 3 }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div style={large ? { minWidth: 600 } : {}}>
                  <h4 className="inter-600">
                    {currentStep}. Add Project Images
                  </h4>
                  {!loading ? (
                    <div style={{ minHeight: 300 }}>
                      <div className="my-3">
                        <ReactSelect
                          name="type_of_space"
                          defaultValue={[
                            roomTypes.filter(
                              (item) =>
                                item.title.toLowerCase() === "master bedroom"
                            )[0],
                          ]}
                          options={roomTypes}
                          isMulti
                          getOptionLabel={(e) => e.title}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => addSelectedRoomtype(e)}
                          isClearable
                          placeholder="Select type of space"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: "#BDC1C6",
                            }),
                          }}
                        />
                      </div>
                      <div
                        className="d-flex align-items-start mb-3 flex-wrap"
                        style={{ width: "100%" }}
                      >
                        {selectedRoomtypes.length > 0 &&
                          selectedRoomtypes?.map((item, index) => {
                            return (
                              <div key={index}>
                                <Button
                                  className={`cursor-pointer text-center body-text2 inter-500 ${
                                    index == roomtypeIndex
                                      ? ""
                                      : "bg-white border-dark text-dark"
                                  } me-2`}
                                  style={{
                                    flexBasis: "calc(33.33% - 10px)",
                                    whiteSpace: "nowrap",
                                    marginBottom: 10,
                                    borderRadius: 3,
                                  }}
                                  onClick={() => setRoomTypeIndex(index)}
                                >
                                  {item.title} ({item.rooms.length})
                                </Button>
                              </div>
                            );
                          })}
                      </div>
                      {selectedRoomtypes.length > 0 &&
                        selectedRoomtypes?.map((item, index) => {
                          return (
                            <div key={index} className="mb-3">
                              <DropFileInputProject
                                allowMultiple
                                allowOnlyImages
                                className={`bg-white border-dark ${
                                  index === roomtypeIndex ? "d-block" : "d-none"
                                }`}
                                files={() =>
                                  item?.rooms?.map((item) => item.file)
                                }
                                onFileChange={(files) => {
                                  onSelectRoomFiles(files);
                                }}
                              />
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: 240 }}
                    >
                      <Spinner />
                    </div>
                  )}
                  {selectedRoomtypes[roomtypeIndex]?.rooms.length > 0 && (
                    <div>
                      <Row
                        xs={2}
                        sm={2}
                        md={3}
                        lg={3}
                        xl={4}
                        className="pt-2 pb-2"
                      >
                        {selectedRoomtypes[roomtypeIndex]?.rooms?.map(
                          (item, index) => {
                            return (
                              <Col key={index} className="p-2 mb-2">
                                <div
                                  className="d-flex flex-column bg-white w-100 h-100 border border-grey position-relative"
                                  style={{ height: 160, borderRadius: 3 }}
                                >
                                  <div className="d-flex justify-content-center align-items-center flex-grow">
                                    {item?.file && (
                                      <Image
                                        src={
                                          item?.file
                                            ? URL.createObjectURL(item?.file)
                                            : null
                                        }
                                        alt=""
                                        className="img-fluid w-100"
                                        style={{ objectFit: "cover" }}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className="cursor-pointer text-center text-primary text-decoration-underline body-text2 py-1"
                                    onClick={() => {
                                      setRoomTypeIndex(roomtypeIndex);
                                      setActiveRoomIndex(index);
                                      setShowProductsModal(!showProductsModal);
                                    }}
                                  >
                                    {item.products.length} Products Added
                                  </div>
                                  <div
                                    className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden position-absolute"
                                    style={{
                                      top: -16,
                                      right: -16,
                                      width: 40,
                                      height: 40,
                                    }}
                                  >
                                    <div
                                      className="d-flex justify-content-center align-items-center bg-white rounded-circle cursor-pointer"
                                      onClick={() => onRemoveRoomFile(index)}
                                    >
                                      <MdDelete
                                        className="text-primary cursor-pointer"
                                        size={28}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </div>
                  )}
                  <div className="mt-4 d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={() => {
                        handleGoToPreview();
                      }}
                    >
                      Go to Preview
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(1)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
              {currentStep === 3 && (
                <div style={large ? { width: 600 } : {}}>
                  <h4 className="inter-600">{currentStep}. Project Preview</h4>
                  <Slider className="w-100" {...settings}>
                    {roomsToDisplay?.map((room, index) => {
                      return (
                        <div
                          key={index}
                          className="position-relative overflow-hidden"
                        >
                          <div
                            className="bg-gray-50 rounded p-2 position-absolute"
                            style={{ top: 16, left: 16 }}
                          >
                            {room?.products?.length} Products Added
                          </div>
                          <div className="w-100">
                            <img
                              key={index}
                              className="img-fluid w-100 rounded-3"
                              src={URL.createObjectURL(room.file)}
                              style={{
                                height: xlarge
                                  ? 500
                                  : large
                                  ? 500
                                  : medium
                                  ? 400
                                  : small
                                  ? 300
                                  : 200,
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                  <div className="mb-4">
                    {!status && (
                      <div className="mb-4 body-text2">
                        <div className="d-flex justify-content-between">
                          <h4 className="fw-bold">Project Details</h4>
                          <div
                            className="cursor-pointer"
                            onClick={() => setCurrentStep(1)}
                            style={{ width: "24px" }}
                          >
                            <CiEdit size={24} />
                          </div>
                        </div>
                        <div className="my-1">
                          Project Name: {projectDetails.project_name}
                        </div>
                        <div className="my-1">
                          Project Layout: {projectDetails.bhk}
                        </div>
                        <div className="my-1">
                          Project Year: {projectDetails.project_year}
                        </div>
                        <div className="my-1">
                          Flat Area: {projectDetails.project_area}
                        </div>
                        {projectDetails?.project_property?.location && (
                          <div className="my-1">
                            Location: {projectDetails.project_property.location}
                          </div>
                        )}
                        {projectDetails?.project_property?.builder_data && (
                          <div className="my-1">
                            Builder:{" "}
                            {projectDetails.builder_data?.name
                              ? projectDetails.builder_data?.name
                              : "-"}
                          </div>
                        )}
                        <div className="my-1">Architect: {user.full_name}</div>
                        <div className="my-1">
                          Project Description:{" "}
                          {projectDetails?.project_description
                            ? projectDetails?.project_description
                            : "-"}
                        </div>
                      </div>
                    )}
                    {/* <Slider className="w-100" {...settings}>
                      {roomsToDisplay?.map((room, index) => {
                        return (
                          <div
                            key={index}
                            className="position-relative overflow-hidden"
                          >
                            <div
                              className="bg-gray-50 rounded p-2 position-absolute"
                              style={{ top: 16, left: 16 }}
                            >
                              {room?.products?.length} Products Added
                            </div>
                            <div className="w-100">
                              <img
                                key={index}
                                className="img-fluid w-100 rounded-3"
                                src={URL.createObjectURL(room.file)}
                                style={{
                                  height: xlarge ? 500 : large ? 500 : medium ? 400 : small ? 300 : 200
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </Slider> */}
                  </div>
                  <div className="d-flex">
                    <Button
                      className="d-flex align-items-center text-white body-text2 me-2"
                      style={{ borderRadius: 3 }}
                      onClick={handleProjectSubmit}
                    >
                      Save
                    </Button>
                    <Button
                      className="bg-transparent border-gray-200 text-gray-900 body-text2"
                      style={{ borderRadius: 3 }}
                      onClick={() => setCurrentStep(2)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddPropertyModal
          show={showPropertyModal}
          setShow={setShowPropertyModal}
          setCustomProperty={setCustomProperty}
          setSelectedProperty={setSelectedProperty}
        />
      </Container>
    </>
  );
}

export default AddProject;
