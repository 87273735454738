import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Stack } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import { notify } from "utilities/notifications/Notify";
import * as authActions from "reduxFiles/actions/authActions";
import { CgScreenMirror } from "react-icons/cg";
import { BsBuildingAdd } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import propertyImg from "../../assets/products/post-placeholder.webp";
import { RiEditLine } from "react-icons/ri";

function ArchitectProjectCard({ project, setProjects }) {
  const xsmall = useMediaQuery({ query: "(max-width: 576px)" });
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the path from the URL
  const path = location.pathname;

  // console.log(project, "project");
  const user = useSelector((state) => state.auth.user);

  const [roomsToDisplay, setRoomsToDisplay] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await authActions.fetchProjectsByArchitectUser(user.id);
      console.log(response, "actual products");
      if (response.data) {
        setProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow-spaces`}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowLeft size={25} color="black" /> */}
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow-spaces`}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowRight size={25} color="black" /> */}
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: xsmall ? 1 : 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const submitInspiration = async () => {
    try {
      const response = await authActions.submitInspiration(project.id, {
        status: "Pending Approval",
      });
      if (response.data) {
        notify("Inspiration submitted!", "success");
        fetchProjects();
      } else {
        notify(
          response.error.response.data.msg || "An error occured!",
          "error",
          1
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let temp = [];
    project?.rooms?.forEach((room, i) => {
      room?.media?.forEach((media, j) => {
        temp.push(media);
      });
    });
    // console.log(project?.property_data?.property_name, temp);
    setRoomsToDisplay(temp);
  }, [project]);

  return (
    <Card className="d-flex p-2 p-lg-3 flex-column text-start my-4 shadow-none">
      <div className="d-flex">
        <Card.Body className="w-100 p-0 p-1">
          <div className="d-flex mb-md-2">
            <Card.Img
              className="bg-white border rounded mb-2 mb-md-0 me-3 me-md-3"
              variant="top"
              src={project?.property_data?.property_img || propertyImg}
              // height={200}
              // style={{ width: !xsmall && "40%", objectFit: "cover" }}
              style={{
                width: 96,
                height: 96,
                objectFit: "cover",
                display: "block",
                margin: "auto",
              }}
            />
            <div className="w-100">
              <div className="d-flex flex-column flex-md-row justify-content-md-between mb-2 w-100 pt-2">
                <Card.Title className="d-flex h4 inter-700">
                  {project?.property_data?.property_name} /{" "}
                  {project?.property_data?.builder_data?.company_name} /{" "}
                  {project?.project_name}
                </Card.Title>
                <div className="d-flex gap-4 align-items-center">
                  <Card.Title
                    className="d-flex h4 inter-700 mb-0"
                    style={{
                      color:
                        project?.status === "Pending Approval"
                          ? "#FC7C05"
                          : project?.status === "Approved"
                          ? "#68C858"
                          : "#A53030",
                    }}
                  >
                    {project?.status}
                  </Card.Title>
                  <div
                    onClick={() =>
                      navigate(
                        `/${
                          path.includes("architect") ? "architect" : "brand"
                        }/editProject/${project?.id}`,
                        {
                          state: { project: project },
                        }
                      )
                    }
                  >
                    <RiEditLine size={24} className="cursor-pointer" />
                  </div>
                </div>
              </div>
              <Stack
                direction={!xsmall && "horizontal"}
                gap={2}
                className="mb-2"
              >
                {project?.recommended && (
                  <Badge
                    bg="info text-primary p-2"
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 30 }}
                  >
                    <CgScreenMirror size={20} className="me-1" />
                    <span className="body-text2"> Interiokeys Recommended</span>
                  </Badge>
                )}
                {project?.property_data?.new_property && (
                  <Badge
                    bg="info text-primary p-2"
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: 30 }}
                  >
                    <BsBuildingAdd size={20} className="me-1" />
                    <span className="body-text2"> New Property!</span>
                  </Badge>
                )}
              </Stack>
            </div>
          </div>

          <div>
            <Card.Text className="body-text2 mb-0">
              <span className="inter-600">Total Inspirations Created: </span>
              {project?.rooms?.length}
            </Card.Text>
            <Card.Text className="body-text2 mb-0">
              <span className="inter-600">Number of Flats: </span>
              {project?.property_data?.flats}
            </Card.Text>
            <Card.Text className="body-text2 mb-0">
              <span className="inter-600">Layout Types: </span>
              {project?.property_data?.layout_types ?? "-"}
            </Card.Text>
            <Card.Text className="body-text2 mb-0">
              <span className="inter-600">Project Type: </span>
              {project?.property_data?.project_type_data?.title}
            </Card.Text>
            {!project?.property_data?.pincode ? (
              <Card.Text className="body-text2 mb-0">
                <span className="inter-600">Location: </span>
                {project?.property_data?.location}
              </Card.Text>
            ) : (
              <Card.Text className="body-text2 mb-0">
                <span className="inter-600">Location: </span>
                {project?.property_data?.city}, {project?.property_data?.state}{" "}
                - {project?.property_data?.country}{" "}
                {project?.property_data?.pincode}
              </Card.Text>
            )}
          </div>
          {
            // project?.rooms?.length > 0
            roomsToDisplay?.length > 0 && (
              <div className="mt-3">
                <div style={{ width: xsmall ? 200 : 460 }}>
                  <div className="inter-600 mb-2">Your Inspirations:</div>
                  {roomsToDisplay?.length > 1 ? (
                    <Slider {...settings}>
                      {roomsToDisplay?.map((room, index) => {
                        return (
                          <div
                            key={index}
                            className="rounded-3 overflow-hidden pe-2"
                            style={{ width: 228, height: 150 }}
                          >
                            <img
                              className="img-fluid border rounded-3"
                              src={room.file || propertyImg}
                              alt="Space"
                              style={{
                                width: 228,
                                height: 150,
                                objectFit: "cover",
                              }}
                              // style={{ maxHeight: 150, minHeight: 150 }}
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div
                      className="border rounded-3 overflow-hidden"
                      style={{ width: 228, height: 150 }}
                    >
                      <img
                        className="img-fluid w-100"
                        src={roomsToDisplay[0].file || propertyImg}
                        alt="Space"
                        style={{ width: 228, height: 150, objectFit: "cover" }}
                        // style={{ maxHeight: 150, minHeight: 150 }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          }
          <div className="d-flex justify-content-end">
            {project.status === "Designing" && (
              <div className="ms-auto">
                <Button className="text-white" onClick={submitInspiration}>
                  Submit Inspirations
                </Button>
              </div>
            )}
          </div>
        </Card.Body>
      </div>
    </Card>
  );
}

export default ArchitectProjectCard;
