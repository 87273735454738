import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
} from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import HeroBanner from "./HeroBanner";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import BrandOnInspirations from "./BrandOnInspirations";

import {
  ArchitectsForDisplay,
  HowItWorks,
  InfiniteScroll,
  useScrollBottom,
} from "components";
import { Breakpoint } from "react-socks";
import TestimonialCardMobile from "components/Cards/TestimonialCardMobile";
import MobileProjectCard from "components/Cards/MobileProjectCard";
import { truncateText } from "utilities/truncateText";
import { all_properties } from "assets";
import SearchBarInspiration from "components/Navbar/SearchBarInspiration";
import { useSelector } from "react-redux";

const Home = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const { navbarToggle } = useSelector((state) => state.auth);
  const currentLocation = useSelector(
    (state) => state.location.currentLocation
  );

  const [brands, setBrands] = useState([]);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);
  const [projects, setProjects] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const bottom = useScrollBottom();
  const [noNextItem, setNoNextItem] = useState(true);
  const [properties, setProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState({
    id: 0,
    property_name: "All Properties",
    slug: "",
  });
  const [next, setNext] = useState("");
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const card_info = [
    {
      id: 1,
      title: "Real Inspirations",
      description:
        "You can choose from a variety of upcoming and completed interior projects by Interiokeys",
    },
    {
      id: 2,
      title: "No intermediaries",
      description:
        "Interiokeys maintains a direct connection between itself, the craftsmen, manufacturers and the end consumers.",
    },
    {
      id: 3,
      title: "Cost Reduction by 20-30%",
      description:
        "Ready-made designs and multiple brands working directly to reduce interior costs.",
    },
  ];

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadProjects = async (next) => {
    try {
      const response = await authActions.loadProjects(next);

      setCount(response?.count);
      setNext(response?.next);
      if (Array.isArray(response?.results)) {
        setProjects((prevProjects) => [...prevProjects, ...response?.results]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProperties = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchAllProperties();
      if (response.data) {
        setProperties(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const loadPaginatedProjects = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjects(
        `/project/projects/`,
        null,
        null,
        currentProperty.slug,
        null,
        null,
        null
      );
      if (response) {
        setNext(response.next);
        setCount(response.count);
        setProjects(response.results);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
    loadPaginatedProjects();
    fetchProperties();
  }, []);

  useEffect(() => {
    loadPaginatedProjects();
  }, [currentProperty]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowRight size={45} /> */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowLeft size={35} /> */}
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const slideLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -sliderRef.current.offsetWidth * 0.2,
        behavior: "smooth",
      });
    }
  };

  const slideRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: sliderRef.current.offsetWidth * 0.2,
        behavior: "smooth",
      });
    }
  };

  const handleLoadmore = async () => {
    if (next) {
      loadProjects(next);
    }
  };

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-center first_section_home_page"
        style={{
          minHeight: !small ? "40vh" : "40vh",
          padding: !small ? "26px 16px" : "0px",
        }}
      >
        <Container className={`p-0 ${!small ? "mt-3" : "mt-5"}`}>
          <div className="h1 px-lg-5 inter-600">
            Designing Interiors for City's Elite Properties!
          </div>
          <div className="text-gray-600 body-title inter-400 my-3">
            Explore high-quality designs created by expert designers using the
            best brand products for top properties all over India.
          </div>
          <div className="d-flex justify-content-center">
            <SearchBarInspiration
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
          {/* {card_info && (
            <HeroBanner items={recommendedProjects.slice(0, 3)} card_info={card_info} />
          )} */}
        </Container>
      </Container>
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-center"
        style={{
          minHeight: !small ? "90vh" : "100vh",
          padding: !small ? "26px 16px" : "0px",
        }}
      >
        <Container className={`p-0 ${!small ? "mt-3" : "mt-5"}`}>
          <div className="mb-3 d-block">
            <div className="h3 px-lg-5 inter-600">
              Top interior inspirations for{" "}
              <span className="text-primary">
                {currentProperty.property_name}
              </span>{" "}
              in {currentLocation}
            </div>
            <div
              className="d-flex align-items-center position-relative"
              style={{ gap: "16px", paddingBottom: "10px" }}
            >
              {/* Left Slide Button */}
              {small && (
                <Button
                  className="position-absolute z-index-top rounded-circle"
                  style={{
                    zIndex: 10,
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    border: "none",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={slideLeft}
                >
                  <MdKeyboardArrowLeft size={20} />
                </Button>
              )}
              {/* All Properties Card */}
              <div
                ref={sliderRef}
                className="d-flex flex-nowrap overflow-auto gap-3 py-2 px-4"
              >
                <div
                  className={`d-flex flex-column align-items-center body-text3 nav-link cursor-pointer`}
                  onClick={() => {
                    setCurrentProperty({
                      id: 0,
                      property_name: "All Properties",
                    });
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      width: "132px",
                      height: "124px",
                    }}
                  >
                    <Image
                      src={all_properties}
                      alt={"All Properties"}
                      className={`small-img`}
                      width="132px"
                      height="124px"
                    />
                    <div
                      className={
                        currentProperty?.property_name == "All Properties"
                          ? "d-none"
                          : "white-filter"
                      }
                    ></div>
                  </div>
                  <div
                    className={`p-1 ${currentProperty?.property_name == "All Properties"
                        ? "text-primary"
                        : ""
                      }`}
                  >
                    All Properties
                  </div>
                </div>

                {/* Dynamic Property Cards */}
                {properties?.length > 1 &&
                  properties.map((item, index) => (
                    <div
                      key={index}
                      className={`d-flex flex-column align-items-center body-text3 nav-link cursor-pointer`}
                      onClick={() => {
                        setCurrentProperty(item);
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "132px",
                          height: "124px",
                          filter: navbarToggle ? "brightness(75%)" : "none", // Dim images when navbarToggle is true
                        }}
                      >
                        <Image
                          src={item?.property_img || item?.image}
                          alt={item?.property_name || item?.title}
                          className={`small-image ${currentProperty?.id == item.id
                              ? "slider-active"
                              : "white-filter"
                            }`}
                          width="132px"
                          height="124px"
                        />
                        <div
                          className={
                            currentProperty.id == item.id || navbarToggle
                              ? "d-none"
                              : "white-filter"
                          }
                        ></div>
                      </div>
                      <div
                        className={`p-1 ${currentProperty.id == item.id ? "text-primary" : ""
                          }`}
                      >
                        {truncateText(item?.property_name || item?.title, 13)}
                      </div>
                    </div>
                  ))}
              </div>
              {/* Right Slide Button */}
              {small && (
                <Button
                  className="position-absolute end-0 z-index-top rounded-circle"
                  style={{
                    zIndex: 10,
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "#fff",
                    border: "none",
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={slideRight}
                >
                  <MdKeyboardArrowRight size={20} />
                </Button>
              )}
            </div>
          </div>
          <div className={`mb-3 d-block`}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center w-100"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : count > 0 ? (
              <div className="mb-3">
                <div
                  className="d-flex flex-column align-items-center"
                  style={{ minHeight: "80vh" }}
                >
                  <Row className="w-100">
                    {projects?.map((item, index) => (
                      <Col
                        key={index}
                        lg={4}
                        md={6}
                        sm={12}
                        className={`d-flex flex-column ps-lg-0 py-2 mb-2 justify-content-start ${small ? "" : "p-0"
                          }`}
                      >
                        {small ? (
                          <ProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            image={item?.rooms[0]?.media[0]?.file}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            architect={item?.company_name}
                            location={item?.property_data?.location}
                            builders={
                              item?.property_data?.builder_data?.company_name
                            }
                            className="mx-0 flex-grow"
                          />
                        ) : (
                          <MobileProjectCard
                            {...item}
                            id={item.id}
                            project_name={item?.project_name}
                            image={item?.rooms[0]?.media[0]?.file}
                            type={`${item?.bhk}, ${item?.project_area}`}
                            builder_name={
                              item?.property_data?.builder_data?.company_name
                            }
                            architect={item?.company_name}
                            className="w-100 mobile-card"
                          />
                        )}
                      </Col>
                    ))}
                    {/* <InfiniteScroll bottom={bottom} noNextItem={noNextItem} /> */}
                  </Row>
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
                style={{ height: "60vh" }}
              >
                No projects available
              </div>
            )}
          </div>
        </Container>

        {next && (
          <>
            <Button
              className="d-flex justify-content-center align-items-center text-center border-0 mt-1 p-2"
              style={{
                borderRadius: "50%",
                background: "#F1F3F4",
                marginBottom: `${small ? "2rem" : ""}`,
              }}
              onClick={handleLoadmore}
            >
              <IoIosArrowDown size={20} color="#3C4043" />
            </Button>
          </>
        )}
      </Container>
    </Container>
  );
};

export default Home;
