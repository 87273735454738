import { ad_1 } from "assets";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Carousel } from "react-bootstrap";
import AdCard from "./AdCard";

const MultipleAds = ({ endPoint }) => {
    const [ads, setAds] = useState([]);
    const [bannerOneAds, setBannerOneAds] = useState([]);
    const [bannerTwoAds, setBannerTwoAds] = useState([]);
    const [activeIndexOne, setActiveIndexOne] = useState(0);
    const [activeIndexTwo, setActiveIndexTwo] = useState(0);
    const [intervalOne, setIntervalOne] = useState(5000); // Default interval
    const [intervalTwo, setIntervalTwo] = useState(5000); // Default interval

    const fetchAds = async () => {
        try {
            const response = await axios.get(`/marketing/advertisements/${endPoint}/`);
            if (response.status === 201 || response.status === 200) {
                setAds(response.data);
            } else {
                return false;
            }
        } catch (error) {
            console.error("viewProperty error", error);
            return false;
        }
    };

    const randomizeAds = (adsArray) => {
        const shuffledAds = [...adsArray];
        for (let i = shuffledAds.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledAds[i], shuffledAds[j]] = [shuffledAds[j], shuffledAds[i]];
        }
        return shuffledAds;
    };

    useEffect(() => {
        fetchAds();
    }, []);

    useEffect(() => {
        if (ads.length > 0) {
            setBannerOneAds(randomizeAds(ads));
            setBannerTwoAds(randomizeAds(ads));
        }
    }, [ads]);

    // console.log({ bannerOneAds });
    // console.log({ bannerTwoAds });



    // Handle dynamic interval for Banner One
    useEffect(() => {
        if (bannerOneAds.length > 0) {
            const interval = bannerOneAds[activeIndexOne]?.time_interval * 1000 || 5000;
            setIntervalOne(interval);

            const timer = setTimeout(() => {
                setActiveIndexOne((prevIndex) => (prevIndex + 1) % bannerOneAds.length);
            }, interval);

            return () => clearTimeout(timer);
        }
    }, [activeIndexOne, bannerOneAds]);

    // Handle dynamic interval for Banner Two
    useEffect(() => {
        if (bannerTwoAds.length > 0) {
            const interval = bannerTwoAds[activeIndexTwo]?.time_interval * 1000 || 5000;
            setIntervalTwo(interval);

            const timer = setTimeout(() => {
                setActiveIndexTwo((prevIndex) => (prevIndex + 1) % bannerTwoAds.length);
            }, interval);

            return () => clearTimeout(timer);
        }
    }, [activeIndexTwo, bannerTwoAds]);

    return (
        <>
            {bannerOneAds.length > 0 ? (
                <Carousel
                    activeIndex={activeIndexOne}
                    onSelect={(selectedIndex) => setActiveIndexOne(selectedIndex)}
                    controls={false} // Optional: Hide controls for auto-slide
                    indicators={false}
                    pause="hover"
                >
                    {bannerOneAds.map((ad, index) => (
                        <Carousel.Item key={ad.id}>
                            <AdCard ad={ad} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded mb-3"
                    style={{ height: 400 }}
                >
                    Loading Ads...
                </div>
            )}

            {bannerTwoAds.length > 0 ? (
                <Carousel
                    activeIndex={activeIndexTwo}
                    onSelect={(selectedIndex) => setActiveIndexTwo(selectedIndex)}
                    controls={false} // Optional: Hide controls for auto-slide
                    indicators={false}
                    pause="hover"
                >
                    {bannerTwoAds.map((ad, index) => (
                        <Carousel.Item key={ad.id}>
                            <AdCard ad={ad} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded mt-4"
                    style={{ height: 400 }}
                >
                    Loading Ads...
                </div>
            )}
        </>
    );
};


export default MultipleAds;