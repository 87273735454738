import ListedPropertyCard from "components/Cards/ListedPropertyCard";
import ProductListCard from "components/Cards/ProductListCard";
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";


const SavedProducts = ({
    showAllRecords = false,
    showProducts = true,
}) => {
    const navigate = useNavigate();

    // const small = useMediaQuery({ query: "(min-width: 576px)" });
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const large = useMediaQuery({ query: "(min-width: 992px)" });
    const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

    const user = useSelector((state) => state.auth.user);

    const [products, setProducts] = useState([]);
    const [productLength, setProductLength] = useState(0);

    const [loading, setLoading] = useState(false);

    const itemsInRow = xlarge ? 3 : large ? 3 : medium ? 2 : 1;

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await authActions.fetchSavedPropertiesByArchitect({
                user: user.id,
                products: showProducts,
            });
            if (response.data) {
                if (showProducts) {
                    setProductLength(response.data["saved_products"].length);
                    setProducts(
                        showAllRecords
                            ? response.data["saved_products"]
                            : response.data["saved_products"].slice(0, itemsInRow)
                    );
                }
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, [showProducts]);

    return (
        <Container className="py-4 px-0">
            {loading ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "50vh" }}
                >
                    <Spinner animation="border" size="sm" />
                </div>
            ) : (
                <>
                    <div>
                        {showProducts && (
                            <div className="mb-4">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="h4 inter-600 mb-0">
                                        Saved Products ({!loading && productLength})
                                    </div>
                                    {!showAllRecords && productLength > 3 && (
                                        <div
                                            className="body-text2 text-gray-900 inter-500 cursor-pointer"
                                            onClick={() => navigate(`/user/saved#products`)}
                                        >
                                            View all
                                        </div>
                                    )}
                                </div>
                                <Row className={`${products.length > 0 ? "px-3" : "px-3"}`}>
                                    {products?.length > 0 ? (
                                        products?.map((product, index) => (
                                            <Col
                                                key={index}
                                                lg={4}
                                                md={6}
                                                sm={12}
                                                className={`d-flex flex-column py-2 mb-2 justify-content-start ${large
                                                        ? index % 3 === 0
                                                            ? "ps-0"
                                                            : index % 3 === 2
                                                                ? "pe-0"
                                                                : ""
                                                        : medium
                                                            ? index % 2 === 0
                                                                ? "ps-0"
                                                                : "pe-0"
                                                            : "p-0"
                                                    }`}
                                            >
                                                <ProductListCard
                                                    {...product?.saved_product_data}
                                                    images={
                                                        product?.saved_product_data?.media
                                                    }
                                                    product_type={product?.product_category_data?.title}
                                                    className={`cursor-pointer`}
                                                />
                                            </Col>
                                        ))
                                    ) : (
                                        <div
                                            className="d-flex justify-content-center align-items-center bg-gray-25 mt-4"
                                            style={{ height: "40vh" }}
                                        >
                                            No saved products!
                                        </div>
                                    )}
                                </Row>
                            </div>
                        )}
                    </div>
                    {/* <SavedRooms rooms={rooms} loading={loading} /> */}
                </>
            )}
        </Container>
    );
};

export default SavedProducts;