import React, { useEffect, useState } from "react";
import { Form, InputGroup, Card } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";

const SearchBar = ({ searchText, setSearchText, setToggled }) => {
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1300px)",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const [searchResults, setSearchResults] = useState([
    { id: 3, title: "Projects", slug: "projects" },
    { id: 2, title: "Locations", slug: "locations" },
    { id: 1, title: "Professionals", slug: "professionals" },
  ]);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [hoveredSearchResult, setHoveredSearchResult] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleSearchNavigate = (slug) => {
    setSearchDropdown(false);
    setSelectedCategory(slug);
    if (slug === "projects") {
      navigate(`/projects?property=${searchText}`);
      setToggled((prev) => !prev);
      setSearchText("");
    } else if (slug === "locations") {
      navigate(`/projects?location=${searchText}`);
      setToggled((prev) => !prev);
      setSearchText("");
    } else if (slug === "professionals") {
      // navigate(param1 === "builder" ? `/builder/properties?property_name=${searchText}` : `/professionals/builders?name=${searchText}`)
      navigate(`/projects?professionals=${searchText}`);
      setToggled((prev) => !prev);
      setSearchText("");
    }
  };

  useEffect(() => {
    if (param1 === "builder") {
      setSearchResults([{ id: 1, title: "Properties", slug: "properties" }]);
    }
  }, [param1]);

  return (
    <Form.Group
      className="d-flex w-100 position-relative mx-2 mx-lg-0"
      style={{ maxWidth: param1 === "builder" ? 700 : "" }}
    >
      <InputGroup style={{ height: 40 }}>
        <InputGroup.Text
          className="pe-2 px-lg-1 px-xxl-2"
          style={{
            borderLeft: "1px solid #BDC1C6",
            borderTop: "1px solid #BDC1C6",
            borderRight: 0,
            borderBottom: "1px solid #BDC1C6",
            borderRadius: "3px 0px 0px 3px",
            backgroundColor: "white",
          }}
        >
          <CiSearch size={20} color="#2E3134" style={{ strokeWidth: 1 }} />
        </InputGroup.Text>
        <Form.Control
          size="sm"
          placeholder={
            param1 === "builder"
              ? "Search by project name.."
              : "Search by project, location and professional."
          }
          className="py-2 ps-1 pe-3 pe-lg-0"
          style={{
            width: isLargeScreen && 120,
            borderLeft: 0,
            borderTop: "1px solid #BDC1C6",
            borderRight: searchText ? 0 : "1px solid #BDC1C6",
            borderBottom: "1px solid #BDC1C6",
            borderRadius: searchText ? "0px 0px 0px 0px" : "0px 3px 3px 0px",
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            if (e.target.value !== "") {
              setSearchDropdown(true);
            } else {
              setSearchDropdown(false);
            }
          }}
        />
        {searchText && (
          <div
            className="d-flex justify-content-center align-items-center pe-2 cursor-pointer"
            style={{
              borderLeft: 0,
              borderTop: "1px solid #BDC1C6",
              borderRight: "1px solid #BDC1C6",
              borderBottom: "1px solid #BDC1C6",
              borderRadius: "0px 3px 3px 0px",
            }}
          >
            {selectedCategory && (
              <span className="me-2 inter-400 text-primary-500">
                in {selectedCategory}
              </span>
            )}
            <div
              onClick={() => {
                setSearchText("");
                setSearchDropdown(false);
              }}
            >
              <IoCloseCircleOutline size={20} />
            </div>
          </div>
        )}
      </InputGroup>
      <Card
        style={{ top: 40 }}
        className={`${
          searchDropdown
            ? "position-absolute search-dropdown-container d-flex p-0 shadow-none"
            : "d-none"
        }`}
      >
        <Card.Body
          className="p-0"
          style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
        >
          <div className="d-flex flex-column">
            {searchResults?.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={`body-text2 inter-400 p-2 w-100 cursor-pointer 
                                    ${
                                      hoveredSearchResult === item?.slug
                                        ? "bg-gray-25"
                                        : ""
                                    }
                                    ${index === 0 ? "" : "border-top"} 
                                    d-flex justify-content-between`}
                  onMouseEnter={() => {
                    setHoveredSearchResult(item.slug);
                  }}
                  onMouseLeave={() => {
                    setHoveredSearchResult("");
                  }}
                  onClick={() => handleSearchNavigate(item.slug)}
                >
                  <span className="text-gray-900">{searchText}</span>
                  <span className="text-primary-500">{item?.title}</span>
                </div>
              );
            })}
          </div>
        </Card.Body>
      </Card>
    </Form.Group>
  );
};

export default SearchBar;
