import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaRegHeart } from "react-icons/fa6";
import { BiDislike } from "react-icons/bi";
import { default_property_image } from "assets";

function RoomCard({
  id,
  index,
  roomName,
  roomImage,
  projectName,
  location,
  total_inspirations,
  is_liked,
  onLike,
}) {
  const navigate = useNavigate();

  return (
    <Card
      key={index}
      className="p-0 d-flex flex-column text-start my-4 shadow-none"
      style={{ border: "1px solid #E8EAED", minHeight: 200, borderRadius: 6 }}
    >
      <Row>
        <Col
          md={4}
          lg={5}
          xl={4}
          xxl={3}
          className="mb-2 mb-md-0"
          style={{ flexGrow: 1 }}
        >
          <Card.Img
            className="bg-gray-25 border-0"
            variant="top"
            src={roomImage ?? default_property_image}
            style={{ height: 226, borderRadius: "6px 0px 0px 6px" }}
          />
        </Col>
        <Col md={8} lg={7} xl={8} xxl={9}>
          <Card.Body className="w-100 h-100 p-2 p-md-3 d-flex flex-column justify-content-between">
            <div className="mb-2">
              <div className="d-flex flex-row justify-content-between">
                <Card.Title className="h4 inter-700">
                  {roomName} / {projectName}
                </Card.Title>
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className={`d-flex justify-content-center align-items-center cursor-pointer me-2 p-0 ${
                      is_liked ? "bg-primary text-white" : "bg-gray-50"
                    }`}
                    style={{ width: 35, height: 35, borderRadius: 17.5 }}
                    onClick={onLike}
                  >
                    <FaRegHeart size={18} />
                  </div>
                </div>
              </div>
              <div className="body-text2 mb-2">
                {/* <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Room Type: {room_type}</span>
                </Card.Text> */}
                <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                  <span className="">Project Location: {location}</span>
                </Card.Text>
                {total_inspirations > 0 && (
                  <Card.Text className="mb-0" style={{ color: "#5F6368" }}>
                    <span className="">
                      Total Inspirations Created: {total_inspirations}
                    </span>
                  </Card.Text>
                )}
              </div>
            </div>
            {/* <div className="d-flex justify-content-end">
              <Button
                className="text-white body-text2 mt-2"
                style={{ borderRadius: 3 }}
                onClick={() => navigate(`/room/${id}`)}
              >
                View Room
              </Button>
            </div> */}
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}

export default RoomCard;
