import { all_products } from "assets";
import ProductListCard from "components/Cards/ProductListCard";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import ProductCategorySlider from "./ProductCategorySlider";
import { FaFilter } from "react-icons/fa6";
import FilterSearchBar from "components/FilterSearchBar/FilterSearchBar";
import { FaTimes } from "react-icons/fa";

const ProductLibrary = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const small = useMediaQuery({ query: "(min-width: 576px)" });

  const navigate = useNavigate();

  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [next, setNext] = useState("");
  const [productSubTypes, setProductSubTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [brands, setBrands] = useState([]);
  const [productCategory, setProductCategory] = useState("");
  const [productType, setProductType] = useState("");
  const [productSubType, setProductSubType] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [brand, setBrand] = useState("");
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(productCategory);
  const [selectedProductType, setSelectedProductType] = useState(productType);
  const [selectedProductSubType, setSelectedProductSubType] =
    useState(productSubType);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTypeTerm, setSearchTypeTerm] = useState("");
  const [searchSubtypeTerm, setSearchSubtypeTerm] = useState("");

  const [searchBrandTerm, setSearchBrandTerm] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState(brand);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTypeSearch = (e) => setSearchTypeTerm(e.target.value);
  const handleSubtypeSearch = (e) => setSearchSubtypeTerm(e.target.value);
  const handleBrandSearch = (e) => setSearchBrandTerm(e.target.value);

  // Filter product categories based on the search query
  const filteredCategories = productCategories.filter((prodCat) =>
    prodCat.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredProductTypes = productTypes.filter((prodType) =>
    prodType.title.toLowerCase().includes(searchTypeTerm.toLowerCase())
  );

  const filteredProductSubtypes = productSubTypes.filter((prodSubtype) =>
    prodSubtype.title.toLowerCase().includes(searchSubtypeTerm.toLowerCase())
  );

  // Filter brands based on the search term
  const filteredBrands = brands.filter((brand) =>
    brand.company_name.toLowerCase().includes(searchBrandTerm.toLowerCase())
  );

  const handleSelect = (categoryId) => {
    setSelectedCategory(categoryId);
    onChangeFilters(categoryId, "category");
    setSearchTerm(""); // Clear search term after selection
  };

  const handleProductTypeSelect = (productTypeId) => {
    setSelectedProductType(productTypeId);
    onChangeFilters(productTypeId, "product_type");
    setSearchTypeTerm(""); // Clear search term after selection
  };

  const handleProductSubtypeSelect = (productSubtypeId) => {
    setSelectedProductSubType(productSubtypeId);
    onChangeFilters(productSubtypeId, "product_sub_type");
    setSearchSubtypeTerm("");
  };

  const handleBrandSelect = (brandId) => {
    setSelectedBrandId(brandId);
    onChangeFilters(brandId, "brands");
    setSearchBrandTerm(""); // Clear search term after selection
  };

  const searchParams = new URLSearchParams(document.location.search);

  const category = searchParams.get("category");
  const type = searchParams.get("product_type");
  const subType = searchParams.get("product_sub_type");
  const sort = searchParams.get("sort_by");
  const brandParam = searchParams.get("brands");
  const q = searchParams.get("q");

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise(
        "/product/products/?page=1"
      );
      if (response?.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProductsByBrand = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsByBrandUser(user?.id);
      if (response.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    try {
      const response = await authActions.fetchProductCategories();
      if (response?.data) {
        setProductCategories(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductTypes = async (categoryId) => {
    try {
      const response = await authActions.fetchProductTypes(categoryId);
      if (response?.data) {
        setProductTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchProductSubTypes = async (typeId) => {
    try {
      const response = await authActions.fetchProductSubTypes(typeId);
      if (response?.data) {
        setProductSubTypes(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBrandsPagewise = async (typeId) => {
    try {
      const response = await authActions.fetchBrandsPagewise();

      if (response?.data) {
        setBrands(response.data.results);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const loadMoreProducts = () => {
    if (loading) return;
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;

      if (param1 === "architect") {
        if (nextPage > 2 || next === null) return;
        fetchProducts(nextPage);
      } else if (param1 === "brand") {
        fetchProductsByBrand(nextPage);
      }
      return nextPage;
    });
  };

  useEffect(() => {
    fetchBrandsPagewise();
  }, []);

  useEffect(() => {
    fetchCategories();

    if (category) {
      setProductCategory(category);
      fetchProductTypes(category);
    }

    if (type) {
      setProductType(type);
      fetchProductSubTypes(type);
    }
    if (subType) setProductSubType(subType);

    if (q) setSearchText(q);

    if (sort) setSortBy(sort);
    if (brandParam) setBrand(brandParam);
  }, [productType]);

  const onChangeFilters = (value = null, type = null) => {
    if (value === null && type === null) {
      setProductCategory(null);
      setProductType(null);
      setProductSubType(null);
      setBrand(null);
      setSortBy(null);

      const queryString = "";

      if (param1 === "architect") {
        navigate(`/architect/product-library`);
      } else {
        navigate(`/brand/product-library`);
      }
      return;
    }

    const filterParams = {
      category: type === "category" ? value : productCategory,
      product_type: type === "product_type" ? value : productType,
      product_sub_type: type === "product_sub_type" ? value : productSubType,
      brands: type === "brands" ? value : brand,
      sort_by: type === "sort_by" ? value : sortBy,
      q: type === "q" ? value : searchText,
    };

    const queryString = Object.entries(filterParams)
      .filter(([key, val]) => val)
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");

    if (type === "category") {
      setProductCategory(value);
    } else if (type === "product_type") {
      setProductType(value);
    } else if (type === "product_sub_type") {
      setProductSubType(value);
    } else if (type === "brands") {
      setBrand(value);
    } else if (type === "sort_by") {
      setSortBy(value);
    } else if (type === "q") {
      setSearchText(value);
    }

    if (param1 === "architect") {
      navigate(`/architect/product-library?${queryString}`);
    } else {
      navigate(`/brand/product-library?${queryString}`);
    }
  };

  const loadPaginatedProducts = async (next) => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise(next);
      if (response) {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...(response?.data?.results || []),
        ]);
        setNext(response?.data?.next);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (next !== null) {
      loadPaginatedProducts(next);
    }
  }, [productType, productSubType, brand, searchText]);

  const filteredProducts = async (
    productCategory,
    productType,
    productSubType,
    brand,
    searchText,
    sortBy
  ) => {
    try {
      const response = await authActions.buildFirstPageUrl(
        productCategory,
        productType,
        productSubType,
        brand,
        searchText,
        sortBy
      );

      console.log(response, "response");
      if (response?.data) {
        setNext(response?.data?.next);
        setProducts(response?.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredBrands = async () => {
    const response = await authActions.fetchBrandsByProductCategories(
      productCategory,
      productType,
      productSubType
    );
    if (response?.data) {
      setBrands(response.data.results);
    }
  };

  useEffect(() => {
    fetchFilteredBrands();
  }, [productCategory, productType, productSubType]);

  useEffect(() => {
    filteredProducts(
      productCategory,
      productType,
      productSubType,
      brand,
      searchText,
      sortBy
    );
  }, [productCategory, brand, productType, productSubType, sortBy, searchText]);

  useEffect(() => {
    if (param1 === "architect" && next !== null) {
      // fetchProducts();
    } else if (param1 === "brand") {
      fetchProductsByBrand();
    }
  }, [param1, user]);

  // Fetch Product Types when category changes
  useEffect(() => {
    if (productCategory) {
      fetchProductTypes(productCategory);
    }
  }, [productCategory]);

  // Fetch Product SubTypes when product type changes
  useEffect(() => {
    if (productType) {
      fetchProductSubTypes(productType);
    }
  }, [productType]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (loading || !next) return;
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 10
      ) {
        loadPaginatedProducts(
          next,
          productCategory,
          productType,
          productSubType,
          brand,
          sortBy
        );
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [
    loading,
    next,
    productCategory,
    productType,
    productSubType,
    brand,
    sortBy,
  ]);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  /* console.log(productTypes, "productTypes");
  console.log(productType, "productType"); */

  if (!products) return;

  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        <ProductCategorySlider
          all_products={all_products}
          productCategories={productCategories}
          setProductCategory={setProductCategory}
          onChangeFilters={onChangeFilters}
          fetchProducts={fetchProducts}
          productCategory={productCategory}
        />

        <div className="body-title my-4">
          <span className="inter-600">
            Products Available({products?.length})
          </span>
        </div>

        <div className="d-block d-md-none d-flex justify-content-between align-items-center">
          <FaFilter
            size={20}
            className="cursor-pointer my-2"
            onClick={handleToggleFilters}
          />

          {!small && (
            <div className="ms-3">
              <span
                className="cursor-pointer text-gray-700"
                onClick={() => {
                  onChangeFilters();
                  setSearchText("");
                  setSelectedCategory(null);
                  setSelectedProductType(null);
                  setSelectedProductSubType(null);
                  setSelectedBrandId(null);
                }}
              >
                Clear Filters
              </span>
            </div>
          )}
        </div>

        {!small && (
          <div className="mb-3">
            <FilterSearchBar
              onChangeFilters={onChangeFilters}
              setSearchText={setSearchText}
              searchText={searchText}
            />
          </div>
        )}

        {/* Filters (Visible on desktop and toggled on mobile) */}
        <div
          className={`d-flex flex-column flex-md-row justify-content-between ${
            showFilters ? "" : "d-none d-md-flex"
          }`}
        >
          <div className="row g-2">
            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {selectedCategory
                    ? productCategories.find(
                        (cat) => cat.id === selectedCategory
                      )?.title
                    : productCategories.find(
                        (cat) => cat.id === productCategory
                      )?.title || "Product Category"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search categories..."
                      value={searchTerm}
                      onChange={handleSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredCategories.map((prodCat) => (
                    <Dropdown.Item
                      as="button"
                      key={prodCat.id}
                      onClick={() => handleSelect(prodCat.id, prodCat.title)}
                      style={{
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {prodCat.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {selectedProductType
                    ? productTypes.find(
                        (type) => type.id === selectedProductType
                      )?.title
                    : "Product Type"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search product types..."
                      value={searchTypeTerm}
                      onChange={handleTypeSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredProductTypes.map((prodType) => (
                    <Dropdown.Item
                      as="button"
                      key={prodType.id}
                      onClick={() => handleProductTypeSelect(prodType.id)}
                      style={{
                        whiteSpace: "normal", // Allow wrapping
                        wordWrap: "break-word", // Break long words
                        overflowWrap: "break-word", // Handle wrapping for long strings
                      }}
                    >
                      {prodType.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {selectedProductSubType
                    ? productSubTypes.find(
                        (subtype) => subtype.id === selectedProductSubType
                      )?.title
                    : "Product Subtype"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search product subtypes..."
                      value={searchSubtypeTerm}
                      onChange={handleSubtypeSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredProductSubtypes.map((prodSubtype) => (
                    <Dropdown.Item
                      as="button"
                      key={prodSubtype.id}
                      onClick={() => handleProductSubtypeSelect(prodSubtype.id)}
                      style={{
                        whiteSpace: "normal", // Allow wrapping
                        wordWrap: "break-word", // Break long words
                        overflowWrap: "break-word", // Handle wrapping for long strings
                      }}
                    >
                      {prodSubtype.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-700 cursor-pointer w-100"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                  }}
                >
                  {selectedBrandId
                    ? brands.find((brand) => brand.id === selectedBrandId)
                        ?.company_name
                    : "Select Brand"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Search brands..."
                      value={searchBrandTerm}
                      onChange={handleBrandSearch}
                      style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                    />
                  </InputGroup>

                  {filteredBrands.map((brand) => (
                    <Dropdown.Item
                      as="button"
                      key={brand.id}
                      onClick={() => handleBrandSelect(brand.id)}
                      style={{
                        whiteSpace: "normal", // Allow wrapping
                        wordWrap: "break-word", // Break long words
                        overflowWrap: "break-word", // Handle wrapping for long strings
                      }}
                    >
                      {brand.company_name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {small && (
              <div className="col-12 col-md-auto">
                <FilterSearchBar
                  onChangeFilters={onChangeFilters}
                  setSearchText={setSearchText}
                  searchText={searchText}
                />
              </div>
            )}
          </div>

          <div className={small ? "d-flex align-items-center" : ""}>
            <div className="col-12 col-md-auto">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-transparent text-gray-900 cursor-pointer"
                  style={{
                    border: "1px solid #BDC1C6",
                    borderRadius: 3,
                    width: "100%",
                  }}
                >
                  {sortBy === "-created_at"
                    ? "Featured (New to Old)"
                    : sortBy === "created_at"
                    ? "Featured (Old to New)"
                    : "Sort By"}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    maxHeight: "250px",
                    maxWidth: "100%",
                    overflowY: "auto",
                  }}
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => onChangeFilters("-created_at", "sort_by")}
                    style={{
                      whiteSpace: "normal", // Allow wrapping
                      wordWrap: "break-word", // Break long words
                      overflowWrap: "break-word",
                    }}
                  >
                    Featured (New to Old)
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={() => onChangeFilters("created_at", "sort_by")}
                    style={{
                      whiteSpace: "normal", // Allow wrapping
                      wordWrap: "break-word", // Break long words
                      overflowWrap: "break-word",
                    }}
                  >
                    Featured (Old to New)
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {small && (
              <div
                className="col-12 col-md-auto"
                style={{ marginInlineStart: small && "1rem" }}
              >
                <Button
                  variant="danger"
                  className="cursor-pointer text-white w-100"
                  onClick={() => {
                    onChangeFilters();
                    setSearchText("");
                    setSelectedCategory(null);
                    setSelectedProductType(null);
                    setSelectedProductSubType(null);
                    setSelectedBrandId(null);
                  }}
                >
                  Clear Filters
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner animation="border" />
            </div>
          ) : products?.length > 0 || undefined ? (
            <div
              className="d-flex flex-column align-items-center"
              style={{ minHeight: "80vh" }}
            >
              <Row className="w-100">
                {products.map((product, index) => (
                  <Col
                    key={index}
                    lg={4}
                    md={6}
                    sm={12}
                    className={`d-flex flex-column py-2 mb-2 justify-content-start ${
                      large
                        ? index % 3 === 0
                          ? "ps-0"
                          : index % 3 === 2
                          ? "pe-0"
                          : ""
                        : medium
                        ? index % 2 === 0
                          ? "ps-0"
                          : "pe-0"
                        : "p-0"
                    }`}
                  >
                    <ProductListCard
                      {...product}
                      images={product?.media}
                      product_category={product?.product_category_data?.title}
                      product_type={product?.product_type_data?.title}
                      product_sub_type={product?.product_sub_type_data?.title}
                      className={`cursor-pointer`}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
              style={{ height: "60vh" }}
            >
              No products available
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default ProductLibrary;
