import { FaCheck, FaStar } from "react-icons/fa6";

const Review = ({ id, name, rating, review,created_at, type, content, image,by_user_data }) => {
  const date = new Date(created_at)
  const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

  return (
    <div className="my-3 p-4" style={{ backgroundColor: "#FFFAFA" }}>
      <div className="d-flex mb-2">
        <div
          className="inter-700 me-3 bg-dark text-white d-flex justify-content-center align-items-center"
          style={{
            fontSize: "28px",
            borderRadius: "50%",
            padding: "5px 18px",
          }}
        >
          {by_user_data?.full_name?.charAt(0)}
        </div>
        <div>
          <div className="h4 inter-600"> {by_user_data?.full_name}</div>
          <div className="d-flex align-items-center inter-600">
            {`Rating:  ${rating ?? 5} `}
            <FaStar color="#d95656" className="ms-1" />
          </div>
        </div>
      </div>
      <div className="text-primary inter-600 mb-2">{type}</div>
      <div className="geist mb-2">{review}</div>
      <div className="d-flex body-text3 inter-600">
        <div className="text-primary me-3">
          <FaCheck /> Helpful
        </div>
        <div>{formattedDate}</div>
      </div>
    </div>
  );
};

export default Review;
