import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import Review from "components/Review/Review";
import ProfilePropertyCard from "components/Cards/ProfilePropertyCard";
import ShareModal from "components/General/ShareModal";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import PropertyGridCard from "components/Cards/PropertyGridCard";
import { useSelector } from "react-redux";
import { LuPencilLine } from "react-icons/lu";
import ProjectCard from "components/Cards/ProjectCard";
import { notify } from "utilities/notifications/Notify";
import RatingStarsView from "components/General/RatingStarsView";
import LoginModal from "components/Auth/LoginModal";

const BuilderDetails = () => {
  const navigate = useNavigate();
  const { detailedSlug } = useParams();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);

  const form = useRef();
  const [rating, setRating] = useState(5);

  const [loading, setLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [projects, setProjects] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [properties, setProperties] = useState(null);
  const [builderDetails, setBuilderDetails] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [showShareProject, setShowShareProject] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const fetchData = async () => {
    setProjectsLoading(true);
    // fetch builder data
    try {
      const resBuilders = await authActions.fetchBuildersByTypeSlug(
        detailedSlug
      );
      console.log(resBuilders?.data);
      if (resBuilders?.data.results) {
        console.log(resBuilders.data);
        let tempBuilderDetails =
          resBuilders.data.results.length > 0
            ? resBuilders.data.results[0]
            : null;
        // fetch projects by builder user
        console.log(tempBuilderDetails);
        if (tempBuilderDetails) {
          const response = await authActions.fetchProjectsByBuilderUser(
            tempBuilderDetails.id
          );
          if (response.data?.results) {
            setProjects(response.data?.results);
            setTotalPages(Math.ceil(response.data.count / 12));
          }

          setProjectsLoading(false);
          // fetch reviews by architect
          const resReviews = await authActions.fetchReviewsByArchitectUser(
            tempBuilderDetails.id
          );
          console.log(resReviews?.data?.results);
          if (resReviews?.data?.results) {
            setReviews(resReviews.data.results);
          }
          setBuilderDetails(tempBuilderDetails);
        }
      }

      setProjectsLoading(false);

      setProjectsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  console.log({ projects });

  const submitReview = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      setShowLoginModal(true);
      return;
    }

    const formData = new FormData(form.current);
    formData.append("for_user", builderDetails.user_data.id);
    formData.append("by_user", user?.id);
    formData.append("rating", rating);
    if (formData.get("review")) {
      // let data = {};
      // formData.forEach((value, key) => (data[key] = value));
      const response = await authActions.addReview(formData);
      if (response.data) {
        notify("Review submitted successfully!", "success");

        if (builderDetails?.user_data?.id) {
          fetchReviews(builderDetails?.user_data?.id);
        }
        form.current.reset();
      }
    } else {
      notify("Review cannot be empty!", "error");
    }
  };

  const fetchReviews = async (id) => {
    setLoading(true);
    const resReviews = await authActions.fetchReviewsByArchitectUser(id);
    console.log(resReviews?.data?.results);
    if (resReviews?.data?.results) {
      setReviews(resReviews.data.results);
    }
    setLoading(false);
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(
        user.id,
        builderDetails.user
      );
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(
        user.id,
        builderDetails.user
      );
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      } else {
        notify("Failed to save professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(
        user.id,
        builderDetails.user
      );
      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (detailedSlug) {
      fetchData();
    } else {
      navigate(-1);
    }
  }, [detailedSlug]);

  useEffect(() => {
    if (builderDetails) {
      fetchReviews(builderDetails?.user_data.id);
    }
  }, [builderDetails]);

  useEffect(() => {
    if (builderDetails?.id) {
      checkProfessionalIfSaved();
    }
  }, [builderDetails]);

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <div className="d-flex justify-content-center bg-gray-25 geist">
        <Container className="d-flex flex-column flex-lg-row align-items-start p-3 border-bottom m-0">
          <div className="w-100" style={{ order: large ? 1 : 2 }}>
            <div className="d-flex align-items-center w-100">
              <div className="h2 inter-600 w-100 text-capitalize">
                {builderDetails?.company_name ??
                  detailedSlug?.split("-").join(" ")}
              </div>
              <div className="d-flex ms-auto me-lg-5">
                <Button
                  className="d-flex justify-content-center align-items-center social-btn py-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowShareProject(true);
                  }}
                >
                  <FiSend size={18} />
                </Button>
                {user?.user_type !== "builder" &&
                  user?.user_type !== "architect" &&
                  user?.user_type !== "brand" && (
                    <Button
                      className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${isLiked ? "bg-primary text-white" : ""
                        }`}
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!user) {
                          setShowLoginModal(true);
                        } else {
                          isLiked
                            ? handleUnsaveProfessional()
                            : handleSaveProfessional();
                        }
                      }}
                    >
                      <FaRegHeart size={18} />
                    </Button>
                  )}
              </div>
            </div>
            <div className="body-text inter-600">Builder</div>
            <div>
              {`
              ${builderDetails?.years_of_experience ?? 0}+ Years | 
              ${builderDetails?.project_count ?? 0}+ Projects | 
              `}
              {/* ${builderDetails?.no_of_cities ?? 0}+ Cities */}
            </div>
            <div>
              <span className="body-text inter-600">Location:</span>{" "}
              {builderDetails?.location}
            </div>
            <div className="d-flex align-items-center body-text inter-600">
              Rating:{" "}
              {builderDetails?.rating ? (
                <div>
                  {builderDetails?.rating}
                  <FaStar color="#D95656" />
                </div>
              ) : (
                "-"
              )}
              <FaStar color="#D95656" />
            </div>
            <div>
              <span className="body-text inter-600">Typical Job Cost:</span>{" "}
              {builderDetails?.job_cost_minimum}-
              {builderDetails?.job_cost_maximum}
            </div>
            <div>
              <span className="body-text inter-600">Properties made:</span>{" "}
              {builderDetails?.properties
                ? builderDetails?.properties
                  .map((obj) => obj?.property_name)
                  .join(", ")
                : "-"}
            </div>
            {user && user.user_type === "builder" && (
              <Button
                variant="primary"
                className="my-2 p-2"
                style={{
                  fontWeight: "600",
                  color: "#fff",
                  border: 0,
                  fontSize: 14,
                }}
                onClick={() => navigate(-1)}
              >
                <LuPencilLine className="me-2" />
                Edit Profile
              </Button>
            )}
            {/* <div>
              <span className="body-text inter-600">Projects Count:</span>{" "}
              {builderDetails?.project_count}
            </div>
            <div>
              <span className="body-text inter-600">Number of Cities:</span>{" "}
              {builderDetails?.no_of_cities}
            </div> */}
          </div>
          <div
            className={`d-flex align-items-center mt-2 ${large ? "justify-content-end" : "justify-content-start mb-3"
              }`}
            style={{ order: large ? 2 : 1 }}
          >
            <div
              className="d-flex justify-content-center align-items-center bg-white overflow-hidden"
              style={{ width: 200, height: 200, borderRadius: 12 }}
            >
              {builderDetails?.user_data?.profile_picture ? (
                <img
                  className="bg-white ms-auto"
                  width={200}
                  height={200}
                  style={{
                    // border: "1px solid #D0D5DD",
                    objectFit: "cover",
                  }}
                  src={builderDetails?.user_data?.profile_picture}
                  alt="Builder's Profile"
                />
              ) : (
                <div>No profile pic</div>
              )}
            </div>
          </div>
        </Container>
      </div>
      <Container className="my-3">
        {builderDetails?.builder_thoughts !== null &&
          builderDetails?.builder_image !== null ? (
          <Row
            className=""
            style={{
              border: "1px solid #D1D1D1",
              borderRadius: "6px",
              margin: "0 0px",
            }}
          >
            {/* Image Section */}
            <Col
              xs={12}
              md={2}
              className="mb-4 mb-md-0 px-0 d-flex align-items-center"
            >
              <img
                src={builderDetails?.builder_image}
                alt="Mandeep and Nisha"
                className="img-fluid"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: small ? "6px" : "",
                  borderTopRightRadius: !small ? "6px" : "",
                }}
              />
            </Col>
            {/* Text Section */}
            <Col
              xs={12}
              md={10}
              sm={12}
              className="py-2 text-start d-flex flex-column justify-content-center"
            >
              <div className="ms-2">
                {/* <div className="h4 inter-600 text-gray-900">
                        “Delighted to create so many interior inspirations for
                        top properties”
                      </div> */}
                <div className="body-text1 inter-400 text-gray-600">
                  {builderDetails?.builder_thoughts}
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <Row
            className=""
            style={{
              border: "1px solid #D1D1D1",
              borderRadius: "6px",
              margin: "0 0px",
            }}
          >
            {/* Image Section */}
            {/* <Col
            xs={12}
            md={2}
            className="mb-4 mb-md-0 px-0 d-flex align-items-center"
          >
            <img
              src={jewel_of_india}
              alt="Mandeep and Nisha"
              className="img-fluid"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: small ? "6px" : "",
                borderTopRightRadius: !small ? "6px" : "",
              }}
            />
          </Col> */}
            {/* Text Section */}
            <Col
              xs={12}
              md={10}
              sm={12}
              className="py-2 text-start d-flex flex-column justify-content-center"
            >
              <div className="ms-2">
                {/* <div className="h4 inter-600 text-gray-900">
                        “Delighted to create so many interior inspirations for
                        top properties”
                      </div> */}
                <div className="body-text1 inter-400 text-gray-600">
                  “Collaborating with Interiofy has been an inspiring journey,
                  from creating designs for the top builder’s projects to
                  enriching the presentation of our office’s architectural
                  designs and providing a unique platform to connect with
                  potential clients”
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Container>
      <Container className="pt-4 pb-3">
        <Row>
          {/* {properties?.map((item) =>
            item?.inspirations?.map((elem, index) => (
              <Col
                key={index}
                lg={4}
                className="d-flex justify-content-around my-3"
              >
                <ProfilePropertyCard
                  id={elem.id}
                  image={elem.spaces[0].image}
                  name={item.name}
                  project_type={item.project_type}
                  layout_types={item.layout_types}
                  location={item.location}
                  builder={item.builder}
                />
              </Col>
            ))
          )} */}
          {projectsLoading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : (
            <div>
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ minHeight: "50vh" }}
                >
                  <Spinner />
                </div>
              ) : projects?.length > 0 ? (
                <div className="mb-4">
                  <div className="h3 inter-600">{`Projects Created(${projects?.length})`}</div>
                  <Row>
                    {projects.map((item, index) => (
                      <Col
                        key={index}
                        sm={6}
                        md={6}
                        lg={4}
                        className="d-flex justify-content-around my-3"
                      >
                        <ProjectCard
                          id={item.id}
                          project_name={item?.project_name}
                          image={item?.rooms[0]?.media[0]?.file}
                          // type={`${item?.bhk}, Block ${item?.block}, ${item?.project_area}`}
                          type={`${item?.bhk}, ${item?.flat_area}`}
                          bhk={item?.bhk}
                          architect={item?.company_name}
                          property_name={item?.property_name}
                          location={item?.property_data?.location}
                          slug={item?.slug}
                          builder_name={item?.builder_name}
                        />
                        <ShareModal
                          show={showShareProject}
                          onHide={() => setShowShareProject(false)}
                          // id={id}
                          // image={image}
                          // title={`${project_name} / ${type}`}
                          urlPath={
                            item?.slug !== "undefined"
                              ? `/projects/${item?.slug}`
                              : null
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <div>
                  <div className="h3 inter-600">Projects</div>
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                    style={{ height: 160 }}
                  >
                    No projects available
                  </div>
                </div>
              )}
              {/* {properties && (
                <div className="mb-4">
                  <div className="h3 inter-600">{`Properties Created(${properties?.length})`}</div>

                  <Row
                    md={2}
                    lg={3}
                    className="my-4 signup-card flex-wrap"
                    style={{ boxShadow: "none" }}
                  >
                    {properties?.map((property, index) => (
                      <Col
                        key={index}
                        md={6}
                        lg={4}
                        className="mb-4"
                        // style={{ flexGrow: 1 }}
                      >
                        <PropertyGridCard
                          key={index}
                          {...property}
                          builder={property?.builder_data?.company_name}
                          project_type={property.project_type_data.title}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )} */}
            </div>
          )}
        </Row>
        <div className="my-4">
          {/* {user && user.user_type === "customer" && ( */}
            <>
              <div className="h3 inter-600 mb-3">Add Review</div>
              <div className="d-flex justify-content-end mb-3">
                <RatingStarsView rating={rating} setRating={setRating} />
              </div>
              <Form ref={form} onSubmit={submitReview}>
                <Form.Group>
                  <Form.Control
                    name="review"
                    as="textarea"
                    rows={5}
                    placeholder="I love interiokeys because..."
                    style={{ resize: "none" }}
                  />
                </Form.Group>
                <div className="w-100 my-3 d-flex justify-content-end">
                  <Button className="text-white" type="submit">
                    Submit Review
                  </Button>
                </div>
              </Form>
            </>
          {/* )} */}
          {/* <div className="h4 inter-600 mb-3">Reviews and Ratings</div> */}
          <div className="h3 inter-600">Reviews and Ratings</div>
          {reviews?.length > 0 ? (
            reviews.map((review, index) => <Review key={index} {...review} />)
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
              style={{ height: 160 }}
            >
              No reviews available
            </div>
          )}
        </div>
      </Container>
      <ShareModal
        show={showShareProject}
        onHide={() => setShowShareProject(false)}
        // id={user.id}
        // image={user.image}
        // title={user.company_name}
        urlPath={`/professionals/builders/${detailedSlug}`}
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </Container>
  );
};

export default BuilderDetails;
