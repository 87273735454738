import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiSend } from "react-icons/fi";
import Review from "components/Review/Review";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";
import LoginModal from "components/Auth/LoginModal";
import ShareModal from "components/General/ShareModal";
import { jewel_of_india, testimonial_pic } from "assets";
import { useMediaQuery } from "react-responsive";
import TeamMemberList from "components/TeamMember/TeamMemberList";
import Gallery from "components/Gallery/Gallery";
import RatingStarsView from "components/General/RatingStarsView";
import userImg from "../../assets/navbar/user.webp";
import TeamMember from "components/TeamMember/TeamMember";

const ProfessionalDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const slug = useParams();
  const { detailedSlug } = useParams();
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const queryParams = new URLSearchParams(location.search);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const form = useRef();

  // console.log("This is user id:" + user.id);

  const [rating, setRating] = useState(5);

  const [professionalDetails, setProfessionalDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [inspirationType, setInspirationType] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [roomCategories, setRoomCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [teamMembers, setTeamMembers] = useState([]);
  const [media, setMedia] = useState([]);
  const [sortBy, setSortBy] = useState(queryParams.get("sort_by") || "");
  const [status, setStatus] = useState(queryParams.get("status") || "");

  const fetchArchitectsBySlug = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await authActions.fetchArchitectsBySlug(detailedSlug);
      //   if (response.data) {
      //     if (response.data.results[0].id) {
      //       fetchProjects(response.data.results[0].id);
      //     }
      //     setProfessionalDetails(response.data.results[0]);
      //   } else {
      //     setError(
      //       "Error while loading professionals's details. Try again later!"
      //     );
      //   }
      // } catch (error) {
      //   console.log(error);
      if (response.data && response.data.results) {
        const desiredArchitect = response.data.results.find(
          (item) => item.slug === detailedSlug
        );

        if (desiredArchitect) {
          /* if (sortBy === "" || status === "") {
            fetchProjects(desiredArchitect?.id);
          } */
          fetchReviews(desiredArchitect?.id);
          setProfessionalDetails(desiredArchitect);
        } else {
          setError("No professional found. Please try again!");
        }
      } else {
        setError(
          "Error while loading professionals' details. Try again later!"
        );
      }
    } catch (error) {
      console.log("Error fetching architects by slug:", error);
      setError("Failed to load data. Please try again later.");
    }
    setLoading(false);
  };

  const submitReview = async (e) => {
    e.preventDefault();

    if (!isAuthenticated) {
      setShowLoginModal(true);
      return;
    }

    const formData = new FormData(form.current);
    formData.append("for_user", professionalDetails?.user_data.id);
    formData.append("by_user", user?.id);
    formData.append("rating", rating);
    if (formData.get("review")) {
      // let data = {};
      // formData.forEach((value, key) => (data[key] = value));
      const response = await authActions.addReview(formData);
      if (response.data) {
        notify("Review submitted successfully!", "success");

        if (professionalDetails?.user_data.id) {
          fetchReviews(professionalDetails?.user_data.id);
        }
        form.current.reset();
      }
    } else {
      notify("Review cannot be empty!", "error");
    }
  };

  const fetchProjects = async (id, sortBy = "") => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectsByArchitect(id, sortBy);
      if (response.data) {
        setProjects(response.data?.results);
      } else {
        setError("Error while loading project details");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSortBy = (ordering) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("sort_by", ordering);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

    setSortBy(ordering);
  };

  const handleStatusFilter = (status) => {
    const updatedSearchParams = new URLSearchParams(location.search);
    updatedSearchParams.set("status", status);
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);

    setStatus(status);
  };

  const fetchProjectsWithFilters = async (id, ordering = "", status = "") => {
    setLoading(true);
    try {
      const response = await authActions.fetchProjectsByArchitect(
        id,
        ordering,
        status
      );
      if (response.data) {
        setProjects(response.data?.results);
      } else {
        setError("Error while loading project details");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      (sortBy && professionalDetails?.id) ||
      (status && professionalDetails?.id)
    ) {
      fetchProjectsWithFilters(professionalDetails?.id, sortBy, status);
    } else if (professionalDetails?.id) {
      fetchProjects(professionalDetails?.id);
    }
  }, [sortBy, professionalDetails?.id, status]);

  const handleClearFilters = () => {
    // Create a copy of the current URL search params
    const updatedSearchParams = new URLSearchParams(location.search);

    // Remove specific filters from the search params
    updatedSearchParams.delete("status");
    updatedSearchParams.delete("sort_by");
    setSortBy("");
    setStatus("");
    navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
  };
  const fetchReviews = async (id) => {
    setLoading(true);
    const resReviews = await authActions.fetchReviewsByArchitectUser(id);
    console.log(resReviews?.data?.results);
    if (resReviews?.data?.results) {
      setReviews(resReviews.data.results);
    }
    setLoading(false);
  };

  const checkProfessionalIfSaved = async () => {
    try {
      const response = await authActions.checkProfessionalIfSaved(
        user.id,
        professionalDetails?.user_data?.id
      );
      setIsLiked(response?.status);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoomCategories = async () => {
    try {
      const response = await authActions.fetchRoomCategoriesData();
      if (response.data) {
        setRoomCategories(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProfessional = async () => {
    try {
      const response = await authActions.saveProfessional(
        user.id,
        professionalDetails.user_data.id
      );
      if (response.data) {
        setIsLiked(true);
        notify("Professional saved successfully", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProfessional = async () => {
    try {
      const response = await authActions.unsaveProfessional(
        user.id,
        professionalDetails.user_data.id
      );

      if (response) {
        setIsLiked(false);
        notify("Professional unsaved successfully", "success");
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTeamMembers = async (id) => {
    try {
      const response = await authActions.fetchTeamMembers({ user_id: id });
      if (response) {
        console.log(response.data);
        // notify("Professional unsaved successfully", "success");
        setTeamMembers(response.data);
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGalleries = async (id) => {
    try {
      const response = await authActions.fetchGallery({ user_id: id });
      if (response) {
        // notify("Professional unsaved successfully", "success");
        setMedia(response.data);
      } else {
        notify("Failed to unsave professional, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (detailedSlug) {
      fetchArchitectsBySlug();
    }
  }, [detailedSlug]);

  useEffect(() => {
    if (professionalDetails) {
      fetchReviews(professionalDetails?.user_data.id);
    }
  }, [professionalDetails]);

  useEffect(() => {
    if (professionalDetails?.id) {
      fetchTeamMembers(professionalDetails?.user);
      fetchGalleries(professionalDetails?.user);
    }
  }, [professionalDetails]);

  useEffect(() => {
    if (professionalDetails) {
      checkProfessionalIfSaved();
    }
  }, [professionalDetails]);

  return (
    <Container fluid className="mt-5 pt-4 p-0">
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </div>
      ) : error ? (
        <>
          <Container
            fluid
            className="my-5 p-0 py-4 d-flex justify-content-center align-items-center"
            style={{ minHeight: "80vh" }}
          >
            <div
              className="h2 text-primary-500 inter-600 p-5"
              style={{ backgroundColor: "#F8F9FA" }}
            >
              {error}
            </div>
          </Container>

          {/* <div className="h3 inter-600 text-center"></div> */}
        </>
      ) : (
        <div>
          <div className="bg-gray-25 geist">
            <Container className="pb-4">
              <Row className="py-3 px-0 m-0 mb-4 align-items-start">
                <Col lg={3} xl={2} className="p-0">
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ height: "100%" }}
                  >
                    <div
                      className="d-flex justify-content-start align-items-center bg-white rounded overflow-hidden"
                      style={{ width: 190, height: 190 }}
                    >
                      <img
                        className="border"
                        width={190}
                        height={190}
                        style={{
                          border: "1px solid #D0D5DD",
                          borderRadius: "12px",
                          objectFit: "cover",
                        }}
                        src={
                          professionalDetails?.user_data?.profile_picture ||
                          userImg
                        }
                        alt="Architect's Profile"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={9} xl={10}>
                  <div
                    className="d-flex align-items-center my-2 my-lg-0"
                    style={{ marginLeft: "-1px" }}
                  >
                    <div className="h2 inter-600 text-capitalize">
                      {professionalDetails?.user_data.full_name}
                    </div>
                    {(!user || user?.user_type === "customer") && (
                      <div className="d-flex ms-auto">
                        <Button
                          className="d-flex justify-content-center align-items-center social-btn py-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowShare(true);
                          }}
                        >
                          <FiSend size={18} />
                        </Button>

                        <button
                          className={`d-flex justify-content-center align-items-center social-btn-professional py-2 ms-2 ${
                            isLiked ? "bg-primary text-white" : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!user) {
                              setShowLoginModal(true);
                            } else {
                              isLiked
                                ? handleUnsaveProfessional()
                                : handleSaveProfessional();
                            }
                          }}
                        >
                          <FaRegHeart size={18} />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="body-text inter-600 text-capitalize">
                    {professionalDetails?.company_name ??
                      detailedSlug?.split("-").join(" ")}
                  </div>
                  <div className="d-flex align-items-center body-text inter-600">
                    Ratings: {professionalDetails?.rating}
                    <FaStar color="#D95656" />
                  </div>
                  <div>
                    <span className="body-text inter-600">Location:</span>{" "}
                    {professionalDetails?.location}
                  </div>
                  <div>
                    <span className="body-text inter-600">
                      Number of cities:
                    </span>{" "}
                    {professionalDetails?.no_of_cities}
                  </div>
                  <div>
                    <span className="body-text inter-600">
                      Typical Job Cost:
                    </span>{" "}
                    {professionalDetails?.project_cost_minimum}-
                    {professionalDetails?.project_cost_maximum}
                  </div>
                  <div>
                    <span className="body-text inter-600">Designed for:</span>{" "}
                    {professionalDetails?.project_names}
                  </div>
                  {(!user || user?.user_type === "customer") && (
                    <Button
                      className="text-white mt-3 mb-4"
                      onClick={() => {
                        if (professionalDetails?.id) {
                          navigate(`/consultation/architect/${detailedSlug}`, {
                            state: { architect: professionalDetails },
                          });
                        } else {
                          notify(
                            "Professional undefined, try again later",
                            "error"
                          );
                        }
                      }}
                    >
                      Book Free Consultaion
                    </Button>
                  )}
                </Col>
              </Row>
              {professionalDetails?.architect_thoughts !== null &&
              professionalDetails?.architect_image !== null ? (
                <Row
                  className=""
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: "6px",
                    margin: "0 0px",
                  }}
                >
                  {/* Image Section */}
                  <Col
                    xs={12}
                    md={2}
                    className="mb-4 mb-md-0 px-0 d-flex align-items-center"
                  >
                    <img
                      src={professionalDetails?.architect_image}
                      alt="Mandeep and Nisha"
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: small ? "6px" : "",
                        borderTopRightRadius: !small ? "6px" : "",
                      }}
                    />
                  </Col>
                  {/* Text Section */}
                  <Col
                    xs={12}
                    md={10}
                    sm={12}
                    className="py-2 text-start d-flex flex-column justify-content-center"
                  >
                    <div className="ms-2">
                      {/* <div className="h4 inter-600 text-gray-900">
                        “Delighted to create so many interior inspirations for
                        top properties”
                      </div> */}
                      <div className="body-text1 inter-400 text-gray-600">
                        {professionalDetails?.architect_thoughts}
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row
                  className=""
                  style={{
                    border: "1px solid #D1D1D1",
                    borderRadius: "6px",
                    margin: "0 0px",
                  }}
                >
                  {/* Image Section */}
                  <Col
                    xs={12}
                    md={2}
                    className="mb-4 mb-md-0 px-0 d-flex align-items-center"
                  >
                    <img
                      src={jewel_of_india}
                      alt="Mandeep and Nisha"
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderTopLeftRadius: "6px",
                        borderBottomLeftRadius: small ? "6px" : "",
                        borderTopRightRadius: !small ? "6px" : "",
                      }}
                    />
                  </Col>
                  {/* Text Section */}
                  <Col
                    xs={12}
                    md={10}
                    sm={12}
                    className="py-2 text-start d-flex flex-column justify-content-center"
                  >
                    <div className="ms-2">
                      {/* <div className="h4 inter-600 text-gray-900">
                        “Delighted to create so many interior inspirations for
                        top properties”
                      </div> */}
                      <div className="body-text1 inter-400 text-gray-600">
                        “Collaborating with Interiofy has been an inspiring
                        journey, from creating designs for the top builder’s
                        projects to enriching the presentation of our office’s
                        architectural designs and providing a unique platform to
                        connect with potential clients”
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>
          <Container className="py-3">
            {projectsLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ minHeight: "50vh" }}
              >
                <Spinner />
              </div>
            ) : (
              <div className="mb-3">
                <div className="geist body-title mb-3">
                  <span className="inter-700">
                    {projects?.length} real project designs
                  </span>{" "}
                  created
                </div>
                <Row className="mb-4">
                  {inspirationType === "projects" ? (
                    <Col className="d-flex" lg={9}>
                      <div className="pe-2 me-2 border-2 border-end">
                        <Button
                          className="text-white"
                          onClick={() => setInspirationType("spaces")}
                        >
                          Products
                        </Button>
                      </div>
                      <Button className="bg-gray me-2">Apartments</Button>
                      <Button className="bg-gray me-2">Villas</Button>
                      <Button className="bg-gray me-2">Showrooms</Button>
                      {/* <Button className="bg-gray me-2">Independent Land</Button> */}
                      <Button className="bg-gray me-2">Offices</Button>
                    </Col>
                  ) : (
                    roomCategories?.length > 0 && (
                      <Col
                        className="d-flex overflow-scroll scrollbar-none mb-2"
                        style={{ whiteSpace: "nowrap" }}
                        lg={9}
                      >
                        <div className="pe-2 me-2 border-2 border-end">
                          <Button
                            className="text-white h-100"
                            onClick={() => setInspirationType("projects")}
                          >
                            Spaces
                          </Button>
                        </div>

                        {roomCategories.map((item, index) => (
                          <Button
                            key={index}
                            className="bg-gray me-2"
                            onClick={() =>
                              navigate(`/spaces?room-caterogry=${item.slug}`)
                            }
                          >
                            {item?.title}
                          </Button>
                        ))}
                      </Col>
                    )
                  )}
                  <Col lg={4}>
                    <Row>
                      <Col>
                        <Form.Select
                          value={status}
                          onChange={(e) => handleStatusFilter(e.target.value)}
                          className="bg-gray-25"
                        >
                          <option value="" disabled>
                            Status
                          </option>
                          <option value="DESIGNING">Designing</option>
                          <option value="PENDING_APPROVAL">
                            Pending Approval
                          </option>
                          <option value="APPROVED">Approved</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Form.Select
                          value={sortBy}
                          onChange={(e) => handleSortBy(e.target.value)}
                          className="bg-gray-25"
                        >
                          {/* <option value="popularity">
                            Popularity (High to Low)
                          </option>
                          <option value="cost_high_low">
                            Cost (High to Low)
                          </option>
                          <option value="cost_low_high">
                            Cost (Low to High)
                          </option> */}
                          <option value="" disabled>
                            Sort By
                          </option>
                          <option value="-created_at">
                            Featured (New to Old)
                          </option>
                          <option value="created_at">
                            Featured (Old to New)
                          </option>
                        </Form.Select>
                      </Col>
                      <Col>
                        <Button
                          onClick={handleClearFilters}
                          className="border me-2 inter-500 bg-primary text-white"
                          style={{ padding: !small ? "0.6rem 0.5rem" : "" }}
                        >
                          Clear Filters
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {projects?.length > 0 ? (
                  <Row>
                    {projects?.map((item, index) => (
                      <Col
                        lg={4}
                        key={index}
                        className="d-flex justify-content-around my-3"
                      >
                        <ProjectCard
                          {...item}
                          id={item.id}
                          project_name={item?.project_name}
                          image={item?.rooms[0]?.media[0]?.file}
                          // type={`${item?.bhk}, Block ${item?.block}, ${item?.project_area}`}
                          type={`${item?.bhk}, ${item?.project_area}`}
                          architect={item?.company_name}
                          location={item?.property_data?.location}
                          builders={
                            item?.property_data?.builder_data?.company_name
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                    style={{ height: 160 }}
                  >
                    No projects available
                  </div>
                )}
              </div>
            )}
            {/* {user && user.user_type === "customer" && ( */}
            <>
              <div className="h3 inter-600 mb-3">Add Review</div>
              <div className="d-flex justify-content-end mb-3">
                <RatingStarsView rating={rating} setRating={setRating} />
              </div>
              <Form ref={form} onSubmit={submitReview}>
                <Form.Group>
                  <Form.Control
                    name="review"
                    as="textarea"
                    rows={5}
                    placeholder="I love interiokeys because..."
                    style={{ resize: "none" }}
                  />
                </Form.Group>
                <div className="w-100 my-3 d-flex justify-content-end">
                  <Button className="text-white" type="submit">
                    Submit Review
                  </Button>
                </div>
              </Form>
            </>
            {/* )} */}
            <div className="mb-4">
              <div className="h4 inter-600">Reviews and Ratings</div>
              {reviews?.length > 0 ? (
                reviews.map((review, index) => (
                  <Review key={index} {...review} />
                ))
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                  style={{ height: 160 }}
                >
                  No reviews available
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="h4 inter-600">Get to Know Us!</div>
              {/* <TeamMemberList
                handleAddTeamMember={null}
                teamMembers={teamMembers}
                handleDeleteMember={null}
                isSubmitting={false}
                showAddTeamModal={null}
                setShowAddTeamModal={null}
              /> */}
              <div className="py-3">
                {teamMembers?.length > 0 ? (
                  <div className="container">
                    <div className="row">
                      {teamMembers.map((teamMember, index) => (
                        <div
                          key={index}
                          className="col-5 col-sm-6 col-md-2 mb-3"
                        >
                          <TeamMember
                            {...teamMember}
                            // handleDelete={handleDeleteMember}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                    style={{ height: 160 }}
                  >
                    No Team Members available
                  </div>
                )}
              </div>
            </div>
            <div className="mb-4">
              <Gallery items={media} fetchGalleries={fetchGalleries} />
            </div>
          </Container>
        </div>
      )}
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={professionalDetails?.id}
        image={professionalDetails?.image}
        title={professionalDetails?.company_name}
        urlPath={
          detailedSlug !== "undefined" ? `/professionals/${detailedSlug}` : null
        }
      />
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      />
    </Container>
  );
};

export default ProfessionalDetails;
