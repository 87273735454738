import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdOutlineArrowForward,
  MdArrowForward,
  MdArrowForwardIos,
} from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

import { ArchitectsForDisplay, HowItWorks } from "components";
import { Breakpoint } from "react-socks";
import {
  archilabs,
  ayushi_j,
  brand_partner_1,
  brand_partner_2,
  brand_partner_3,
  brand_partner_4,
  brand_why_choose_us,
  career_choice,
  client_leads,
  dharmesh_shah,
  for_professionals_1,
  for_professionals_2,
  joi_property,
  managalam_tarang,
  meera_hoya,
  partner_img_1,
  partner_img_2,
  partner_img_3,
  partner_img_4,
  partner_img_5,
  partner_img_bg,
  partner_img_brand,
  partner_profile1,
  piyush_rai,
  priya_joshi,
  priyanka_rai,
  product_dev,
  recreating_seating,
  referral_code,
  relationship_logo,
  show_your_work1,
  show_your_work2,
  show_your_work3,
  shree_residence,
  somani_house,
  suncity_avenue,
  testimonial_pic,
  wait_is_over,
  walk_through,
  web_visibility,
} from "assets";
import CustomAccordion from "components/CustomAccordion/CustomAccordion";
import LoginModal from "components/Auth/LoginModal";

const ProfessionalBrand = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();

  const [brands, setBrands] = useState(null);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeItemId, setActiveItemId] = useState("step_1");
  const [hovered, setHovered] = useState(null);
  // const [showLoginModal, setShowLoginModal] = useState(false);
  // const [isHomeOwner, setIsHomeOwner] = useState(true);

  const portfolioSteps = [
    {
      id: 1,
      title: "Create your professional account",
      description:
        "Showcase all your work, details and awards with a single click. Let clients find you!",
    },
    {
      id: 2,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
    {
      id: 3,
      title: "Badges to promote your presence",
      description:
        "To let your efforts be known, we assign you badges when you reach each milestone on the Interiokeys platform.",
    },
  ];

  const accordionItems = [
    {
      id: "step_1",
      title: "Register & Upload Your Collections",
      content:
        "Sign up to showcase your product collection with detailed specifications, high-quality images, and pricing to captivate clients and drive inquiries.",
      step: "Step 1",
    },
    {
      id: "step_2",
      title: "Get Tagged in Inspirations",
      content:
        "By sharing insights, tips, and valuable information, you position yourself as an expert in your field, increasing trust!",
      step: "Step 2",
    },
    {
      id: "step_3",
      title: "Engage Directly",
      content:
        "You profile will become your entry ticket to many new projects. Gain visibility among builders, clients and brands by showcasing your work!",
      step: "Step 3",
    },
    {
      id: "step_4",
      title: "Grow Your Reach",
      content:
        "Share all the work you do, your awards, your company, everything that makes you special!",
      step: "Step 4",
    },
  ];

  const stepImages = [
    {
      id: "step_1",
      images: [
        {
          url: `${joi_property}`,
          title: "Jewel of India/Suncity Builders",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft) ",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${managalam_tarang}`,
          title: "Mangalam Tarang/Mangalam Group",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          location: "Location: Vaishali Nagar, Jaipur, India",
        },
        {
          url: `${suncity_avenue}`,
          title: "Suncity Avenue/Suncity Builders",
          layout: "Layouts: 4BHK (1380Sft), 5BHK (1500Sft)",
          location: " Location: Vaishali Nagar, Jaipur, India",
        },
      ],
    },
    {
      id: "step_2",
      images: [
        {
          url: `${walk_through}`,
          title: "Let us walk you through the ArchiLabs design process.",
          description: `Showing you how Archilabs transforms your home through ideation, design and execution.`,
          linkText: "Read More",
        },
        {
          url: `${archilabs}`,
          title: "ArchiLabs on designing the new heritage museum",
          description: `Showing you how Archilabs transforms your home through ideation, design and execution.`,
          linkText: "Read More",
        },
        {
          url: `${recreating_seating}`,
          title: "Recreating Seating Experience",
          description: `Showing you how Archilabs transforms your home through products reimagined as a statement piece`,
          linkText: "Enquire",
        },
        {
          url: `${wait_is_over}`,
          title: "Wait, is that a light?",
          description: `Going beyond conventional designs to create beautiful and functional lighting systems for your home`,
          linkText: "Enquire",
        },
      ],
    },
    {
      id: "step_3",
      images: [
        {
          url: `${somani_house}`,
          title: "Somani House/3BHK/Melodia Apartments",
          architect: "Architects: DCJ Architects",
          builders: "Builders: Melodia Group",
        },
        {
          url: `${shree_residence}`,
          title: "Shree Residence/2BHK/Jewel of India",
          architect: "Architects: ArchiLabs",
          builders: "Builders: Suncity Builders",
        },
        {
          url: `${somani_house}`,
          title: "Boho House/3.5BHK/Mangalam Radiance",
          architect: "Architects: ArchiLabs",
          builders: "Builders: Suncity Builders",
        },
      ],
    },
  ];

  const profilePics = [
    { img: `${partner_profile1}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${priya_joshi}`, name: "Priya Joshi", role: "Co-Founder" },
    { img: `${dharmesh_shah}`, name: "Dharmesh Shah", role: "Founder" },
    { img: `${piyush_rai}`, name: "Piyush Rai", role: "Co-Founder" },
    { img: `${meera_hoya}`, name: "Meera hoya", role: "Founder" },
    { img: `${ayushi_j}`, name: "Ayushi J", role: "Founder" },
    { img: `${priyanka_rai}`, name: "Priyanka Rai", role: "Founder" },
  ];

  const cardData = [
    {
      id: 1,
      icon: `${relationship_logo}`,
      title: "Increase Product Visibility",
      description:
        "Feature your products in real-life project inspirations that resonate with homeowners and professionals alike.",
    },
    {
      id: 2,
      icon: `${web_visibility}`,
      title: "Build Authentic Connections",
      description:
        "Engage with designers who can organically integrate your products into their projects.",
    },
    {
      id: 3,
      icon: `${product_dev}`,
      title: "Measure Impact",
      description:
        "Get real-time insights on product views, inquiries, and usage in inspirations to refine your offerings.",
    },
    {
      id: 4,
      icon: `${client_leads}`,
      title: "Reduce Marketing Efforts",
      description:
        "InterioKeys brings the audience to you, cutting down the time and expense of traditional outreach strategies.",
    },
    {
      id: 5,
      icon: `${referral_code}`,
      title: "Targeted Engagement",
      description:
        "Connect with architects, designers, and homeowners who are actively searching for products in your category.",
    },
    {
      id: 6,
      icon: `${career_choice}`,
      title: "Showcase Collections",
      description:
        "Display your products in a creative and relevant context by being tagged in design inspirations by top designers.",
    },
  ];

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return <div className="custom-dot mt-3" />;
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleActiveItemId = (id) => {
    setActiveItemId(id);
  };

  const renderCardsForActiveStep = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index}
        style={{ minWidth: "279px", textAlign: "left", margin: "16px" }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "279px",
            height: "384px",
            objectFit: "cover",
            borderRadius: "6px",
          }}
        />
        <div style={{ padding: "6px", backgroundColor: "white" }}>
          <div className="body-text1 inter-600 text-gray-800 wrap">
            {image.title}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.layout}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.location}
          </div>
        </div>
      </div>
    ));
  };

  const renderCardsForStep2 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index}
        style={{ maxWidth: "205px", textAlign: "left", margin: "16px" }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "205px",
            height: "320px",
            objectFit: "cover",
            borderRadius: "6px",
          }}
        />
        <div style={{ padding: "16px", backgroundColor: "white" }}>
          <div
            className="body-title inter-600 text-gray-800 wrap"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {image.title}
          </div>
          <div
            className="body-text2 inter-400 text-gray-800"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {image.description}
          </div>
          <p
            className="body-text2 inter-500 mb-0 pt-2"
            style={{ color: "#c53d3d", cursor: "pointer" }}
          >
            {image.linkText}
          </p>
        </div>
      </div>
    ));
  };

  const renderCardsForStep3 = () => {
    const step = stepImages.find((item) => item.id === activeItemId); // Find step by activeItemId

    return step.images.map((image, index) => (
      <div
        key={index}
        style={{
          minWidth: "279px",
          textAlign: "left",
          margin: "16px",
        }}
      >
        <img
          src={image.url}
          alt={image.title}
          style={{
            width: "279px",
            height: "384px",
            objectFit: "cover",
            borderRadius: "6px",
          }}
        />
        <div
          style={{ padding: "6px", backgroundColor: "white", width: "279px" }}
        >
          <div
            className="body-text1 inter-600 text-gray-800 wrap"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1, // Truncate to one line
              WebkitBoxOrient: "vertical",
            }}
          >
            {image.title}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.architect}
          </div>
          <div className="body-text2 inter-400 text-gray-800">
            {image.builders}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Container fluid className="px-0">
      <Container
        fluid
        className="d-flex flex-column align-items-center text-center px-0"
        style={{ minHeight: "100vh" }}
      >
        <Container fluid style={{ background: "#F8F9FA" }}>
          <Container className={`pt-5 ${screenHeight <= 800 ? "mt-5" : ""}`}>
            <div className="h1 px-lg-5 inter-600 pt-lg-5 pb-lg-4 mb-0">
              "Promote your products, get featured in inspirations, and connect
              with designers and homeowners across India through Interiokeys!"
            </div>
            {/* <div className="text-gray-600 body-title inter-400 my-3">
            Gain an edge over competitors by collaborating with Interiokeys, where we give you support from start to finish.
          </div> */}
          </Container>
        </Container>
        {/* <div className="d-flex justify-content-center bg-brand-500-half w-100 mx-0">
          <div className="mx-2">
            <Image src={for_professionals_1} alt="For Professioanls image 1" className="w-100" />
          </div>
          <div className="mx-2">
            <Image src={for_professionals_2} alt="For Professioanls image 1" className="w-100" />
          </div>
        </div> */}
        <Container fluid className="py-5 px-0">
          <Container>
            <div className="h2 inter-600">Partner with Us!</div>
            <div className="body-title inter-400 text-gray-600">
              "Partner with InterioKeys to showcase your product collections,
              create inspirations, get featured in{" "}
              <span style={{ display: "block" }}>
                designs and be visible to homeowners. Expand your reach and
                collaborate on unique projects."
              </span>
            </div>
          </Container>
          <Container className="pt-5 px-0" style={{ width: "1350px" }}>
            <Row>
              {/* Left Column (3 units) */}
              <Col xs={4} className="d-flex flex-column align-items-center">
                <Row className="mb-2 justify-content-center">
                  <Col>
                    <div className="text-start partner-card-border">
                      <img
                        src={brand_partner_1}
                        alt="Shree Residence/5BHK/Jewel of India"
                        className="img-fluid rounded-top"
                        style={{
                          width: "320px",
                          height: "208px",
                          minWidth: "320px",
                          objectFit: 'cover'
                        }}
                      />
                      <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                        Bedspreads/D-decor furnishings
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col>
                    <div className="text-start partner-card-border">
                      <img
                        src={brand_partner_3}
                        alt="Room 1"
                        className="img-fluid rounded-top"
                        style={{
                          width: "320px",
                          height: "208px",
                          minWidth: "320px",
                          objectFit: "cover",
                        }}
                      />
                      <p className="ps-3 py-2 body-text1 inter-600 text-gray-800 m-0">
                        Bathtubs/hansgrohe sanitaryware
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>

              {/* Middle Column (6 units) */}
              <Col
                xs={4}
                className="h-100 d-flex flex-column justify-content-end align-items-center"
              >
                <div
                  style={{
                    width: "355px",
                    // height: '400px',
                    backgroundImage: `url(${partner_img_bg})`,
                    backgroundSize: "contain",
                    backgroundPosition: "bottom",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <img
                    src={partner_img_brand}
                    alt="Partner Image"
                    className="img-fluid"
                    style={{
                      maxHeight: "100%",
                      objectFit: "contain",
                      marginBottom:'0'
                    }}
                  />
                </div>
              </Col>

              {/* Right Column (3 units) */}
              <Col xs={4} className="d-flex flex-column align-items-center">
                <Row className="mb-2">
                  <Col>
                    <div className="text-start border rounded">
                      <img
                        src={brand_partner_2}
                        alt="Room 3"
                        className="img-fluid rounded-top"
                        style={{
                          width: "320px",
                          height: "208px",
                          minWidth: "320px",
                          objectFit: "cover",
                        }}
                      />
                      <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                        Outdoor Collection/Kajaria Tiles
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col>
                    <div className="text-start partner-card-border">
                      <img
                        src={brand_partner_4}
                        alt="Room 4"
                        className="img-fluid rounded-top"
                        style={{
                          width: "320px",
                          height: "208px",
                          minWidth: "320px",
                          objectFit: "cover",
                        }}
                      />
                      <p className="ps-2 py-2 body-text1 inter-600 text-gray-800 m-0">
                        Luxury Lights/Hybec Lighting
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="p-5 bg-gray-25">
          <Row>
            {/* Left Section */}
            <Col md={3} className="d-flex flex-column text-start">
              <div className="mb-4 h2 inter-600">How it Works</div>
              <CustomAccordion
                accordionItems={accordionItems}
                onToggle={handleActiveItemId}
              />
              <Button
                className="px-3 text-start btn-primary mt-3 d-flex justify-content-between align-items-center"
                onClick={() => {
                  navigate("/signup#professional|brand");
                }}
              >
                Become an Interiorkeys Brand Partner <MdArrowForwardIos />{" "}
              </Button>
            </Col>

            <Col
              md={9}
              className="d-flex align-items-center justify-content-around"
            >
              {activeItemId === "step_1" && (
                <div className="d-flex align-items-center justify-content-between">
                  {renderCardsForActiveStep()}
                </div>
              )}

              {activeItemId === "step_2" && (
                <div className="d-flex align-items-center justify-content-between">
                  {renderCardsForStep2()}
                </div>
              )}

              {activeItemId === "step_3" && (
                <div className="d-flex align-items-center justify-content-between">
                  {renderCardsForStep3()}
                </div>
              )}

              {activeItemId === "step_4" && (
                <div>
                  <div className="mb-4">
                    <Row className="align-items-center justify-content-center">
                      <Col md={9} style={{ width: "700px" }}>
                        <Image
                          src={show_your_work1}
                          alt="Main Content Image"
                          fluid
                          style={{
                            width: "100%",
                            height: "306px",
                            objectFit: "cover",
                          }}
                        // className="mb-3"
                        />
                      </Col>
                      <Col md={3}>
                        <Row>
                          <Col xs={12} className="px-0 mb-2">
                            <Image
                              src={show_your_work2}
                              alt="Sidebar Image 1"
                              style={{
                                // width: '200px',
                                height: "147px",
                                objectFit: "cover",
                              }}
                              fluid
                            />
                          </Col>
                          <Col xs={12} className="px-0">
                            <Image
                              src={show_your_work3}
                              alt="Sidebar Image 2"
                              style={{
                                // width: '200px',
                                height: "147px",
                                objectFit: "cover",
                              }}
                              fluid
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className="d-flex justify-content-center">
                    <Row>
                      <h4 className="text-center mb-4">Meet the Team</h4>
                      <Row className="text-center justify-content-around">
                        {profilePics.map((member, index) => (
                          <Col key={index} md={1} xs={6} className="mb-3 px-0">
                            <div style={{ width: "100px" }}>
                              <Image
                                src={member.img}
                                alt={member.name}
                                style={{
                                  objectFit: "cover",
                                  width: "80px",
                                  height: "80px",
                                  borderRadius: "50%",
                                }}
                                fluid
                              />
                              <div
                                className="mt-2 body-title inter-600 text-gray-900"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {member.name}
                              </div>
                              <div className="text-muted body-text2 inter-400">
                                {member.role}
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <Container fluid className="p-5 my-5">
          <div className="h2 inter-600 text-gray-900 text-start mb-5">
            Interiokeys Helps You!
          </div>
          <Row>
            {cardData.map((card) => (
              <Col key={card.id} xs={12} md={6} lg={4} className="mb-4">
                <div
                  className="p-3 text-start"
                  style={{ border: "1px solid #E8EAED", borderRadius: "6px" }}
                >
                  <img
                    src={card.icon}
                    alt={card.title}
                    style={{ width: "48px" }}
                  />
                  <h5
                    className="body-title inter-600 mt-3"
                    style={{
                      color: hovered === card.id ? "#C53D3D" : "#1A1C1F",
                    }}
                    onMouseEnter={() => setHovered(card.id)}
                    onMouseLeave={() => setHovered(null)}
                  >
                    {card.title}
                  </h5>
                  <p className="body-text1 inter-400 text-gray-600">
                    {card.description}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <Container fluid className="p-5 my-5">
          <div className="h2 inter-600 text-gray-900 text-start mb-5">
            Why Should You Choose Us?
          </div>
          <Row
            className=""
            style={{ border: "1px solid #D1D1D1", borderRadius: "6px" }}
          >
            {/* Image Section */}
            <Col xs={12} md={3} className="mb-4 mb-md-0 px-0">
              <img
                src={brand_why_choose_us}
                alt="Mandeep and Nisha"
                className="img-fluid"
                style={{
                  width: "100%",
                  maxHeight: "280px",
                  objectFit: "cover",
                }}
              />
            </Col>
            {/* Text Section */}
            <Col
              xs={12}
              md={9}
              className="text-start d-flex flex-column justify-content-between"
            >
              <div className="mt-4 ms-2">
                <div className="h3 inter-600 text-gray-900">
                  Where brands meet brilliance and inspire designs that shine.
                </div>
                <div className="body-title inter-400 text-gray-600">
                  "As a lighting solutions brand, InterioKeys has provided us
                  with an incredible opportunity to showcase our collections to
                  the right audience. Architects and designers tagging our
                  products in their inspirations have driven more inquiries and
                  collaborations than we imagined. It's the perfect blend of
                  marketing and networking for brands like ours."
                </div>
              </div>
              <div className="mb-4 ms-2">
                <div
                  className="inter-600 text-gray-900"
                  style={{ fontSize: "20px" }}
                >
                  Anita Verma
                </div>
                <div
                  className="inter-400 text-gray-600"
                  style={{ fontSize: "16px" }}
                >
                  Interiokeys Brand Partner
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container
        fluid
        className="px-0 bg-brand-500 text-center d-flex justify-content-center align-items-center "
        style={{ minHeight: "400px" }}
      >
        <div>
          <div className="h1 inter-600 text-white">
            Become An Interiokeys Brand Partner
          </div>
          <div className="body-title inter-400 text-white mb-5">
            Get ahead of the curve and join Interiokeys as a partner. Let us
            help you become the top professional of your industry!
          </div>
          <button
            className="text-center mt-3 text-gray-700 inter-500"
            style={{
              width: "auto",
              backgraoundColor: "#f1f3f4",
              borderRadius: "3px",
              border: "none",
              padding: "8px 14px",
            }}
            onClick={() => {
              navigate("/signup#professional|brand");
            }}
          >
            Become an Interiorkeys Brand Partner <MdArrowForwardIos />{" "}
          </button>
        </div>
      </Container>
      {/* <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isHomeOwner={isHomeOwner}
        setIsHomeOwner={setIsHomeOwner}
      /> */}
    </Container>
  );
};

export default ProfessionalBrand;
